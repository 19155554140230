import BrokerApi from '../../api/BrokerApi'
import { PlaybackState, PlaybackStateFile } from '../../api/BrokerApi/types'
import { Mode } from 'remotivelabs-grpc-web-stubs'

export class PlaybackDelegate {
    private _brokerGrpcClient: BrokerApi

    constructor(brokerGrpcClient: BrokerApi) {
        this._brokerGrpcClient = brokerGrpcClient
    }

    batchDeleteFiles = async (files: Array<PlaybackStateFile>) => {
        await this._brokerGrpcClient.batchDeleteFiles(files)
    }

    getAvailableFiles = async (): Promise<Array<PlaybackStateFile>> => {
        return this._brokerGrpcClient.getAvailableFiles()
    }

    subscribeToPlaybackState = async (callback: (playing: PlaybackState) => void) => {
        console.log('subscribe to play mode')
        await this._brokerGrpcClient.initializePlaybackStream()
        this._brokerGrpcClient.listenOnPlayback((playing: PlaybackState) => {
            callback(playing)
        })
    }

    playFiles = (files: Array<PlaybackStateFile>, mode: Mode = Mode.PLAY) => {
        return this._brokerGrpcClient.playFiles(files, mode)
    }

    uploadFiles = async (files: Array<File>, callback: (bytes: number, percent: number) => void) => {
        for await (const file of files) {
            await this._brokerGrpcClient.uploadFile(file, callback)
        }
    }
}
