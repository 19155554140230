import { useState } from 'react'
import BrokersIcon from '@mui/icons-material/AccountTreeRounded'

import { getBrokerDetails } from '../../utils/broker'
import { Card, Modal } from 'react-bootstrap'
import BrokerDetailsContainer from './BrokerDetailsContainer'

interface BrokerDetailsInfoProps {
    buttonText?: string
    asModal: boolean
}

function BrokerDetailsInfo(props: BrokerDetailsInfoProps) {
    const [show, setShow] = useState(false)

    const formatBrokerDetails = (maxCharacters: number = 25) => {
        const { brokerUrl } = getBrokerDetails()
        if (brokerUrl.length > maxCharacters + 3) {
            return `${brokerUrl.substring(0, maxCharacters)}...`
        }
        return brokerUrl
    }

    const getDefaultText = (maxCharacters: number = 25) => {
        return `${formatBrokerDetails(maxCharacters)}`
    }

    const getBrokerDetailsBar = () => {
        return (
            <div className="d-flex justify-content-start">
                <div className="d-block d-md-none">
                    <button
                        className="border-0 remotive-primary-70-background m-3 mt-2 px-2"
                        style={{ opacity: 0.95, borderRadius: '0.4rem', height: '32px' }}
                        onClick={() => setShow(!show)}
                    >
                        <p className="m-0 text-light remotive-small">
                            <b>{props.buttonText || getDefaultText()}</b>
                        </p>
                    </button>
                </div>
                <div className="d-none d-md-block">
                    <button
                        className="border-0 remotive-primary-70-background p-2 m-3 mt-2 mb-5"
                        style={{ opacity: 0.95, borderRadius: '0.4rem' }}
                        onClick={() => setShow(!show)}
                    >
                        <p className="m-0 text-light remotive-small">
                            <BrokersIcon className="me-1" sx={{ fontSize: 15 }} />{' '}
                            <b>{props.buttonText || getDefaultText(43)}</b>
                        </p>
                    </button>
                </div>
            </div>
        )
    }

    return (
        <>
            <div className="mx-3">
                {props.asModal ? (
                    <>
                        <Modal show={show} size="lg" className="border-0 shadow-lg">
                            <BrokerDetailsContainer handleCloseFunction={() => setShow(false)} />
                        </Modal>
                    </>
                ) : (
                    <>
                        {' '}
                        {show && (
                            <Card style={{ zIndex: 9999 }} className="col-12 border-0 shadow-lg">
                                <BrokerDetailsContainer handleCloseFunction={() => setShow(false)} />
                            </Card>
                        )}
                    </>
                )}
            </div>
            {getBrokerDetailsBar()}
        </>
    )
}

export default BrokerDetailsInfo
