import { getNamespaces } from '../BrokerService'
import { toast } from 'react-toastify'

import {
    vssCurrentGear,
    vssHeading,
    vssHorizontalAccuracy,
    vssIsLeftIndicatorOn,
    vssIsRightIndicatorOn,
    vssLatitude,
    vssLongitude,
    vssPedalPosition,
    vssSpeed,
    vssStateOfChargeDisplayed,
    vssStateOfChargeDisplayed10Hz,
    vssSteeringAngle,
} from './vssMocks'
import { Namespace } from '../../api/BrokerApi/types'
import {
    aaosEvBatteryLevel,
    aaosInfoEvBatteryCapacity,
    aaosPerfSteeringAngle,
    aaosPerfVehicleSpeed,
    aaosHeading,
    aaosCurrentGear,
    aaosHorizontalAccuracy,
    aaosLatitude,
    aaosLongitude,
} from './aaosMocks'
import { isDemo } from '../../utils/CloudDetails'

let memoryCachedNamespaces: Array<Namespace>

const mockedSignalMappings = new Map<string, string>([
    ['vss_Vehicle.Body.Lights.IsLeftIndicatorOn', vssIsLeftIndicatorOn()],
    ['vss_Vehicle.Body.Lights.IsRightIndicatorOn', vssIsRightIndicatorOn()],
    ['vss_Vehicle.Powertrain.TractionBattery.StateOfCharge.Displayed', vssStateOfChargeDisplayed()],
    ['vss_Vehicle.Powertrain.TractionBattery.StateOfCharge.Displayed_10Hz', vssStateOfChargeDisplayed10Hz()],
    ['vss_Vehicle.Chassis.Accelerator.PedalPosition', vssPedalPosition()],
    ['vss_Vehicle.Speed', vssSpeed()],
    ['vss_Vehicle.Powertrain.Transmission.CurrentGear', vssCurrentGear()],
    ['vss_Vehicle.Chassis.SteeringWheel.Angle', vssSteeringAngle()],
    ['vss_Vehicle.CurrentLocation.Heading', vssHeading()],
    ['vss_Vehicle.CurrentLocation.HorizontalAccuracy', vssHorizontalAccuracy()],
    ['vss_Vehicle.CurrentLocation.Latitude', vssLatitude()],
    ['vss_Vehicle.CurrentLocation.Longitude', vssLongitude()],
    

    ['vss_PERF_VEHICLE_SPEED', aaosPerfVehicleSpeed()],
    ['vss_PERF_STEERING_ANGLE', aaosPerfSteeringAngle()],
    ['vss_INFO_EV_BATTERY_CAPACITY', aaosInfoEvBatteryCapacity()],
    ['vss_CURRENT_GEAR', aaosCurrentGear()],
    ['vss_EV_BATTERY_LEVEL', aaosEvBatteryLevel()],
    ['vss_HEADING', aaosHeading()],
    ['vss_HORIZONTAL_ACCURACY', aaosHorizontalAccuracy()],
    ['vss_LATITUDE', aaosLatitude()],
    ['vss_LONGITUDE', aaosLongitude()],
])

const fetchNamespaces = async () => {
    if (memoryCachedNamespaces === undefined) {
        const namespaces = await getNamespaces()
        memoryCachedNamespaces = namespaces
        return namespaces
    }
    return memoryCachedNamespaces
}

const isMappedConfiguration = async (namespaceName: string) => {
    const namespaces = await fetchNamespaces()
    return namespaces.some((namespace) => {
        if (namespace.name === namespaceName && namespace.type === 'scripted') {
            return isDemo() && true // Since these are hard coded we can only allow showing source in demo for now
        }
    })
}

const viewSignalSource = (signalName: string, signalNamespace: string) => {
    const signalMapping = getSignalCustomMappingSource(signalName, signalNamespace)
    if (signalMapping) {
        const tab = window.open('', '_blank')
        const escapedSignalMapping = signalMapping
            .replaceAll('&', '&amp;')
            .replaceAll('<', '&lt;')
            .replaceAll('>', '&gt;')
            .replaceAll('"', '&quot;')
            .replaceAll("'", '&#039;')
        tab?.document.write(`<pre>${escapedSignalMapping}</pre>`)
        tab?.document.close() // to finish loading the page
    } else {
        toast.error(`No source found for ${signalName} in ${signalNamespace}`)
    }
}

const getSignalCustomMappingSource = (signalName: string, signalNamespace: string) => {
    // TODO Replace this mock with an actual API call to the broker
    return mockedSignalMappings.get(`${signalNamespace}_${signalName}`)
}

export { isMappedConfiguration, getSignalCustomMappingSource, viewSignalSource, fetchNamespaces }
