import videojs from 'video.js'
import Player = videojs.Player

/**
 * Module for synchronizing video(js) video with a recording so that they are
 * paused, played, stopped and aligned properly.
 *
 */

/*
 * This interface is to decouple from our PlaybackState.currentOffset so that we
 * do not have to depend on that in this module.
 */
export interface CurrentRecording {
    // How far into the recording is currently played
    offsetInSeconds: number | undefined
}

// Can be used to track the status
export type VideoSyncStatus = {
    streamAndVideoOffsetSecs: number
    videoCurrentTimeSecs: number
    videoLengthSeconds: number
    isVideoPlaying: boolean
    hasVideoEnded: boolean
    isVideoSynced: boolean
    problemDescription: string | undefined
}

// Exported interface
export interface VideoSync {
    dispose(): void

    play(): void

    pause(): void

    stop(): void

    status(): VideoSyncStatus

    enable(enable: boolean): void

    setVideoOffsetSeconds(offset: number): void

    player: Player
    currentRecording: CurrentRecording
}

enum PlayMode {
    PLAY,
    PAUSE,
    STOP,
}

export default function createVideoSync(
    player: Player,
    recording: CurrentRecording,
    initialVideoStartOffsetSeconds: number | undefined, // Will be set to 0 by default
    alignmentInterval = 3000,
    maxDriftTimeSeconds = 0.5,
    isEnabled = true
): VideoSync {
    const MAX_ALLOWED_DRIFT_SECONDS = 20

    let problemDescription: string | undefined = undefined
    let enabled = isEnabled
    let videoThreadPid: number
    let mode: PlayMode
    let videoStartOffsetSeconds = initialVideoStartOffsetSeconds ? initialVideoStartOffsetSeconds : 0
    let currentMaxDriftSeconds = maxDriftTimeSeconds
    ;(() => {
        syncVideoWithRecording()
        // TODO - Thinking about it this thread could be replaced by PlaybackState invocations.
        videoThreadPid = window.setInterval(() => {
            syncVideoWithRecording()
        }, alignmentInterval)
    })()

    function setVideoOffsetSeconds(offset: number) {
        videoStartOffsetSeconds = offset
    }

    function play() {
        // Simply change state and let the video-thread start playing
        mode = PlayMode.PLAY
    }

    function pause() {
        if (!player?.paused()) {
            player.pause()
            mode = PlayMode.PAUSE
        }
    }

    function stop() {
        player.currentTime(0)
        player.pause()
        mode = PlayMode.STOP
    }

    function enable(enable: boolean) {
        enabled = enable
    }

    function dispose() {
        clearInterval(videoThreadPid)
        player.dispose()
    }

    function status(): VideoSyncStatus {
        const expectedSecsIntoVideo = recording.offsetInSeconds! - videoStartOffsetSeconds

        return {
            streamAndVideoOffsetSecs: expectedSecsIntoVideo - player.currentTime(),
            videoCurrentTimeSecs: player.currentTime() ? player.currentTime() : 0,
            isVideoPlaying: !player.paused(),
            hasVideoEnded: expectedSecsIntoVideo > player.duration(),
            isVideoSynced: videoStartOffsetSeconds !== undefined,
            videoLengthSeconds: player.duration(),
            problemDescription: problemDescription,
        }
    }

    // Runs every(alignmentInterval) to make sure that the video is properly aligned, stopped, started according
    // to the RecordingProgress.currentOffsetSeconds
    async function syncVideoWithRecording() {
        if (!enabled) {
            console.log('Video sync currently disabled')
            return
        }
        if (recording.offsetInSeconds === undefined) {
            console.log('Looks like there is no recording playing')
            return undefined
        }

        if (recording.offsetInSeconds! > videoStartOffsetSeconds) {
            const expectedSecsIntoVideo = recording.offsetInSeconds - videoStartOffsetSeconds

            if (expectedSecsIntoVideo > player.duration()) {
                //console.log('Video should have been ended now')
                if (!player.paused()) {
                    player.currentTime(player.duration())
                    player.pause()
                }
                return
            }

            //console.log(`Video should be started and played ${recording.offsetInSeconds} secs in`)
            const secsIntoVideo = player.currentTime()
            //console.log(`video time: ${secsIntoVideo} and ${expectedSecsIntoVideo} should be fairly same`)
            if (player.paused()) {
                if (mode == PlayMode.PLAY) {
                    console.log(`Playing video stream muted ${player.muted()}, inline? ${player.playsinline()}`)
                    try {
                        await player.play()
                    } catch (e: any) {
                        console.log('Failed to play, this is likely a permission error?')
                        console.log(`muted ${player.muted()}, inline? ${player.playsinline()}`)
                        console.log(e)
                        problemDescription = 'We have muted video due to browser restrictions'

                        player.muted(true)
                        player.playsinline(true)
                        player.play()
                    }
                }
            }
            if (Math.abs(secsIntoVideo - expectedSecsIntoVideo) > currentMaxDriftSeconds) {
                // Diff is more than maxDriftTimeSeconds seconds, align with stream
                if (mode == PlayMode.PLAY) {
                    console.log(
                        `Aligning video with stream: ${secsIntoVideo} =>  ${expectedSecsIntoVideo}. Allowed drift is ${currentMaxDriftSeconds}`
                    )
                    player.currentTime(expectedSecsIntoVideo)
                    player.play()
                }
                currentMaxDriftSeconds = Math.min(MAX_ALLOWED_DRIFT_SECONDS, currentMaxDriftSeconds + 0.5)
            }
        } else if (videoStartOffsetSeconds > recording.offsetInSeconds) {
            if (!player.paused()) {
                player.pause()
            }
            console.log(`Video not be started until ${recording.offsetInSeconds - videoStartOffsetSeconds}   secs`)
        }
        // TODO - lets revisit this one, this error comes early and when there are no issues
        //if (currentMaxDriftSeconds >= 4) {
        //    problemDescription =
        //        'Seems like we are having problems keeping the video in sync. This is a known issue when ' +
        //        'there are to many visualizations. You can try to reduce the frame rate or remove some visualizations.'
        //}
    }

    return { play, pause, stop, status, dispose, player, enable, setVideoOffsetSeconds, currentRecording: recording }
}
