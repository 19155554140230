import { BrokerCloudContext } from '../../api/BrokerApi/types'
import BrokerApi from '../../api/BrokerApi'
import { AxiosInstance } from 'axios'

export class BrokerDelegate {
    private _brokerGrpcClient: BrokerApi
    private _brokerHttpClient: AxiosInstance

    constructor(brokerGrpcClient: BrokerApi, brokerHttpClient: AxiosInstance) {
        this._brokerGrpcClient = brokerGrpcClient
        this._brokerHttpClient = brokerHttpClient
    }

    getCloudContext = async (): Promise<BrokerCloudContext | undefined> => {
        try {
            const contents = await this._brokerGrpcClient.downloadAsString('.cloud.context')
            if (contents) {
                const context: BrokerCloudContext = JSON.parse(contents)
                return context
            } else {
                return undefined
            }
        } catch (error: any) {
            console.log('Failed to download cloud context')
            console.log(error)
        }
    }

    upgrade = async (brokerTag: string | undefined, webappTag: string | undefined) => {
        let bTag: string | undefined = !brokerTag || brokerTag.length === 0 ? undefined : brokerTag
        let wTag: string | undefined = !webappTag || webappTag.length === 0 ? undefined : webappTag

        return this._brokerGrpcClient.upgrade(bTag, wTag)
    }

    isReachable = async () => {
        try {
            await fetch(this._brokerHttpClient.getUri(), {
                mode: 'no-cors',
                signal: AbortSignal.timeout(5000),
            })
            return true
        } catch (e: any) {
            console.log(`ERROR: ${e}`)
        }
        return false
    }

    getBrokerVersion = async () => {
        const conf = await this._brokerGrpcClient.getConfiguration()
        console.log(conf.getServerversion())
        const v = conf.getServerversion().replace(/^v/, '')
        console.log(v)
        return v
    }

    fetchTimeout = async (resource: string, options: any) => {
        options = options || {}
        const { timeout = 8000 } = options
        delete options.timeout
        const controller = new AbortController()
        const timeoutID = setTimeout(() => {
            controller.abort()
        }, timeout)
        try {
            const response = await fetch(resource, {
                ...options,
                signal: controller.signal,
            })
            clearTimeout(timeoutID)
            return response
        } catch (err: any) {
            clearTimeout(timeoutID)
            return undefined
        }
    }
}
