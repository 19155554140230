import axios, { AxiosError, AxiosInstance, AxiosRequestConfig, AxiosResponse } from 'axios'
import { toast } from 'react-toastify'
import {
    AuthenticatedUser,
    BrokerConfigurationFolder,
    BrokerContainer,
    CreateProjectRequest,
    CreateUserInfoWithRoles,
    MediaFile,
    Organisation,
    Project,
    RecordingFile,
    RecordingSession,
    SignalDatabase,
    SignalList,
    SignalNameList,
    UserBillableUnitInfo,
    UserInfoWithRoles,
    UserLicense,
} from './types'
import { getLastProject } from '../../utils/CloudDetails'

class MonolithApi {
    private authCookieFromEnvVariable?: string

    constructor() {
        this.remotiveApiClient.interceptors.response.use(
            function (response) {
                return response
            },
            function (error: any) {
                console.log(`${error.response.data} ${error.response.status} (${error.response.statusText})`)
                return Promise.reject(error)
            }
        )

        const { REACT_APP_BACKEND_AUTH_COOKIE } = process.env
        this.authCookieFromEnvVariable = REACT_APP_BACKEND_AUTH_COOKIE

        const getAuthHeader = this.authHeader

        this.remotiveApiClient.interceptors.request.use((req) => {
            if (req.headers) {
                req.headers['Authorization'] = getAuthHeader()
            }
            return req
        })
    }

    /* URLs */

    getBackendUrl = () => {
        if (process.env.REACT_APP_BACKEND_URL) {
            return process.env.REACT_APP_BACKEND_URL
        }
        if (window.location.host.endsWith('cloud.remotivelabs.com')) {
            return 'https://cloud.remotivelabs.com'
        }
        if (window.location.host.endsWith('cloud-dev.remotivelabs.com')) {
            return 'https://cloud-dev.remotivelabs.com'
        }
        if (window.location.host.endsWith('demo.remotivelabs.com')) {
            return 'https://demo.remotivelabs.com'
        }
        console.debug(`Couldn't find a URL for the cloud API, setting it to a default value`)
        return 'https://cloud.remotivelabs.com'
    }

    getConsoleAppBaseURL = () => {
        if (process.env.REACT_APP_CONSOLE_APP_BASE_URL) {
            return process.env.REACT_APP_CONSOLE_APP_BASE_URL
        }
        if (window.location.host.endsWith('cloud.remotivelabs.com')) {
            return 'https://console.cloud.remotivelabs.com'
        }
        if (window.location.host.endsWith('cloud-dev.remotivelabs.com')) {
            return 'https://console.cloud-dev.remotivelabs.com'
        }
        if (window.location.host.endsWith('demo.remotivelabs.com')) {
            return 'https://console.demo.remotivelabs.com'
        }
        console.debug(`Couldn't find a URL for the console app, setting it to a default value`)
        return 'https://console.cloud.remotivelabs.com'
    }

    getConsoleAppReturnUrl = () => {
        const baseUrl = this.getConsoleAppBaseURL()
        console.log(getLastProject())
        const projectId = getLastProject()
        if (projectId) {
            return `${baseUrl}/p/${projectId}/recordings`
        }
        return baseUrl
    }

    getBrokerAppBaseUrl = () => {
        if (process.env.REACT_APP_BROKER_APP_BASE_URL) {
            return process.env.REACT_APP_BROKER_APP_BASE_URL
        }
        if (window.location.host.endsWith('cloud.remotivelabs.com')) {
            return 'https://broker-app.cloud.remotivelabs.com'
        }
        if (window.location.host.endsWith('cloud-dev.remotivelabs.com')) {
            return 'https://broker-app.cloud-dev.remotivelabs.com'
        }
        if (window.location.host.endsWith('demo.remotivelabs.com')) {
            return 'https://broker-app.demo.remotivelabs.com'
        }
        console.debug(`Couldn't find a URL for the broker app, setting it to a default value`)
        return 'https://broker-app.remotivelabs.com'
    }

    setLoginUrl = () => {
        window.location.replace(`${this.getBackendUrl()}/login?redirectUrl=${this.getBrokerAppBaseUrl()}`)
    }

    getProjectHomeUrl = (projectUid: string) => {
        return `/p/${projectUid}/recordings`
    }

    getOrganisationHomeUrl = (billableUnit: UserBillableUnitInfo) => {
        return `/orgs/${billableUnit.organisation.uid}`
    }

    getBrokerPageUrl = (projectUid: string, brokerName: string) => {
        return `/p/${projectUid}/brokers/${brokerName}`
    }

    getBrokerAppUrl = (broker: BrokerContainer, isDemo?: boolean) => {
        return (
            `${this.getBrokerAppBaseUrl()}?` +
            `api_key=${broker?.keys.toString()}` +
            `&broker=${broker?.url}` +
            `${isDemo ? '&isDemo=true' : ''}`
        )
    }

    /* API clients */

    authHeader = () => `Bearer  ${this.authCookieFromEnvVariable || this.getCookie('auth')}`

    hasAuthHeaderCookie = () => (this.authCookieFromEnvVariable || this.getCookie('auth')) !== undefined

    remotiveApiClient: AxiosInstance = axios.create({
        baseURL: this.getBackendUrl(),

        validateStatus: function (status) {
            if (status === 401 && window.location.pathname !== '/' && window.location.pathname !== '/noaccount') {
                console.log(window.location.pathname)
                // TODO - this toast is not seen due to the page refresh afterwards
                // If we could use a route instead it might work but this is not a react component
                toast.error('Your session has expired', {
                    toastId: 'api.unauthenticated',
                })
                window.localStorage.setItem('redirectUrl', window.location.pathname)
                window.location.replace('/')
            }
            if (status === 403) {
                // TODO - consider if we should route the user to organisation home here
                toast.error('You do not have permission to view this resource', {
                    toastId: 'api.unauthorized',
                })
            }

            return status >= 200 && status < 300 // default
        },
    })

    externalApiClient: AxiosInstance = axios.create({
        validateStatus: function (status) {
            return status >= 200 && status < 300 // default
        },
    })

    getCookie = (name: string) => {
        return document.cookie
            .split(';')
            .map((row) => row.trim())
            .find((row) => row.startsWith(`${name}=`))
            ?.split('=')[1]
    }

    removeCookie = (name: string, path: string, domain: string) => {
        if (this.getCookie(name)) {
            document.cookie =
                name +
                '=' +
                (path ? ';path=' + path : '') +
                (domain ? ';domain=' + domain : '') +
                ';expires=Thu, 01 Jan 1970 00:00:01 GMT' // This will force the cookie to expire
        }
    }

    //authHeader = `Bearer  ${this.getCookie('auth')}`

    removeAuthKey = () => {
        this.removeCookie('auth', '/', 'localhost')
        this.removeCookie('auth', '/', 'cloud-dev.remotivelabs.com')
        this.removeCookie('auth', '/', 'cloud.remotivelabs.com')
    }

    // Admin

    migrate = (): Promise<string> => {
        //remotiveApiClient.defaults.headers.common['Authorization'] = "Bearer " + authToken()
        return this.remotiveApiClient.get('/api/admin/migrate')
    }

    /* Billable units */

    listOrganisations = (): Promise<AxiosResponse<Array<UserBillableUnitInfo>>> => {
        return this.remotiveApiClient.get(`/api/bu`)
    }

    getPermissionsForCurrentProject = async (currentProject: string): Promise<Array<string> | undefined> => {
        const resp = await this.listOrganisations()
        const project = resp.data
            .flatMap((it: UserBillableUnitInfo) => it.projects)
            .find((project: Project) => project.uid === currentProject)
        if (project) {
            return project.permissions
        }
        return undefined
    }

    listLicenses = (bu: string, filter: string | undefined): Promise<AxiosResponse<Array<UserLicense>>> => {
        return this.remotiveApiClient.get(`/api/bu/${bu}/licenses?filter=${filter}`)
    }

    defaultUserBu = (): Promise<AxiosResponse<UserBillableUnitInfo>> => {
        return this.remotiveApiClient.get(`/api/bu/me`)
    }

    /* Users */

    whoami = (): Promise<AxiosResponse<AuthenticatedUser>> => {
        return this.remotiveApiClient.get('/api/whoami')
    }

    listProjectUsers = (projectUid: string): Promise<AxiosResponse<Array<UserInfoWithRoles>>> => {
        return this.remotiveApiClient.get(`/api/project/${projectUid}/admin/users`)
    }

    editProjectUser = (projectUid: string, user: UserInfoWithRoles): Promise<AxiosResponse<any>> => {
        return this.remotiveApiClient.put(
            `/api/project/${projectUid}/admin/users/${user.user.uid}`,
            JSON.stringify(user),
            {
                headers: {
                    'Content-Type': 'application/json',
                },
            }
        )
    }

    removeProjectUser = (projectUid: string, user: UserInfoWithRoles): Promise<any> => {
        return this.remotiveApiClient.delete(`/api/project/${projectUid}/admin/users/${user.user.uid}`)
    }

    listBuUsers = (bu: string): Promise<AxiosResponse<Array<UserInfoWithRoles>>> => {
        return this.remotiveApiClient.get(`/api/bu/${bu}/users`)
    }

    editBuUser = (bu: string, user: UserInfoWithRoles): Promise<AxiosResponse<any>> => {
        return this.remotiveApiClient.put(`/api/bu/${bu}/users/${user.user.uid}`, JSON.stringify(user), {
            headers: {
                'Content-Type': 'application/json',
            },
        })
    }

    deleteBuUser(user: UserInfoWithRoles, org: Organisation): Promise<AxiosResponse<any>> {
        return this.remotiveApiClient.delete(`/api/bu/${org.uid}/users/${user.user.uid}`)
    }

    createBuUser(bu: string, user: CreateUserInfoWithRoles): Promise<AxiosResponse<any>> {
        return this.remotiveApiClient.post(`/api/bu/${bu}/users`, JSON.stringify(user), {
            headers: {
                'Content-Type': 'application/json',
            },
        })
    }

    /* Recording Sessions */

    listRecordingSessions = (project: string): Promise<AxiosResponse<Array<RecordingSession>>> => {
        return this.remotiveApiClient.get(`/api/project/${project}/files/recording`)
    }

    getRecordingSession(project: string, sessionId: string): Promise<AxiosResponse<RecordingSession>> {
        return this.remotiveApiClient.get(`/api/project/${project}/files/recording/${sessionId}`)
    }

    editRecordingSession(
        project: string,
        sessionId: string,
        displayName: string,
        description: string
    ): Promise<AxiosResponse<any>> {
        return this.remotiveApiClient.put(`/api/project/${project}/files/recording/${sessionId}`, {
            newDisplayName: displayName,
            newDescription: description,
        })
    }

    deleteRecordingSession = (project: string, sessionId: string): Promise<AxiosResponse<string>> => {
        return this.remotiveApiClient.delete(`/api/project/${project}/files/recording/${sessionId}`)
    }

    uploadRecordingSession(project: string, file: File, config: AxiosRequestConfig): Promise<AxiosResponse<string>> {
        //config.headers!['Content-Type'] = 'multipart/*'
        const f = new FormData()
        f.append(file.name, file)
        return this.remotiveApiClient
            .post(`/api/project/${project}/files/recording/${file.name}`, undefined, {
                headers: {
                    'Content-Type': 'multipart/*',
                },
            })
            .then((res) => {
                return this.externalApiClient.put(res.data, file, config)
            })
    }

    applyRecordingSessionToBroker = (
        project: string,
        recordingSessionId: string,
        broker: string,
        brokerConfigName: string | undefined = undefined
    ): Promise<AxiosResponse<BrokerContainer>> => {
        console.debug(`Trying to start recording ${project} ${recordingSessionId} on ${broker}`)
        return this.remotiveApiClient.get(`/api/project/${project}/files/recording/${recordingSessionId}/upload`, {
            params: { brokerName: broker, brokerConfigName: brokerConfigName },
        })
    }

    /* Recording Files */

    deleteRecordingFile = (
        project: string,
        sessionId: string,
        recordingFile: RecordingFile
    ): Promise<AxiosResponse<string>> => {
        return this.remotiveApiClient.delete(
            `/api/project/${project}/files/recording/${sessionId}/recording-file/${recordingFile.fileName}`
        )
    }

    deleteProcessingRecordingFile = (project: string, recordingFile: RecordingFile): Promise<AxiosResponse<string>> => {
        return this.remotiveApiClient.delete(
            `/api/project/${project}/files/recording/processing-file/${recordingFile.fileName}`
        )
    }

    getRecordingFile(
        project: string,
        sessionId: string,
        recordingFileName: string
    ): Promise<AxiosResponse<RecordingFile>> {
        return this.remotiveApiClient.get(
            `/api/project/${project}/files/recording/${sessionId}/recording-file/${recordingFileName}`
        )
    }

    applyRecordingFileToBroker = (
        project: string,
        sessionId: string,
        fileName: string,
        broker: string
    ): Promise<AxiosResponse<BrokerContainer>> => {
        console.log(`start recording ${project} ${fileName} on ${broker}`)
        return this.remotiveApiClient.get(`/api/project/${project}/files/recording/${sessionId}/${fileName}/upload`, {
            params: { brokerName: broker },
        })
    }

    listProcessingRecordingFiles(project: string): Promise<AxiosResponse<Array<RecordingFile>>> {
        return this.remotiveApiClient.get(`/api/project/${project}/files/recording/processing`)
    }

    /* Media Files */

    getMediaFile(project: string, sessionId: string, mediaFile: MediaFile): Promise<AxiosResponse<MediaFile>> {
        return this.remotiveApiClient.get(
            `/api/project/${project}/files/recording/${sessionId}/media/${mediaFile.fileName}`
        )
    }

    saveVideoOffset(project: string, sessionId: string, mediaFile: MediaFile) {
        return this.remotiveApiClient.put(
            `/api/project/${project}/files/recording/${sessionId}/media/${mediaFile.fileName}/meta`,
            JSON.stringify({
                videoOffsetTimeSeconds: mediaFile.videoOffsetSeconds,
            }),
            {
                headers: {
                    'Content-Type': 'application/json',
                },
            }
        )
    }

    deleteMediaFile = (project: string, sessionId: string, mediaFile: MediaFile): Promise<AxiosResponse<string>> => {
        return this.remotiveApiClient.delete(
            `/api/project/${project}/files/recording/${sessionId}/media/${mediaFile.fileName}`
        )
    }

    deleteBrokerConfigFolder = (
        project: string,
        sessionId: string,
        brokerConfigurationFolder: BrokerConfigurationFolder
    ): Promise<AxiosResponse<string>> => {
        return this.remotiveApiClient.delete(
            `/api/project/${project}/files/recording/${sessionId}/configuration/${brokerConfigurationFolder.name}`
        )
    }

    async uploadRecordingSessionMediaFile(
        projectName: string,
        recordingId: string,
        file: File,
        config: any
    ): Promise<AxiosResponse<string>> {
        const uploadUrlResponse: AxiosResponse<any> = await this.remotiveApiClient.put(
            `/api/project/${projectName}/files/recording/${recordingId}/media/${file.name}`
        )
        const { url, contentType } = uploadUrlResponse.data
        const apiConfig = {
            headers: {
                'Content-type': contentType,
            },
        }
        return this.externalApiClient.put(url, file, apiConfig)
    }

    /* Signal databases */

    listSignalDatabases = (project: string): Promise<AxiosResponse<Array<SignalDatabase>>> => {
        return this.remotiveApiClient.get(`/api/project/${project}/files/config`)
    }

    deleteSignalDatabase = (project: string, fileName: string): Promise<AxiosResponse<string>> => {
        return this.remotiveApiClient.delete(`/api/project/${project}/files/config/${fileName}`)
    }

    downloadSignalDatabase = (project: string, fileName: string): Promise<AxiosResponse<string>> => {
        return this.remotiveApiClient.get(`/api/project/${project}/files/config/${fileName}/download`)
    }

    uploadSignalDatabase(project: string, file: File): Promise<AxiosResponse<string>> {
        const f = new FormData()
        f.append(file.name, file)
        return this.remotiveApiClient.post(`/api/project/${project}/files/config/${file.name}/uploadfile`, f, {
            headers: {
                'Content-Type': 'multipart/*',
            },
        })
    }

    /* Projects */

    listProjects = (bu: string): Promise<AxiosResponse<UserBillableUnitInfo>> => {
        return this.remotiveApiClient.get(`/api/bu/${bu}/me`)
    }

    createProject = (
        request: CreateProjectRequest,
        ownerOrganisationUid: string
    ): Promise<AxiosResponse<UserBillableUnitInfo>> => {
        return this.remotiveApiClient.post(`/api/bu/${ownerOrganisationUid}/project`, JSON.stringify(request), {
            headers: {
                'Content-Type': 'application/json',
            },
        })
    }

    deleteProject = (project: Project): Promise<any> => {
        return this.remotiveApiClient.delete(`/api/project/${project.uid}`)
    }

    /* Brokers */

    listBrokers = (project: string): Promise<AxiosResponse<Array<BrokerContainer>>> => {
        return this.remotiveApiClient.get(`/api/project/${project}/brokers`)
    }

    getBroker = (project: string, brokerName: string): Promise<AxiosResponse<BrokerContainer>> => {
        return this.remotiveApiClient.get(`/api/project/${project}/brokers/${brokerName}`)
    }

    createBroker = (
        project: string,
        brokerName: string,
        apiKey: string | undefined,
        tag: string | undefined
    ): Promise<AxiosResponse<BrokerContainer>> => {
        const RESOURCE_SIZE = 'S'
        return this.remotiveApiClient.post(
            `/api/project/${project}/brokers/${brokerName}`,
            {
                size: RESOURCE_SIZE,
                apiKey: apiKey,
                tag: tag?.trim().length === 0 ? undefined : tag?.trim(),
            },
            {
                headers: {
                    'Content-Type': 'application/json',
                },
            }
        )
    }

    deleteBroker = (project: string, brokerName: string): Promise<AxiosResponse<BrokerContainer>> => {
        return this.remotiveApiClient.delete(`/api/project/${project}/brokers/${brokerName}`)
    }

    getSignalsWithQuery = (project: string, sessionId: string, query: string): Promise<AxiosResponse<SignalList>> => {
        return this.remotiveApiClient.post(`/api/project/${project}/analyze/recording/${sessionId}/signals`, {
            query: query,
        })
    }

    listSignalNames = (project: string, sessionId: string): Promise<AxiosResponse<SignalNameList>> => {
        return this.remotiveApiClient.get(`/api/project/${project}/analyze/recording/${sessionId}/signals`)
    }
}

// Sneaky little singleton
export default new MonolithApi()
