import { WarningRounded } from '@mui/icons-material'
import { ConnectionState } from '../../services/LicenseService'
import { getBrokerDetails } from '../../utils/broker'
import { useEffect, useState } from 'react'
import { Spinner } from 'react-bootstrap'

interface AccessPointAnnouncementProps {
    connectionState: ConnectionState
}

export default function AccessPointAnnouncement(props: AccessPointAnnouncementProps) {
    const [keepAnnouncementOpenWhenChangingNetworks, setKeepAnnouncementOpenWhenChangingNetworks] =
        useState<boolean>(false)
    const [storedPublicUrls, setStoredPublicUrls] = useState<Array<string>>([])

    useEffect(() => {
        if (connectedToBrokerAccessPointWithAssignedIpAddress()) {
            setKeepAnnouncementOpenWhenChangingNetworks(true)
            setStoredPublicUrls(props.connectionState.brokerPublicUrls || [])
        }
    }, [props.connectionState])

    const connectedToBrokerAccessPointWithoutAssignedIpAddress = () => {
        return (
            getBrokerDetails().isConnectedToBrokerAccessPoint() &&
            props.connectionState.clientIsConnectedToBroker &&
            (props.connectionState.brokerPublicUrls === undefined ||
                (props.connectionState.brokerPublicUrls && props.connectionState.brokerPublicUrls.length <= 0))
        )
    }

    const connectedToBrokerAccessPointWithAssignedIpAddress = () => {
        return (
            getBrokerDetails().isConnectedToBrokerAccessPoint() &&
            props.connectionState.brokerPublicUrls &&
            props.connectionState.brokerPublicUrls.length > 0
        )
    }

    const accessPointTitleMessage = () => {
        return (
            <div className="d-flex align-items-center justify-content-center mb-3">
                <div className="mx-2">
                    <WarningRounded sx={{ fontSize: 25 }} />
                </div>
                <div>
                    <p className="lexend-bold remotive-font-md mb-0">
                        You are accessing this{' '}
                        <span className="remotive-primary-50-color lexend-bold">RemotiveBroker</span> via its' access
                        point
                    </p>
                </div>
            </div>
        )
    }

    const connectedToBrokerAccessPointWithoutInternetMessage = () => {
        return (
            <>
                {accessPointTitleMessage()}
                <div className="mx-3">
                    <div>
                        <p className="text-start remotive-font-sm mb-0">
                            To simplify the license acquisition flow, we recommend that you use an ethernet cable to
                            connect the <span className="remotive-primary-50-color lexend-bold">RemotiveBroker</span> to
                            a network with internet access. After that, connect to the{' '}
                            <span className="remotive-primary-50-color lexend-bold">RemotiveBroker</span> via its' IP
                            address on that network instead.
                        </p>
                        <p className="text-start remotive-font-sm text-secondary mb-0">
                            If this is not possible, you can still fetch and apply license by switching between the{' '}
                            <b>access point</b> (remotivelabs-xxx WiFi) and your current <b>WiFi</b> connection. We will
                            interactively guide you through this process.
                        </p>
                    </div>
                </div>
            </>
        )
    }

    const connectedToBrokerAccessPointWithInternetMessage = () => {
        const broksIpAddresses = props.connectionState.brokerPublicUrls || storedPublicUrls
        return (
            <>
                {accessPointTitleMessage()}
                <div className="mx-2">
                    <div>
                        <p className="text-start remotive-font-sm mb-0">
                            It looks like this{' '}
                            <span className="remotive-primary-50-color lexend-bold">RemotiveBroker</span> is already
                            connected to your network, assuming that{' '}
                            <b>{broksIpAddresses.map((it) => it.replaceAll('http://', ''))}</b> is an IP-address on that
                            network. <b>To simplify the license acquisition flow</b> we recommend that you change to
                            your network and then access the{' '}
                            <span className="remotive-primary-50-color lexend-bold">RemotiveBroker</span> on that
                            network instead.
                        </p>
                        <p className="text-start remotive-font-sm text-secondary mb-0">
                            Use the redirect button below to access the{' '}
                            <span className="remotive-primary-50-color lexend-bold">RemotiveBroker</span> via its'
                            IP-address on your network.
                        </p>
                    </div>
                </div>
                <div>
                    <div className="mb-3 mt-4">
                        <div>
                            {!props.connectionState.clientHasInternet && (
                                <div className="d-flex align-items-center justify-content-center mb-1">
                                    <Spinner style={{ height: 9, width: 9 }} size="sm" className="me-2" />
                                    <p className="m-0 remotive-font-xs">
                                        Change to your network and wait up to <b>10 seconds</b> to active the button...
                                    </p>
                                </div>
                            )}
                        </div>
                        {broksIpAddresses.map((url) => (
                            <div key={url}>
                                <button
                                    className={'btn remotive-btn-md remotive-btn-primary'}
                                    disabled={!props.connectionState.clientHasInternet}
                                    onClick={() => {
                                        window.location.href = `${url}/license`
                                    }}
                                >
                                    Redirect using {url}
                                </button>
                                <br />
                            </div>
                        ))}
                    </div>
                </div>
            </>
        )
    }

    return (
        <>
            {(keepAnnouncementOpenWhenChangingNetworks ||
                connectedToBrokerAccessPointWithAssignedIpAddress() ||
                connectedToBrokerAccessPointWithoutAssignedIpAddress()) && (
                <div className="alert alert-warning mb-5" style={{ maxWidth: 910 }}>
                    {!keepAnnouncementOpenWhenChangingNetworks &&
                        connectedToBrokerAccessPointWithoutAssignedIpAddress() &&
                        connectedToBrokerAccessPointWithoutInternetMessage()}
                    {(keepAnnouncementOpenWhenChangingNetworks ||
                        connectedToBrokerAccessPointWithAssignedIpAddress()) &&
                        connectedToBrokerAccessPointWithInternetMessage()}
                </div>
            )}
        </>
    )
}
