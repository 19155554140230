import { getBrokerDetails } from '../../utils/broker'
import { getBrokerVersion } from '../BrokerService'
import { coerce, gte } from 'semver'

type Feature = {
    isEnabled: () => Promise<boolean>
}

const isNotAProperRelease = async () => {
    const brokerVersion = await getBrokerVersion()
    return brokerVersion.startsWith('1.0.0-')
}

const isBrokerVersionGreaterThanOrEqual = async (featureRequiredVersion: string): Promise<boolean> => {
    const brokerVersion = await getBrokerVersion()
    const brokerSemVer = coerce(brokerVersion)
    const featureSemVer = coerce(featureRequiredVersion)

    if (brokerSemVer !== null && featureSemVer !== null) {
        return gte(brokerSemVer, featureSemVer)
    }
    return false
}

export const Features = {
    FRAME_HISTOGRAM: {
        isEnabled: async () =>
            // This feature is enabled for cloud brokers, any brokers running from a non-release commit or if it is a released broker with the proper version
            getBrokerDetails().isCloudBroker() || await isNotAProperRelease() || await isBrokerVersionGreaterThanOrEqual('1.9.0'),
    } as Feature,
}
