import { Container, Form, Modal } from 'react-bootstrap'
import { useRef, useState } from 'react'
import { CloseRounded, WarningRounded } from '@mui/icons-material'
import { toast } from 'react-toastify'
import { formattedToastMessage } from '../../utils/toast'
import { isIframe } from '../../utils/CloudDetails'

export interface CreateConfigurationModalProps {
    show: boolean
    handleCloseFunction: () => void
    handleUploadAndReconfigure: (files: Array<File>) => void
}

type ConfiguredNamespace = {
    file: File
    namespaceName: string
    deviceName: string
    useShortNames: boolean
    signalDatabaseType: string
}

export default function CreateConfigurationModal(props: CreateConfigurationModalProps) {
    const STEPPER_ACTIVE_SIZE = {
        width: '25px',
        height: '25px',
        borderRadius: '20px',
        marginTop: '-7px',
    }
    const STEPPER_INACTIVE_SIZE = {
        width: '12px',
        height: '12px',
        borderRadius: '15px',
    }
    const STEPPER_CIRCLE_OFFSET = '5px'
    const STEPPER_1_INDEX = 0
    const STEPPER_2_INDEX = 1
    const STEPPER_3_INDEX = 2
    const STEPPER_BORDER_COLOR = '#002649'
    const STEPPER_ACTIVE_COLOR = 'remotive-primary-80-background p-0'
    const STEPPER_INACTIVE_COLOR = 'remotive-primary-10-background p-0'

    const [currentStepperIndex, setCurrentStepperIndex] = useState<number>(STEPPER_1_INDEX)
    const [file, setFile] = useState<File>()
    const [configuredNamespaces, setConfiguredNamespaces] = useState<Array<ConfiguredNamespace>>([])
    const [namespace, setNamespace] = useState<string>('my_can0')
    const [signalDbType, setSignalDbType] = useState<string>('can')
    const [deviceName, setDeviceName] = useState<string>('can0')
    const [useShortNames, setUseShortNames] = useState(false)
    const fileInputRef = useRef<HTMLInputElement>(null)

    const closeAndResetModal = () => {
        props.handleCloseFunction()
        setCurrentStepperIndex(STEPPER_1_INDEX)
        setConfiguredNamespaces([])
        resetFields()
    }

    const resetFields = () => {
        if (fileInputRef.current) {
            fileInputRef.current.value = ''
        }
        setFile(undefined)
        setNamespace('')
        setSignalDbType('can')
        setDeviceName('')
    }

    const reconfigureAndClose = () => {
        props.handleCloseFunction()
        setCurrentStepperIndex(0)
        const configAsFile = new File([interfacesJsonConfigAsString()], 'interfaces.json', { type: 'application/json' })
        props.handleUploadAndReconfigure([...configuredNamespaces.map((it) => it.file as File), configAsFile])
        resetFields()
    }

    const getStepper = () => {
        return (
            <div className="row">
                <div
                    className={currentStepperIndex === STEPPER_1_INDEX ? STEPPER_ACTIVE_COLOR : STEPPER_INACTIVE_COLOR}
                    style={{
                        ...(currentStepperIndex === STEPPER_1_INDEX ? STEPPER_ACTIVE_SIZE : STEPPER_INACTIVE_SIZE),
                        borderWidth: '2px',
                        borderColor: STEPPER_BORDER_COLOR,
                        borderStyle: 'solid',
                    }}
                >
                    {currentStepperIndex === STEPPER_1_INDEX && <p className="remotive-font-md text-light">1</p>}
                </div>
                <hr
                    className="align-middle"
                    style={{
                        width: '40px',
                        borderWidth: '2px',
                        borderColor: STEPPER_BORDER_COLOR,
                        opacity: '1',
                        marginTop: STEPPER_CIRCLE_OFFSET,
                    }}
                />
                <div
                    className={currentStepperIndex === STEPPER_2_INDEX ? STEPPER_ACTIVE_COLOR : STEPPER_INACTIVE_COLOR}
                    style={{
                        ...(currentStepperIndex === STEPPER_2_INDEX ? STEPPER_ACTIVE_SIZE : STEPPER_INACTIVE_SIZE),
                        borderWidth: '2px',
                        borderColor: STEPPER_BORDER_COLOR,
                        borderStyle: 'solid',
                    }}
                >
                    {currentStepperIndex === STEPPER_2_INDEX && <p className="remotive-font-md text-light">2</p>}
                </div>
                <hr
                    className="align-middle"
                    style={{
                        width: '40px',
                        borderWidth: '2px',
                        borderColor: STEPPER_BORDER_COLOR,
                        opacity: '1',
                        marginTop: STEPPER_CIRCLE_OFFSET,
                    }}
                />
                <div
                    className={currentStepperIndex === STEPPER_3_INDEX ? STEPPER_ACTIVE_COLOR : STEPPER_INACTIVE_COLOR}
                    style={{
                        ...(currentStepperIndex === STEPPER_3_INDEX ? STEPPER_ACTIVE_SIZE : STEPPER_INACTIVE_SIZE),
                        borderWidth: '2px',
                        borderColor: STEPPER_BORDER_COLOR,
                        borderStyle: 'solid',
                    }}
                >
                    {currentStepperIndex === STEPPER_3_INDEX && <p className="remotive-font-md text-light">3</p>}
                </div>
            </div>
        )
    }

    const isStepperContentVisible = (stepperIndex: number) => {
        if (stepperIndex === currentStepperIndex) {
            return 'd-block'
        }
        return 'd-none'
    }

    const createConfiguredNamespaceKey = (configuredNamespace: ConfiguredNamespace) => {
        return `${configuredNamespace.namespaceName}`
    }

    const removeConfiguredNamespace = (configuredNamespace: ConfiguredNamespace) => {
        const configuredNamespaceToRemoveKey = createConfiguredNamespaceKey(configuredNamespace)
        setConfiguredNamespaces(
            configuredNamespaces.filter((it) => createConfiguredNamespaceKey(it) !== configuredNamespaceToRemoveKey)
        )
    }

    const addCurrentNamespaceConfiguration = () => {
        const configuredNamespaceToAdd = {
            file: file,
            namespaceName: namespace,
            deviceName: deviceName,
            useShortNames: useShortNames,
            signalDatabaseType: signalDbType,
        } as ConfiguredNamespace
        if (
            configuredNamespaces.some(
                (it) =>
                    it.namespaceName === configuredNamespaceToAdd.namespaceName ||
                    it.deviceName === configuredNamespaceToAdd.deviceName
            )
        ) {
            toast.error(
                formattedToastMessage(
                    'Configuration already exists',
                    'A namespace configuration with the specified device name or namespace already exists, please edit the fields and try again.'
                ),
                { autoClose: 10_000 }
            )
        } else {
            setConfiguredNamespaces([...configuredNamespaces, configuredNamespaceToAdd])
            resetFields()
        }
    }

    function prepareFilesToDownload(event: any) {
        const files: FileList = event.target.files
        Array.from(files).forEach((f) => console.log(f.webkitRelativePath))
        setFile(files[0])
    }

    function interfacesJsonConfigAsString() {
        const chains = configuredNamespaces.map((it) => {
            return {
                namespace: it.namespaceName,
                type: it.signalDatabaseType,
                database: it.file.name,
                device_name: it.deviceName,
                short_names: it.useShortNames,
            }
        })
        return JSON.stringify({ chains }, null, 2)
    }

    function renderInterfaceJsonPreview() {
        return (
            <div className={'text-start'}>
                <p>{interfacesJsonConfigAsString()}</p>
            </div>
        )
    }

    const renderconfiguredNamespaces = () => {
        if (configuredNamespaces.length === 0) {
            return (
                <div
                    style={{ height: 200 }}
                    className="d-flex align-items-center flex-column justify-content-center w-100"
                >
                    <WarningRounded className="text-warning me-1" sx={{ fontSize: 35 }} />
                    <p className="m-0 text-secondary remotive-font-sm">
                        You must configure at least one namespace before proceeding
                    </p>
                </div>
            )
        }
        return configuredNamespaces.map((it) => {
            return (
                <div
                    className="rounded shadow-sm p-1 pt-0 remotive-primary-20-background me-2 mb-2 text-truncate"
                    key={createConfiguredNamespaceKey(it)}
                >
                    <div className="text-truncate">
                        <div className="d-flex justify-content-between align-items-end">
                            <button className="invisible btn remotive-btn-no-bg p-0 m-0">
                                <CloseRounded sx={{ fontSize: 15 }} />
                            </button>
                            <p className="m-0 remotive-font-sm text-truncate">{it.namespaceName}</p>
                            <button
                                onClick={() => removeConfiguredNamespace(it)}
                                className="btn remotive-btn-no-bg p-0 m-0 ms-2"
                            >
                                <CloseRounded sx={{ fontSize: 15 }} />
                            </button>
                        </div>
                        <div className="text-start px-1 text-truncate">
                            <p className="m-0 text-secondary remotive-font-xxs text-truncate">
                                Type: {it.signalDatabaseType}
                            </p>
                            <p className="m-0 text-secondary remotive-font-xxs text-truncate">File: {it.file.name}</p>
                            <p className="m-0 text-secondary remotive-font-xxs text-truncate">
                                Device name: {it.deviceName}
                            </p>
                            <p className="m-0 text-secondary remotive-font-xxs text-truncate">
                                Using short names: {it.useShortNames ? 'yes' : 'no'}
                            </p>
                        </div>
                    </div>
                </div>
            )
        })
    }

    const downloadFile = (fileName: string, fileContents: string) => {
        // Download file by creating a link, faking a click on it, and then removing the link again
        var link = document.createElement('a')
        link.download = `${fileName}.json`
        var blob = new Blob([fileContents], { type: 'text/plain' })
        link.href = window.URL.createObjectURL(blob)
        link.click()
    }

    function renderWizard() {
        const isAddNamespaceConfigurationAllowed = file && signalDbType !== '' && namespace !== '' && deviceName !== ''
        return (
            <Container className="row m-0 mt-4 mb-2">
                <div className="col-12 text-center">
                    <div>
                        <div className="d-flex justify-content-center">
                            {/* Stepper */}
                            {getStepper()}
                        </div>
                    </div>

                    {/* Step 1 */}
                    <div style={{ minHeight: 500 }} className={'row mt-1 ' + isStepperContentVisible(STEPPER_1_INDEX)}>
                        <div>
                            <p className="m-0 mt-1 fs-6">Configure namespaces</p>
                            <p className="m-0 mb-3 remotive-font-xs text-secondary ">
                                Fill the fields to configure your first namespace
                            </p>
                        </div>
                        <div className="text-center d-flex flex-row">
                            <div className="w-50">
                                <div>
                                    <div className={'text-start'}>
                                        <Form.Group className="p-1 pb-0">
                                            <Form.Label className={'remotive-font-sm m-0'}>
                                                1. Signal database
                                            </Form.Label>
                                            <Form.Control
                                                id="file"
                                                onChange={prepareFilesToDownload}
                                                ref={fileInputRef}
                                                type="file"
                                                size="sm"
                                            />
                                        </Form.Group>
                                        <Form.Group className={'p-1 pb-0'}>
                                            <Form.Label className={'remotive-font-sm m-0'}>
                                                2. Signal database type
                                            </Form.Label>
                                            <Form.Select
                                                aria-label="Default select example"
                                                onChange={(event: any) => setSignalDbType(event.target.value)}
                                            >
                                                <option value="can">can</option>
                                                <option value="canfd">canfd</option>
                                            </Form.Select>
                                        </Form.Group>
                                        <Form.Group className={'p-1 pb-0'}>
                                            <Form.Label className={'remotive-font-sm m-0'}>3. Namespace</Form.Label>
                                            <Form.Control
                                                size={'sm'}
                                                placeholder="E.g. my_can0"
                                                type="text"
                                                value={namespace}
                                                onChange={(event: any) => setNamespace(event.target.value)}
                                            />
                                        </Form.Group>
                                        <Form.Group className={'p-1 pb-0'}>
                                            <Form.Label className={'remotive-font-sm m-0'}>4. Device name</Form.Label>
                                            <Form.Control
                                                size={'sm'}
                                                placeholder="E.g. can0"
                                                type="text"
                                                value={deviceName}
                                                onChange={(event: any) => setDeviceName(event.target.value)}
                                            />
                                        </Form.Group>
                                        <Form.Group className={'p-1 pb-0'}>
                                            <Form.Label className={'small m-0'}>5. Use short names</Form.Label>
                                            <Form.Check
                                                className={'small'}
                                                checked={useShortNames}
                                                type={'switch'}
                                                value={deviceName}
                                                onChange={(event: any) => setUseShortNames(event.target.checked)}
                                            />
                                        </Form.Group>
                                    </div>
                                    <div>
                                        <button
                                            className="btn remotive-btn-md remotive-btn-primary"
                                            disabled={!isAddNamespaceConfigurationAllowed}
                                            onClick={() => addCurrentNamespaceConfiguration()}
                                        >
                                            <span className="d-none d-lg-block">Add namespace configuration</span>
                                            <span className="d-lg-none">Add</span>
                                        </button>
                                        {!isAddNamespaceConfigurationAllowed && (
                                            <div className="d-flex align-items-center justify-content-center mt-1">
                                                <>
                                                    <p className="remotive-font-xs text-secondary m-0 d-none d-sm-block">
                                                        You need to select a file and fill in all the fields to add a
                                                        namespace configuration
                                                    </p>
                                                </>
                                            </div>
                                        )}
                                    </div>
                                </div>
                            </div>
                            <div className="w-50 mt-2">
                                <div
                                    className="ms-3 rounded remotive-primary-5-background p-2"
                                    style={{ minHeight: 333 }}
                                >
                                    <p className="text-start remotive-font-sm lexend-bold mb-1">
                                        Configured namespaces:
                                    </p>
                                    <div className="d-flex flex-wrap">{renderconfiguredNamespaces()}</div>
                                </div>
                            </div>
                        </div>
                        <div>
                            <hr className="my-4 mv-2 w-75 m-auto" />
                            <div className="d-flex align-items-end justify-content-evenly">
                                <button
                                    className="btn remotive-btn remotive-btn-primary"
                                    onClick={() => closeAndResetModal()}
                                >
                                    Close
                                </button>
                                <div>
                                    {configuredNamespaces.length <= 0 && (
                                        <div className="d-flex mb-1">
                                            <p className="remotive-font-xs text-secondary m-0">
                                                Configure at least one namespace
                                            </p>
                                        </div>
                                    )}
                                    <button
                                        className="btn remotive-btn remotive-btn-success"
                                        disabled={configuredNamespaces.length <= 0}
                                        onClick={() => setCurrentStepperIndex(STEPPER_2_INDEX)}
                                    >
                                        Next
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* Step 2 */}
                    <div style={{ minHeight: 500 }} className={'row mt-1 ' + isStepperContentVisible(STEPPER_2_INDEX)}>
                        <div className="text-center">
                            <p className="m-0 mt-1 fs-6">Preview configuration</p>
                            <p className="m-0 mb-3 remotive-font-xs text-secondary ">
                                This is the configuration that we've generated for you
                            </p>

                            <pre
                                style={{
                                    overflowY: 'scroll',
                                    height: '341px',
                                }}
                            >
                                {renderInterfaceJsonPreview()}
                            </pre>
                            <hr className="my-4 w-75 m-auto" />
                            <div className="d-flex justify-content-evenly align-items-end">
                                <button
                                    className="btn remotive-btn remotive-btn-primary"
                                    onClick={() => setCurrentStepperIndex(STEPPER_1_INDEX)}
                                >
                                    Back
                                </button>
                                <button
                                    className="btn remotive-btn remotive-btn-success"
                                    disabled={configuredNamespaces.length <= 0}
                                    onClick={() => setCurrentStepperIndex(STEPPER_3_INDEX)}
                                >
                                    Next
                                </button>
                            </div>
                        </div>
                    </div>
                    {/* Step 3 */}
                    <div style={{ minHeight: 500 }} className={'row mt-12 ' + isStepperContentVisible(STEPPER_3_INDEX)}>
                        <div className="text-center">
                            <p className="m-0 mt-1 fs-6">You're almost done!</p>
                            <p className="m-0 mb-3 remotive-font-xs text-secondary ">Decide what to do next</p>

                            <div
                                style={{ height: 341 }}
                                className="d-flex justify-content-center flex-column align-items-center"
                            >
                                <p className="mx-5" style={{ maxWidth: 500 }}>
                                    You can now download the configuration file or reconfigure this broker to use the
                                    generated configuration file
                                </p>

                                <div className="d-flex justify-content-center">
                                    <button
                                        className="btn me-3 remotive-btn-md remotive-btn-secondary"
                                        onClick={() => downloadFile('generated-config', interfacesJsonConfigAsString())}
                                    >
                                        Download
                                    </button>

                                    <button
                                        className="btn remotive-btn-md remotive-btn-primary"
                                        onClick={() => reconfigureAndClose()}
                                    >
                                        Reconfigure
                                    </button>
                                </div>
                            </div>

                            <hr className="my-4 w-75 m-auto" />
                            <div className="d-flex justify-content-evenly">
                                <button
                                    className="btn remotive-btn remotive-btn-primary"
                                    onClick={() => setCurrentStepperIndex(STEPPER_2_INDEX)}
                                >
                                    Back
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </Container>
        )
    }

    return (
        <Modal show={props.show} size="xl" onHide={closeAndResetModal}>
            <Modal.Header closeVariant="white" className="remotive-primary-70-background" closeButton>
                <Modal.Title className="text-light lexend-regular">Create configuration</Modal.Title>
            </Modal.Header>
            <Modal.Body>{renderWizard()}</Modal.Body>
        </Modal>
    )
}
