export const BROKER_URL_LOCAL_STORAGE_KEY = 'brokerUrlKey'
export const BROKER_API_KEY_LOCAL_STORAGE_KEY = 'brokerApiKey'

//export const CLOUD_PROJECT_NAME = 'cloudProjectName'
//export const CLOUD_RECORDING_SESSION = 'cloudRecordingSession'

/*export function getCloudProject(): string | null {
    return localStorage.getItem(CLOUD_PROJECT_NAME)
}

export function setCloudProject(project: string) {
    return localStorage.setItem(CLOUD_PROJECT_NAME, project)
}

export function setCloudRecordingSession(project: string) {
    return localStorage.setItem(CLOUD_RECORDING_SESSION, project)
}

export function getCloudRecordingSession(): string | null {
    return localStorage.getItem(CLOUD_RECORDING_SESSION)
}*/

export function getUserAuthToken() {
    return process.env.REACT_APP_BACKEND_AUTH_COOKIE || getCookie('auth')
}

function getCookie(name: string) {
    return document.cookie
        .split(';')
        .map((row) => row.trim())
        .find((row) => row.startsWith(`${name}=`))
        ?.split('=')[1]
}

export function setBrokerApiKey(brokerApiKey?: string | null) {
    if (brokerApiKey) {
        return localStorage.setItem(BROKER_API_KEY_LOCAL_STORAGE_KEY, brokerApiKey)
    }
}

export function getBrokerApiKey(): string | undefined {
    return localStorage.getItem(BROKER_API_KEY_LOCAL_STORAGE_KEY) || undefined
}

export function setBrokerUrl(brokerUrl?: string | null) {
    if (brokerUrl) {
        return localStorage.setItem(BROKER_URL_LOCAL_STORAGE_KEY, brokerUrl)
    }
}

export function getBrokerUrl(): string {
    return localStorage.getItem(BROKER_URL_LOCAL_STORAGE_KEY) || `http://${window.location.hostname}:50051`
}

export function getBrokerDetails() {
    const isCloudBroker = getBrokerUrl().startsWith('https') && localStorage.getItem('overrideIsCloudBroker') === null
    return {
        brokerUrl: getBrokerUrl(),
        brokerApiKey: getBrokerApiKey(),
        brokerAccessToken: getUserAuthToken(),
        isCloudBroker: (): boolean => isCloudBroker,
        isConnectedToBrokerAccessPoint: (): boolean => getBrokerUrl().match(/^http:\/\/192\.168\.4\.1:/) != null,
    }
}
