// Order of these Chart dependencies are important!
import {
    Chart as ChartJS,
    LinearScale,
    Title,
    Tooltip as ChartJSTooltip,
    Legend,
    BarElement,
    ChartOptions,
    BarController,
    TimeScale,
} from 'chart.js'
import { Chart } from 'react-chartjs-2'
import 'chartjs-adapter-luxon' //This is used by chart js streaming to handle realtime

import { useEffect, useRef } from 'react'
import { FrameDistribution } from '../../../types/FrameDistribution'

ChartJS.register(
    //Scales
    TimeScale, // x-axis
    LinearScale, // y-axis

    // Chart types
    BarController,
    BarElement,

    //Plugins
    ChartJSTooltip,
    Legend,
    Title
)

interface FrameDistributionChartProps {
    GRAPH_WIDTH_MS: number
    data: Array<FrameDistribution>
    shouldPauseStreaming: boolean
    onRefreshFunction: () => void
    chartKey: string
}

export default function FrameDistributionChart(props: FrameDistributionChartProps) {
    const chartRef = useRef<ChartJS<'bar', { x: number; y: string | number | number[] | string[] }[], string>>(null)

    useEffect(() => {
        chartRef.current?.update('none')
    }, [props.data])

    const convertFrameDistributionToPlottableData = (frameDistribution: FrameDistribution): any => {
        const frequencyCount = frameDistribution.frameIdFrequency.map((it) => Number(it.frequency))
        return {
            x: frameDistribution.receivedTimestamp,
            y:
                frequencyCount.length > 0
                    ? frequencyCount.reduce((accumulator, currentValue) => accumulator + currentValue)
                    : 0,
        }
    }

    const getOptions = (): ChartOptions => {
        return {
            responsive: true,
            parsing: false,
            maintainAspectRatio: false,
            animation: false, // disable animations
            plugins: {
                legend: {
                    display: false,
                },
                tooltip: {
                    callbacks: {
                        title: () => `Total number of frames`,
                        afterTitle: () => {
                            return 'during this period:'
                        },
                    },
                },
            },
            scales: {
                x: {
                    display: true,
                    type: 'time',
                    time: {
                        unit: 'second',
                    },
                    ticks: {
                        callback(tickValue, index, ticks) {
                            const time = new Date(tickValue)
                            const timeString = `${time.getUTCHours().toString().padStart(2, '0')}:${time
                                .getUTCMinutes()
                                .toString()
                                .padStart(2, '0')}:${time.getUTCSeconds().toString().padStart(2, '0')}`
                            if (window.innerWidth < 450) {
                                // Small screens and below, E.g. iPhones
                                if ((Number(tickValue) / 1000) % 5 === 0) {
                                    // One tick per 5 seconds
                                    return timeString
                                }
                                return undefined
                            }
                            if (window.innerWidth < 680) {
                                // Up to medium screens, E.g. tablets
                                if ((Number(tickValue) / 1000) % 3 === 0) {
                                    // One tick per 3 seconds
                                    return timeString
                                }
                                return undefined
                            }
                            if (window.innerWidth < 1500) {
                                // Up to large screens, almost full width of a 13" laptop
                                if ((Number(tickValue) / 1000) % 2 === 0) {
                                    // One tick per 2 seconds
                                    return timeString
                                }
                                return undefined
                            }
                            // All other screens larger full width of 13" lapotop, one tick per second
                            return timeString
                        },
                        align: 'inner',
                        maxRotation: 0,
                        minRotation: 0,
                        autoSkip: false,
                        font: {
                            family: 'LexendDecaRegular',
                        },
                        mirror: false,
                    },
                },
                y: {
                    ticks: {
                        font: {
                            family: 'LexendDecaRegular',
                        },
                        mirror: false,
                        align: 'end',
                    },
                },
            },
        } as ChartOptions
    }

    const data = () => {
        const dataToPlot = props.data.map((it) => convertFrameDistributionToPlottableData(it))
        return {
            datasets: [
                {
                    data: dataToPlot,
                    borderWidth: 1.0,
                    maxBarThickness: 100,
                    barThickness: 30,
                    backgroundColor: '#05315aB3',
                    borderColor: '#05315a',
                },
            ],
        }
    }

    return (
        <div className={`w-100 h-100`}>
            <Chart type={'bar'} options={getOptions()} ref={chartRef} data={data()} />
        </div>
    )
}
