import { Form, Spinner } from 'react-bootstrap'
import { Container } from 'react-bootstrap'
import { Dangerous, DangerousRounded, Warning } from '@mui/icons-material'
import { useEffect, useState } from 'react'
import CreateConfigurationModal from './modal/CreateConfigurationModal'
import Linkify from 'linkify-react'
import { getInterfacesJsonAsString, reloadConfiguration, uploadFiles } from '../services/BrokerService'
import { ConnectionState } from '../services/LicenseService'
import { ConfigurationInfo } from '../api/BrokerApi/types'
import DisconnectedBrokerText from './DisconnectedBrokerText'

interface ConfigurationCardProps {
    connectionState: ConnectionState
    isLicensed: boolean
}

export default function ConfigurationContainer(props: ConfigurationCardProps) {
    const [showCreateConfigModal, setShowCreateConfigModal] = useState(false)

    const [filesToUpload, setFilesToUpload] = useState<FileList>()
    const [currentConfiguration, setCurrentConfiguration] = useState<ConfigurationInfo>({
        jsonString: '',
        infoMessage: undefined,
    })
    const [reconfigureError, setReconfigureError] = useState<string>()
    const [reConfigureInProgress, setReconfigureInProgress] = useState(false)

    useEffect(() => {
        const getConfig = async () => {
            setCurrentConfiguration(await getInterfacesJsonAsString())
        }
        getConfig()
    }, [])

    function hasFilesToUpload() {
        return filesToUpload && filesToUpload.length > 0
    }

    function prepareFilesToDownload(event: any) {
        const files: FileList = event.target.files
        setFilesToUpload(files)
    }

    async function uploadAndReconfigure(files: Array<File>) {
        try {
            setReconfigureInProgress(true)
            setReconfigureError(undefined)
            await uploadFiles(files, (bytes, percent) => console.log(`${bytes} ${percent}`))
            setCurrentConfiguration(await reloadConfiguration())
            setReconfigureInProgress(false)
        } catch (e: any) {
            console.log(e)
            setReconfigureInProgress(false)
            setReconfigureError(e.message)
        }
    }

    function renderFilesToBeUploaded(files: FileList) {
        return (
            <>
                {Array.from(files).map((file) => (
                    <i key={file.webkitRelativePath}>
                        {file.webkitRelativePath.substring(file.webkitRelativePath.indexOf('/') + 1)}
                    </i>
                ))}
            </>
        )
    }

    const containerContent = () => {
        return (
            <>
                <div className="col-12 mb-4 p-0">
                    {!props.connectionState.clientIsConnectedToBroker && <DisconnectedBrokerText />}

                    {props.connectionState.clientIsConnectedToBroker && (
                        <Container className="row align-vertical, text-start mt-4">
                            <h3 className="lexend-bold">Configure</h3>
                            <p>
                                Here you can configure your RemotiveBroker for recording, playback or just looking at
                                data from a vehicle or rig
                            </p>
                            <div className="pt-3">
                                <h5 className="lexend-bold">No configuration?</h5>
                                <p>
                                    You can create a simple configuration from a dbc file of your choice using the
                                    configuration wizard{' '}
                                </p>
                                <button
                                    className="btn remotive-btn remotive-btn-primary"
                                    onClick={() => setShowCreateConfigModal(true)}
                                >
                                    configuration wizard
                                </button>
                            </div>
                            <div className="pt-3">
                                <h5 className="lexend-bold">Already have a configuration prepared?</h5>
                                <p>Reconfigure the RemotiveBroker device by uploading a configuration directory.</p>
                                <Form.Group className="mb-3">
                                    <Form.Label>Pick a configuration directory</Form.Label>
                                    <Form.Control
                                        id="fileupload"
                                        onChange={prepareFilesToDownload}
                                        type="file"
                                        size="sm"
                                        {...{
                                            directory: '',
                                            multiple: true,
                                            webkitdirectory: '',
                                            allowdirs: '',
                                        }}
                                    />
                                </Form.Group>
                                <p>
                                    Configuration directory templates can be found on{' '}
                                    <a
                                        href={
                                            'https://github.com/remotivelabs/remotivelabs-samples/tree/main/python/simple-ecu'
                                        }
                                        target={'_blank'}
                                        rel="noreferrer"
                                    >
                                        github
                                    </a>
                                </p>
                            </div>

                            {hasFilesToUpload() && (
                                <>
                                    <b>The following files will be uploaded and used as configuration</b>
                                    {renderFilesToBeUploaded(filesToUpload as FileList)}
                                </>
                            )}
                            {hasFilesToUpload() && !reConfigureInProgress && (
                                <div>
                                    <br />
                                    <button
                                        className="btn remotive-btn remotive-btn-primary"
                                        onClick={() => uploadAndReconfigure(Array.from(filesToUpload as FileList))}
                                    >
                                        Reload configuration
                                    </button>
                                    <br />
                                    <br />
                                </div>
                            )}

                            {reConfigureInProgress && (
                                <>
                                    &nbsp;&nbsp;
                                    <Spinner className="spinner-border p-1" role="status" />
                                </>
                            )}

                            {reconfigureError && (
                                <div
                                    className={'text-start'}
                                    style={{
                                        borderStyle: 'dashed',
                                        padding: '1rem',
                                    }}
                                >
                                    <h4 className={'d-flex align-items-center'} style={{ marginBottom: '8px' }}>
                                        <DangerousRounded sx={{ fontSize: 40, color: 'red' }} />
                                        <span style={{ margin: '0 0 0 10px' }}>
                                            An error occurred while configuring the broker
                                        </span>
                                    </h4>
                                    <Linkify
                                        as="div"
                                        style={{ whiteSpace: 'pre-wrap' }}
                                        options={{
                                            target: '_blank',
                                        }}
                                    >
                                        {reconfigureError}
                                    </Linkify>
                                </div>
                            )}

                            {currentConfiguration.infoMessage && (
                                <div
                                    className={'text-start'}
                                    style={{
                                        borderStyle: 'dashed',
                                        padding: '1rem',
                                    }}
                                >
                                    <h4 className={'d-flex align-items-center'} style={{ marginBottom: '8px' }}>
                                        <Warning sx={{ fontSize: 40, color: '#f7ae01' }} />
                                        <span style={{ margin: '0 0 0 10px' }}>
                                            Take a look at the following configuration warnings
                                        </span>
                                    </h4>
                                    <Linkify
                                        as="div"
                                        style={{ whiteSpace: 'pre-wrap' }}
                                        options={{
                                            target: '_blank',
                                        }}
                                    >
                                        {currentConfiguration.infoMessage}
                                    </Linkify>
                                </div>
                            )}

                            <div className="mt-2">
                                <hr />
                                {currentConfiguration.jsonString.length > 0 && (
                                    <>
                                        <h4 className="remotive-primary-70-color">Current configuration</h4>
                                        <pre className={'small'}>{currentConfiguration.jsonString}</pre>
                                    </>
                                )}
                            </div>
                        </Container>
                    )}
                </div>
                <CreateConfigurationModal
                    show={showCreateConfigModal}
                    handleCloseFunction={() => setShowCreateConfigModal(false)}
                    handleUploadAndReconfigure={uploadAndReconfigure}
                />
            </>
        )
    }

    return <>{containerContent()}</>
}
