import { Modal } from 'react-bootstrap'
import { isIframe } from '../../utils/CloudDetails'

interface ForceRefreshModalProps {
    show: boolean
    handleCloseFunction: () => void
}

function ForceRefreshModal(props: ForceRefreshModalProps) {
    return (
        <>
            <Modal
                style={{ zIndex: '10000' }}
                className="col-12"
                show={props.show}
                onHide={props.handleCloseFunction}
                backdropClassName="z-index-max-background"
            >
                <Modal.Header closeVariant="white" className="remotive-primary-70-background" closeButton>
                    <Modal.Title className="lexend-regular text-light">Connection lost</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="row text-start">
                        <p>Connection lost due to a configuration change or network issues.</p>
                        <p className="remotive-small remotive-neutral-70-color">
                            {' '}
                            Press <b>Refresh</b> if the configuration is updated or <b>Cancel</b> to close this window
                        </p>
                    </div>
                    <div className="d-flex justify-content-end">
                        <button
                            className="btn remotive-btn remotive-btn-primary m-1"
                            onClick={props.handleCloseFunction}
                        >
                            Cancel
                        </button>
                        <button
                            className="btn remotive-btn remotive-btn-success m-1"
                            onClick={() => window.location.reload()}
                        >
                            Refresh
                        </button>
                    </div>
                </Modal.Body>
            </Modal>
        </>
    )
}

export default ForceRefreshModal
