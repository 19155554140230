import { useEffect, useState } from 'react'
import { Modal, InputGroup, Form, Button, Card } from 'react-bootstrap'
import { ContentCopyRounded } from '@mui/icons-material'

import { setBrokerUrl, setBrokerApiKey, getBrokerDetails } from '../../utils/broker'
import { getBrokerVersion } from '../../services/BrokerService'
import { BrokerDetails } from '../../api/BrokerApi/types'
import { title } from 'process'
import { isIframe } from '../../utils/CloudDetails'

interface BrokerDetailsContainerProps {
    handleCloseFunction: () => void
}

const COPY_MESSAGE_RESET_TIMER = 2000

export default function BrokerDetailsContainer(props: BrokerDetailsContainerProps) {
    const [brokerVersion, setBrokerVersion] = useState<string>('unknown')
    const [currentBrokerUrl, setCurrentBrokerUrl] = useState<string>('')
    const [currentBrokerApiKey, setCurrentBrokerApiKey] = useState<string | undefined>(undefined)
    const [isApiKeyCopied, setIsApiKeyCopied] = useState<boolean>(false)
    const [isUrlCopied, setIsUrlCopied] = useState<boolean>(false)

    useEffect(() => {
        console.log('Mounting broker details modal!')
        setCurrentBrokerVersion()
        const brokerDetails = getBrokerDetails()
        setCurrentBrokerUrl(brokerDetails.brokerUrl)
        setCurrentBrokerApiKey(brokerDetails.brokerApiKey)
    }, [])

    useEffect(() => {
        if (isUrlCopied) {
            const timer = setTimeout(() => setIsUrlCopied(false), COPY_MESSAGE_RESET_TIMER)
            return () => clearTimeout(timer)
        }
    }, [isUrlCopied])

    useEffect(() => {
        if (isApiKeyCopied) {
            const timer = setTimeout(() => setIsApiKeyCopied(false), COPY_MESSAGE_RESET_TIMER)
            return () => clearTimeout(timer)
        }
    }, [isApiKeyCopied])

    const body = () => {
        return (
            <div>
                <InputGroup size="sm" className="mb-1">
                    <InputGroup.Text className="remotive-primary-40-background text-light remotive-primary-40-border">
                        URL
                    </InputGroup.Text>
                    <Form.Control
                        className="bg-white remotive-primary-40-border"
                        disabled={isIframe()}
                        value={currentBrokerUrl}
                        onChange={(event) => setCurrentBrokerUrl(event.target.value)}
                    />
                    <Button className="remotive-btn-primary" onClick={() => copyBrokerUrl()}>
                        {getCopyIcon(isUrlCopied)}
                    </Button>
                </InputGroup>
                <InputGroup size="sm" className="mb-1">
                    <InputGroup.Text className="remotive-primary-40-background text-light remotive-primary-40-border">
                        API Key
                    </InputGroup.Text>
                    <Form.Control
                        className="bg-white remotive-primary-40-border"
                        disabled={isIframe()}
                        value={currentBrokerApiKey}
                        onChange={(event) => setCurrentBrokerApiKey(event.target.value)}
                    />
                    <Button className="remotive-btn-primary" onClick={() => copyApiKey()}>
                        {getCopyIcon(isApiKeyCopied)}
                    </Button>
                </InputGroup>
                <div className="d-flex flex-column align-items-end mt-2 mb-0">
                    <p className="m-0 remotive-font-sm text-secondary">
                        RemotiveBroker Application: {process.env.REACT_APP_VERSION}
                    </p>
                    <p className="m-0 remotive-font-sm text-secondary">RemotiveBroker: {brokerVersion}</p>
                </div>
            </div>
        )
    }

    const footer = () => {
        return (
            <div className="border-0 d-flex justify-content-end my-3 text-end me-2">
                <button className="btn remotive-btn remotive-btn-primary me-3" onClick={props.handleCloseFunction}>
                    Cancel
                </button>
                <div title={isIframe() ? "You can't change the broker URL or API Key unless you open this view in a new window": "This will force a refresh and then the new URL and API Key will be used"}>
                    <button
                        disabled={isIframe()}
                        className="btn remotive-btn remotive-btn-success"
                        onClick={saveAndRefresh}
                    >
                        Save
                    </button>
                </div>
            </div>
        )
    }

    const title = () => {
        return <div className="text-start lexend-regular text-light">Broker details</div>
    }

    const setCurrentBrokerVersion = async () => {
        try {
            const version = await getBrokerVersion()
            setBrokerVersion(version)
        } catch (e: any) {
            console.warn(`Failed to fetch broker version for BrokerDetailsModal. Error: \n${JSON.stringify(e)}`)
            setBrokerVersion('N/A')
        }
    }

    const getCopyIcon = (isCopied: boolean) => {
        if (isCopied) {
            return 'Copied!'
        }
        return <ContentCopyRounded sx={{ fontSize: 17 }} />
    }

    const copyBrokerUrl = () => {
        if (currentBrokerUrl !== undefined) {
            navigator.clipboard.writeText(currentBrokerUrl)
            setIsUrlCopied(true)
        }
    }

    const copyApiKey = () => {
        if (currentBrokerApiKey !== undefined) {
            navigator.clipboard.writeText(currentBrokerApiKey)
            setIsApiKeyCopied(true)
        }
    }

    const saveAndRefresh = () => {
        setBrokerUrl(currentBrokerUrl)
        setBrokerApiKey(currentBrokerApiKey)
        window.location.reload()
    }

    return (
        <div className="lexend-regular">
            <div className="remotive-primary-70-background remotive-font-xxl p-3 rounded-top-2">{title()}</div>
            <div className="p-2">
                <div>{body()}</div>
                <div>{footer()}</div>
            </div>
        </div>
    )
}
