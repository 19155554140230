import { useState } from 'react'
import { Modal, Tooltip, OverlayTrigger, InputGroup, Form } from 'react-bootstrap'
import Info from '@mui/icons-material/InfoRounded'
import { Namespace } from '../../api/BrokerApi/types'
import { isIframe } from '../../utils/CloudDetails'


interface CreateRecordingModalProps {
    show: boolean
    handleCloseFunction: () => void
    handleCreateFunction: (namespaces: Array<Namespace>, filename: string) => void
    namespaces: Array<Namespace>
}

const TOOLTIP_NAMESPACES = (
    <Tooltip id="tooltip-create-recording-namespaces">
        You can select multiple namespaces to record, each namespace selected will generate its' own file. The namespace
        name will be appended to the selected filename
    </Tooltip>
)
const TOOLTIP_FILENAME = (
    <Tooltip id="tooltip-create-recording-filename">
        The filename will have the selected namespace(s) appended along with an underscore. If any two files have the
        same name it will be overwritten by the latest file.
    </Tooltip>
)
const FILE_NAME_INPUT = 'filename-input-field'
const SUPER_OBSCURE_STRING_PATTERN = '$_.-._$'

function CreateRecordingModal(props: CreateRecordingModalProps) {
    const [selectedNamespaces, setSelectedNamespaces] = useState<Array<Namespace>>([])

    const renderNamespaces = () => {
        if (props.namespaces.length > 0) {
            return props.namespaces.map((namespace) => {
                return (
                    <div
                        key={namespace.name}
                        className="d-flex rounded px-2 pb-1 pt-2 m-1 remotive-primary-20-background"
                    >
                        <Form.Check
                            type="checkbox"
                            className="m-0 pb-0 text-break remotive-small remotive-neutral-80-color"
                            label={namespace.name}
                            id={`${namespace.type}${SUPER_OBSCURE_STRING_PATTERN}${namespace.name}`}
                            onClick={(element) => {
                                const checkbox = element.target as HTMLInputElement
                                if (!checkbox.checked) {
                                    const namespacesAfterRemoval = selectedNamespaces.filter(
                                        (selectedNamespace) =>
                                            `${selectedNamespace.type}${selectedNamespace.name}` !==
                                            `${namespace.type}${namespace.name}`
                                    )
                                    setSelectedNamespaces(namespacesAfterRemoval)
                                } else {
                                    setSelectedNamespaces([...selectedNamespaces, namespace])
                                }
                            }}
                        />
                    </div>
                )
            })
        }
        return <p className="remotive-small">No namespaces available...</p>
    }

    const validateInputFields = (namespaces: Array<Namespace>, filenameInput: HTMLInputElement | null): boolean => {
        if (namespaces.length <= 0) {
            alert('You must select at least 1 namespace')
            return false
        }
        if (filenameInput === null || filenameInput.value === '') {
            alert('You must set a file name')
            return false
        }
        return true
    }

    const createAndClose = () => {
        const namespaces = selectedNamespaces
        const filenameInput = document.getElementById(FILE_NAME_INPUT) as HTMLInputElement | null
        if (validateInputFields(namespaces, filenameInput)) {
            props.handleCreateFunction(namespaces, filenameInput!!.value)
            setSelectedNamespaces([])
        }
    }

    const close = () => {
        props.handleCloseFunction()
        setSelectedNamespaces([])
    }

    return (
        <>
            <Modal style={{ zIndex: '9999' }} show={props.show} onHide={close}>
                <Modal.Header closeVariant='white' className='remotive-primary-70-background' closeButton>
                    <Modal.Title className="lexend-regular text-light">Recording file</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="col-12">
                        <div className="rounded mb-3">
                            <div className="d-flex justify-content-start d-inline">
                                <div>
                                    <OverlayTrigger placement="bottom" overlay={TOOLTIP_NAMESPACES}>
                                        <Info className="align-top remotive-primary-40-color" sx={{ fontSize: 22 }} />
                                    </OverlayTrigger>
                                </div>
                                <div>
                                    <p className="ms-1">1. Select namespace(s)</p>
                                </div>
                            </div>
                            <div className="d-flex flex-wrap justify-content-start p-1">{renderNamespaces()}</div>
                        </div>
                    </div>

                    <div className="col-12">
                        <div className="rounded  mb-2">
                            <div className="d-flex justify-content-start d-inline">
                                <div>
                                    <OverlayTrigger placement="bottom" overlay={TOOLTIP_FILENAME}>
                                        <Info className="align-top remotive-primary-40-color" sx={{ fontSize: 22 }} />
                                    </OverlayTrigger>
                                </div>
                                <div>
                                    <p className="ms-1">2. Set filename</p>
                                </div>
                            </div>
                            <div className="d-flex justify-content-start p-1">
                                <InputGroup size="sm" className="mb-0">
                                    <Form.Control
                                        placeholder="E.g  xc90_recording..."
                                        aria-label="Small"
                                        aria-describedby="inputGroup-sizing-sm"
                                        id={FILE_NAME_INPUT}
                                    />
                                </InputGroup>
                            </div>
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer className='border-0'>
                    <button className="btn remotive-btn remotive-btn-success" onClick={() => createAndClose()}>
                        Create
                    </button>
                </Modal.Footer>
            </Modal>
        </>
    )
}

export default CreateRecordingModal
