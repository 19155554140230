import { useCallback, useEffect, useRef, useState } from 'react'
import { GoogleMap, useJsApiLoader, Marker } from '@react-google-maps/api'
import { Spinner } from 'react-bootstrap'
import { toast } from 'react-toastify'
import { formattedToastMessage } from '../../../utils/toast'
import { ConnectionState } from '../../../services/LicenseService'

type GoogleWorldMapProps = {
    isCloudBroker: boolean
    connectionState: ConnectionState
    latitude: number
    longitude: number
}

const ZOOM_LEVEL = 17
const MARKER_X = 25 // Magic number, when we use this value here we align our circle with the pin of the default marker
const MARKER_Y = 15 // Magic number, when we use this value here we align our circle with the pin of the default marker
const SVG_MARKER = 'M 37 12.5 c 0 6.63 -5.37 12 -12 12 s -12 -5.37 -12 -12 S 18.37 0.5 25 0.5 s 12 5.37 12 12 Z'

export default function GoogleWorldMap(props: GoogleWorldMapProps) {
    const mapRef = useRef<any>(null)
    const [isInInitialPosition, setIsInInitialPosition] = useState(false)
    const [errorText, setErrorText] = useState<string | undefined>()

    const { isLoaded } = useJsApiLoader({
        id: 'google-map-script',
        googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_KEY || 'N/A',
    })

    useEffect(() => {
        console.log(`Mounting Google Maps map, ${mapRef.current}`)
    }, [])

    useEffect(() => {
        if (errorText !== undefined) {
            toast.error(
                formattedToastMessage(
                    errorText,
                    'Double check the settings in the map panel and make sure that the selected latitude and longitude signals correspond to the actual latitude and longitude values.'
                ),
                { autoClose: 20_000 }
            )
        }
    }, [errorText])

    const onLoad = useCallback((mapInstance: any) => {
        const bounds = new google.maps.LatLngBounds()
        mapRef.current = mapInstance
        mapInstance.fitBounds(bounds)
    }, [])

    useEffect(() => {
        if (!!isLoaded) {
            const typedMap = mapRef.current as google.maps.Map
            if (!!typedMap) {
                if (props.latitude <= 90 && props.longitude <= 180) {
                    setErrorText(undefined)
                    typedMap.panTo(new google.maps.LatLng(props.latitude, props.longitude))
                } else {
                    setErrorText('Invalid latitude or longitude signal value')
                }
            }
        }
    }, [props.latitude, props.longitude])

    useEffect(() => {
        if (!!isLoaded && !isInInitialPosition && props.longitude !== 181 && props.latitude !== 91) {
            zoomAndCenterMarker()
            setIsInInitialPosition(true)
        }
    }, [isLoaded, isInInitialPosition, props.latitude, props.longitude])

    const zoomAndCenterMarker = () => {
        if (isLoaded) {
            const typedMap = mapRef.current as google.maps.Map
            if (!!typedMap) {
                typedMap.setCenter(new google.maps.LatLng(props.latitude, props.longitude))
                typedMap.setZoom(ZOOM_LEVEL)
            }
        }
    }

    return (
        <>
            {isLoaded && (props.isCloudBroker || props.connectionState.clientHasInternet) && (
                <>
                    <GoogleMap
                        options={{ streetViewControl: false, mapTypeControl: false, fullscreenControl: false }}
                        mapContainerClassName="w-100 h-100"
                        onLoad={onLoad}
                    >
                        <Marker
                            onClick={() => {
                                zoomAndCenterMarker()
                            }}
                            icon={{
                                path: SVG_MARKER,
                                strokeColor: 'white',
                                fillColor: '#4086f5',
                                fillOpacity: 1,
                                strokeWeight: 3,
                                anchor: new window.google.maps.Point(MARKER_X, MARKER_Y),
                                scale: 1,
                            }}
                            position={{
                                lat: props.latitude,
                                lng: props.longitude,
                            }}
                        />
                    </GoogleMap>
                </>
            )}
            {!isLoaded && (
                <div className="h-100 w-100 d-flex flex-column align-items-center justify-content-center">
                    <Spinner />
                    <p>Loading...</p>
                </div>
            )}
        </>
    )
}
