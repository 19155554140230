import { BadgeRounded, CheckCircleRounded } from '@mui/icons-material'
import { Spinner } from 'react-bootstrap'

interface LicenseFlowOptionExistingLicenseProps {
    LICENSE_OPTION_CARD_MIN_HEIGHT: number
    LICENSE_OPTION_CARD_MAX_WIDTH: number
    connectedToBroker: boolean
    goToApplyLicenseState: () => void
}

export default function LicenseFlowOptionExistingLicense(props: LicenseFlowOptionExistingLicenseProps) {
    const existingLicenseIcon = () => {
        return (
            <div>
                <BadgeRounded className="remotive-primary-20-color" sx={{ fontSize: 90 }} style={{ marginTop: 0 }} />
                <CheckCircleRounded
                    className="position-absolute remotive-success-60-color"
                    sx={{ marginTop: 7, marginLeft: -4, fontSize: 40, zIndex: 1 }}
                />
            </div>
        )
    }

    const existingLicenseOption = () => {
        return (
            <div
                className="rounded-4 remotive-primary-10-background p-4"
                style={{ minHeight: props.LICENSE_OPTION_CARD_MIN_HEIGHT }}
            >
                <div
                    style={{ maxWidth: props.LICENSE_OPTION_CARD_MAX_WIDTH }}
                    className="d-flex flex-column align-items-center"
                >
                    <div className="mb-4">{existingLicenseIcon()}</div>
                    <p className="fs-5 m-0">I already have a license</p>
                    <p className="remotive-font-sm m-0 text-secondary">
                        If you already have your license available, you will simply be asked to supply your license file{' '}
                        <b>or</b> license phrase and the corresponding email address
                    </p>
                    <div className="mt-3">
                        <div>
                            {!props.connectedToBroker && (
                                <div className="d-flex align-items-center justify-content-center mt-4 mb-2">
                                    <Spinner style={{ height: 10, width: 10 }} size="sm" className="me-2" />
                                    <p className="m-0 remotive-font-xs text-secondary">
                                        Connect to the{' '}
                                        <span className="remotive-primary-50-color lexend-bold">RemotiveBroker</span> to
                                        proceed...
                                    </p>
                                </div>
                            )}
                        </div>
                        <button
                            className="btn remotive-btn remotive-btn-success"
                            disabled={!props.connectedToBroker}
                            onClick={() => props.goToApplyLicenseState()}
                        >
                            Apply license
                        </button>
                    </div>
                </div>
            </div>
        )
    }

    return existingLicenseOption()
}
