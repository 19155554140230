import { Accordion, Container, Form, Modal } from 'react-bootstrap'
import { useEffect, useState } from 'react'
import { compare, validate } from 'compare-versions'
import { ConnectionState, getClientVersions, getServerVersions } from '../../services/LicenseService'
import { getBrokerVersion, upgrade } from '../../services/BrokerService'

interface UpgradeCardProps {
    isCloudBroker: boolean
    connectionState: ConnectionState
    isLicensed: boolean
}

export default function UpgradeContainer(props: UpgradeCardProps) {
    const [showModal, setShowModal] = useState(false)
    const [availableBrokerVersions, setAvailableBrokerVersions] = useState<Array<string>>([])
    const [availableClientVersions, setAvailableClientVersions] = useState<Array<string>>([])
    const [currentBrokerVersion, setCurrentBrokerVersion] = useState<string>()
    const [currentClientVersion, setCurrentClientVersion] = useState<string>(process.env.REACT_APP_VERSION || 'unknown')

    useEffect(() => {
        const setAvailableVersions = async () => {
            try {
                setAvailableBrokerVersions(await getServerVersions())
            } catch (e: any) {
                console.log(`Failed to get server versions: ${e.message}`)
            }

            try {
                setAvailableClientVersions(await getClientVersions())
            } catch (e: any) {
                console.log(`Failed to get client versions: ${e.message}`)
            }
        }

        const setBrokerVersion = async () => {
            setCurrentBrokerVersion(await getBrokerVersion())
        }
        setAvailableVersions()
        setBrokerVersion()
        console.log(currentClientVersion)
    }, [])

    useEffect(() => {
        console.log('getting version')
        const get = async () => {
            setCurrentBrokerVersion(await getBrokerVersion())
        }
        if (props.connectionState.clientIsConnectedToBroker && !currentBrokerVersion) {
            get()
        }
    }, [props.connectionState])

    function getLatestBrokerVersion() {
        if (availableBrokerVersions.length > 0) {
            return availableBrokerVersions.slice(-1)[0] as string
        }
        return undefined
    }

    function getLatestClientVersion() {
        if (availableClientVersions.length > 0) {
            return availableClientVersions.slice(-1)[0] as string
        }
        return undefined
    }

    function hasLaterClientVersion() {
        const latestVersion = getLatestClientVersion()
        if (latestVersion && currentClientVersion && validate(currentClientVersion)) {
            return compare(latestVersion, currentClientVersion, '>')
        } else {
            return false
        }
    }

    function hasLaterServerVersion() {
        const latestVersion = getLatestBrokerVersion()
        if (latestVersion && currentBrokerVersion) {
            return compare(latestVersion, currentBrokerVersion, '>')
        } else {
            return false
        }
    }

    async function doUpgrade(brokerTag: string | undefined, webappTag: string | undefined) {
        try {
            setShowModal(false)

            await upgrade(brokerTag, webappTag)
            window.alert('Software upgrade has been initiated.\nPlease allow up to 10 minutes for completion.')
        } catch (e: any) {
            // Thi can be cancel or an error
            window.alert(e.message)
        }
    }

    interface UpdateModalProps {
        show: boolean
        handleCloseFunction: () => void
        handleInstall: (brokerTag: string | undefined, webclientTag: string | undefined) => void
    }

    function UpdateModal(props: UpdateModalProps) {
        const [brokerTag, setBrokerTag] = useState(undefined)
        const [webappTag, setWebappTag] = useState(undefined)
        const [brokerTagValid, setBrokerTagValid] = useState(true)
        const [webappTagValid, setWebappTagValid] = useState(true)

        function onBrokerTagChanged(event: any) {
            if (event.target.value === '') {
                setBrokerTagValid(true)
                setBrokerTag(undefined)
            } else if (event.target.value.match(/^[a-zA-Z0-9][-.a-zA-Z0-9]{0,127}$/)) {
                setBrokerTag(event.target.value)
                setBrokerTagValid(true)
            } else {
                setBrokerTag(undefined)
                setBrokerTagValid(false)
            }
        }

        function onWebappTagChanged(event: any) {
            if (event.target.value === '') {
                setWebappTagValid(true)
                setWebappTag(undefined)
            } else if (event.target.value.match(/^[a-zA-Z0-9][-.a-zA-Z0-9]{0,127}$/)) {
                setWebappTag(event.target.value)
                setWebappTagValid(true)
            } else {
                setWebappTag(undefined)
                setWebappTagValid(false)
            }
        }

        const optionalItems = () => {
            {
                /* Optional items a collapsed by default */
            }
            return (
                <Accordion defaultActiveKey={undefined} className="mb-3">
                    <Accordion.Item eventKey="0" className="border-0">
                        <Accordion.Header>
                            <div className="d-flex flex-column">
                                <p className="m-0 p-0 remotive-font-md">Optional</p>
                                <p className="m-0 p-0 remotive-font-xs text-secondary">
                                    Specific RemotiveBroker and RemotiveBrokerApp tags from RemotiveLabs
                                </p>
                            </div>
                        </Accordion.Header>
                        <Accordion.Body className="text-start p-1">
                            <Form validated={brokerTagValid && webappTagValid}>
                                <Form.Group className={'p-0'}>
                                    <Form.Label className={'remotive-font-sm m-0'}>RemotiveBroker tag</Form.Label>
                                    <Form.Control
                                        size={'sm'}
                                        type="text"
                                        isInvalid={!brokerTagValid}
                                        onChange={onBrokerTagChanged}
                                    />
                                    <Form.Control.Feedback type="invalid">
                                        You need to enter a valid TAG
                                    </Form.Control.Feedback>
                                </Form.Group>
                                <Form.Group className={'p-0'}>
                                    <Form.Label className={'remotive-font-sm m-0'}>RemotiveBrokerApp tag</Form.Label>
                                    <Form.Control
                                        size={'sm'}
                                        type="text"
                                        isInvalid={!webappTagValid}
                                        onChange={onWebappTagChanged}
                                    />
                                    <Form.Control.Feedback type="invalid">
                                        You need to enter a valid TAG
                                    </Form.Control.Feedback>
                                </Form.Group>
                            </Form>
                        </Accordion.Body>
                    </Accordion.Item>
                </Accordion>
            )
        }

        return (
            <Modal show={props.show} onHide={props.handleCloseFunction}>
                <Modal.Header closeVariant="white" className="remotive-primary-70-background" closeButton>
                    <Modal.Title className="lexend-regular text-light">Software update</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <p>
                        Click <b>Update</b> to install the latest version of the RemotiveBroker and RemotiveBrokerApp
                        software.
                    </p>
                    {optionalItems()}
                    <div className='mt-5 text-end'>
                        <button
                            className="btn remotive-btn remotive-btn-primary me-2"
                            onClick={() => setShowModal(false)}
                        >
                            Cancel
                        </button>
                        <button
                            className="btn remotive-btn remotive-btn-success"
                            disabled={!(brokerTagValid && webappTagValid)}
                            onClick={() => doUpgrade(brokerTag, webappTag)}
                        >
                            Update
                        </button>
                    </div>
                </Modal.Body>
            </Modal>
        )
    }

    const updateButton = () => {
        if (hasLaterServerVersion() || hasLaterClientVersion()) {
            return (
                <button className="btn remotive-btn remotive-btn-primary" onClick={() => setShowModal(true)}>
                    Update
                </button>
            )
        }
        return <></>
    }

    const brokerVersionInfo = () => {
        if (hasLaterServerVersion()) {
            return (
                <>
                    <p>There is a new version of the broker available, {getLatestBrokerVersion()}</p>
                </>
            )
        }
        return (
            <>
                <p>You are using the latest version of the broker</p>
            </>
        )
    }

    const brokerAppVersionInfo = () => {
        if (hasLaterClientVersion()) {
            return (
                <>
                    <p>There is a new version of the broker app available, {getLatestClientVersion()}</p>
                </>
            )
        }
        if (validate(currentClientVersion)) {
            return (
                <>
                    <p>You are using the latest version of the broker app</p>
                </>
            )
        }
        return (
            <>
                <p>Unknown version, running application locally?</p>
            </>
        )
    }

    const installOtherVersionButton = () => {
        if (!hasLaterServerVersion() && !hasLaterClientVersion()) {
            return (
                <button className="btn remotive-btn remotive-btn-primary" onClick={() => setShowModal(true)}>
                    Install another version
                </button>
            )
        }
        return <></>
    }

    const containerContent = () => {
        return (
            <>
                {!props.connectionState.clientIsConnectedToBroker && (
                    <Container className="row col-12">
                        <h3>RemotiveBroker Update</h3>
                        <div>
                            <p>
                                No connection to broker, if you have requested a software update please wait for the
                                update to complete and this page will be automatically updated when the broker comes
                                back up
                            </p>
                        </div>
                    </Container>
                )}

                {props.connectionState.clientIsConnectedToBroker && props.isCloudBroker && (
                    <Container className="row  col-12 mt-2">
                        <h3>RemotiveBroker {currentBrokerVersion}</h3>
                        {brokerVersionInfo()}
                    </Container>
                )}

                {props.connectionState.clientIsConnectedToBroker && !props.isCloudBroker && (
                    <Container className="mb-3">
                        <div>
                            <h5 className="lexend-bold">RemotiveBroker {currentBrokerVersion}</h5>
                            {brokerVersionInfo()}
                        </div>
                        <div>
                            <h5 className="lexend-bold">RemotiveBroker App {currentClientVersion}</h5>
                            {brokerAppVersionInfo()}
                        </div>
                        {props.connectionState.brokerHasEthernet && (
                            <div>
                                {updateButton()}
                                {installOtherVersionButton()}
                            </div>
                        )}
                        {!props.connectionState.brokerHasEthernet && (
                            <div>
                                <p>Installing new versions not possible since broker is not connected to ethernet</p>
                            </div>
                        )}
                    </Container>
                )}
                <UpdateModal show={showModal} handleCloseFunction={() => setShowModal(false)} handleInstall={upgrade} />
            </>
        )
    }

    return <>{containerContent()}</>
}
