import BrokerApi from '../../api/BrokerApi'
import axios, { AxiosInstance } from 'axios'
import { BrokerDetails } from '../../api/BrokerApi/types'
import { getBrokerDetails, getBrokerUrl } from '../../utils/broker'
import { BrokerDelegate } from './BrokerDelegate'
import { ConfigurationDelegate } from './ConfigurationDelegate'
import { FramesDistributionDelegate } from './FrameDistributionDelegate'
import { LicenseDelegate } from './LicenseDelegate'
import { NamespaceDelegate } from './NamespaceDelegate'
import { PlaybackDelegate } from './PlaybackDelegate'
import { RecordingDelegate } from './RecordingDelegate'
import { SignalDelegate } from './SignalDelegate'

const onPlaybackStreamClosedCallbacks: Array<Function> = []
const onSignalStreamClosedCallbacks: Array<Function> = []

const invokeOnPlaybackStreamClosed = () => {
    onPlaybackStreamClosedCallbacks.forEach((callback) => callback())
}

const invokeOnSignalStreamClosed = () => {
    onSignalStreamClosedCallbacks.forEach((callback) => callback())
}

const brokerHttpClient: AxiosInstance = axios.create({
    baseURL: getBrokerUrl(),
    timeout: 4000,
})

const brokerGrpcClient = new BrokerApi(
    getBrokerDetails() as BrokerDetails,
    () => invokeOnPlaybackStreamClosed(),
    () => invokeOnSignalStreamClosed()
)

export const { subscribeToFrameDistribution } = new FramesDistributionDelegate(brokerGrpcClient)
export const {
    listSignals,
    dropIncomingSignals,
    addPendingSubscription,
    applyPendingSubscriptions,
    removeSubscription,
    listSubscriptions,
} = new SignalDelegate(brokerGrpcClient)
export const { getLicenseInfo, applyNewLicense } = new LicenseDelegate(brokerGrpcClient)
export const { subscribeToRecordingState, recordSession } = new RecordingDelegate(brokerGrpcClient)
export const { reloadConfiguration, getConfiguration, getInterfacesJsonAsString } = new ConfigurationDelegate(
    brokerGrpcClient
)
export const { batchDeleteFiles, getAvailableFiles, subscribeToPlaybackState, playFiles, uploadFiles } =
    new PlaybackDelegate(brokerGrpcClient)
export const { getPlayableNamespaces, getNamespaces } = new NamespaceDelegate(brokerGrpcClient)
export const { isReachable, getCloudContext, fetchTimeout, upgrade, getBrokerVersion } = new BrokerDelegate(
    brokerGrpcClient,
    brokerHttpClient
)

export const registerCallback = (onPlaybackStreamClosed: () => void, onSignalStreamClosed: () => void) => {
    onPlaybackStreamClosedCallbacks.push(onPlaybackStreamClosed)
    onSignalStreamClosedCallbacks.push(onSignalStreamClosed)
}
