import { useEffect, useState, ChangeEvent, useRef } from 'react'
import { Spinner } from 'react-bootstrap'
import { uploadFiles } from '../../services/BrokerService'
import { isIframe } from '../../utils/CloudDetails'

interface UploadButtonProps {
    size?: 'sm' | 'lg'
    uploadDoneFunction?: (() => void) | undefined
}

function UploadButton(props: UploadButtonProps) {
    const inputRef = useRef<HTMLInputElement | null>(null)
    const [filesToUpload, setFilesToUpload] = useState<Array<File>>()
    const [isUploading, setIsUploading] = useState<boolean>(false)

    useEffect(() => {
        if (filesToUpload !== undefined) {
            setIsUploading(true)
            uploadFiles(filesToUpload, (bytes: number, percent: number) =>
                uploadProgressCallback(bytes, percent, filesToUpload)
            )
                .then(() => {
                    setFilesToUpload(undefined)
                    setIsUploading(false)
                    if (props.uploadDoneFunction) {
                        props.uploadDoneFunction()
                    }
                    alert(`Successfully uploaded ${filesToUpload.map((file) => file.name)}`)
                })
                .catch((err: any) => {
                    console.log(err)
                    setFilesToUpload(undefined)
                    setIsUploading(false)
                    alert(`Failed to upload files, please try again`)
                })

            setFilesToUpload(undefined)
        }
    }, [filesToUpload])

    const uploadProgressCallback = (bytes: number, percent: number, filesToUpload: Array<File>) => {
        // TODO - we need to add some kind of progress monitor to upload
    }

    const handleUploadClick = () => {
        inputRef.current?.click()
    }

    const handleFileUploadChange = (e: ChangeEvent<HTMLInputElement>) => {
        if (!e.target.files) {
            return
        }
        const files = []
        // Ugly for-loop, but there is nothing better available on the FileList object
        for (let fileIndex = 0; fileIndex < e.target.files.length; fileIndex++) {
            const file = e.target.files.item(fileIndex)
            if (file !== null) {
                files.push(file)
            }
        }
        setFilesToUpload(files)
    }

    const getButton = () => {
        const buttonText = isUploading ? (
            <Spinner as="span" size="sm" animation="border" role="status" />
        ) : props.size === 'lg' ? (
            'Upload recording'
        ) : (
            'Upload'
        )

        return (
            <>
                <button
                    disabled={isIframe()}
                    className={`btn ${
                        props.size === 'lg' ? 'remotive-btn-md' : 'remotive-btn-sm'
                    } remotive-btn-secondary`}
                    onClick={handleUploadClick}
                >
                    {buttonText}
                </button>
            </>
        )
    }

    return (
        <>
            {getButton()}
            {/* Hidden input used for uploading files */}
            <input type="file" ref={inputRef} onChange={handleFileUploadChange} style={{ display: 'none' }} />
        </>
    )
}

export default UploadButton
