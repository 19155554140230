import { useEffect, useRef, useState } from 'react'
import { Card, Dropdown, Form, InputGroup } from 'react-bootstrap'
import Close from '@mui/icons-material/CloseRounded'

import FrameDistributionChart from './FrameDistributionChart'
import { FrameDistribution, FrameIdFrequency } from '../../../types/FrameDistribution'
import { randomString } from '../../../utils/Random'
import { getPlayableNamespaces, subscribeToFrameDistribution } from '../../../services/BrokerService'
import { Panel } from '../../../types/Panel'
import { Frame, PlaybackState } from '../../../api/BrokerApi/types'
import { CountByFrameId } from 'remotivelabs-grpc-web-stubs'
import useInterval from '../../../hooks/useInterval'

interface FrameDistributionCardProps {
    allFrames: Array<Frame>
    isCloudBroker: boolean
    playbackState: PlaybackState | undefined
    pauseLiveState: boolean
    savePanelFunction: (panel: Panel) => void
    removePanelFunction: () => void
    panelKey: string
}

const CARD_HEIGHT = 400
const HISTOGRAM_GRAPH_HEIGHT = 100
const BIN_DISTRIBUTION_HEIGHT = CARD_HEIGHT - HISTOGRAM_GRAPH_HEIGHT - 132
const GRAPH_WIDTH_MS = 10_000

const MESSAGE_INTERVAL = 1 // seconds
const FRAME_DISTRIBUTIONS_ARRAY_MAX_SIZE = 10
const FRAME_DISTRIBUTION_DETAIL_LIST_SIZE = FRAME_DISTRIBUTIONS_ARRAY_MAX_SIZE - 2 // Can't be larger then FRAME_DISTRIBUTIONS_ARRAY_MAX_SIZE
const FRAME_DISTRIBUTION_DETAIL_BAR_HEIGHT = 12

const BIN_DISTRIBUTION_BLACKLIST = ['flexray']

export default function FrameDistributionCard(props: FrameDistributionCardProps) {
    const [namespaceNames, setNamespacesNames] = useState<Array<string>>([])
    const [selectedNamespace, setSelectedNamespace] = useState<string | undefined>(undefined)
    const [filter, setFilter] = useState<string>()
    const [frameDistributionsToPlot, setFrameDistributionsToPlot] = useState<Array<FrameDistribution>>([])
    const bufferedFrameDistributions = useRef<Array<FrameDistribution>>([])
    const [frameRate, setFrameRate] = useState<number>(1)

    const [isLatestBinDistributionEnabled, setIsLatestBinDistributionEnabled] = useState<boolean>(false)
    const [shouldPauseStreaming, setShouldPauseStreaming] = useState<boolean>(false)
    const shouldPauseStreamingRef = useRef<boolean>(false)
    const frameIdToNameMapping = useRef<Map<number, string>>()

    useEffect(() => {
        console.debug('Mounting frame frequency distirbution container!')
        const setNamespaces = async () => {
            const namespaces = await getPlayableNamespaces()
            setNamespacesNames(namespaces.map((it) => it.name))
            setIsLatestBinDistributionEnabled(!namespaces.some((it) => BIN_DISTRIBUTION_BLACKLIST.includes(it.type)))
        }

        setNamespaces()

        return () => {
            console.debug('Unmounting frame frequency distirbution container!')
        }
    }, [])

    useEffect(() => {
        const newFrameIdToNameMapping = new Map<number, string>()
        props.allFrames.forEach((frame) => newFrameIdToNameMapping.set(frame?.metadata?.getFrameid() ?? 0, frame.name))
        frameIdToNameMapping.current = newFrameIdToNameMapping
    }, [props.allFrames])

    useEffect(() => {
        if (namespaceNames.length > 0) {
            // Select first namespace in list on load
            setSelectedNamespace(namespaceNames[0])
        }
    }, [namespaceNames])

    useEffect(() => {
        if (selectedNamespace !== undefined) {
            subscribeToFrameDistribution(selectedNamespace, onDataCallback, onEndCallback)
        }
    }, [selectedNamespace])

    useEffect(() => {
        console.debug(filter)
    }, [filter])

    useEffect(() => {
        const isPaused = (props.playbackState && !props.playbackState?.isPlaying()) || props.pauseLiveState
        setShouldPauseStreaming(isPaused)
        shouldPauseStreamingRef.current = isPaused
    }, [props.playbackState, props.pauseLiveState])

    const updateChart = () => {
        if (!shouldPauseStreaming) {
            updateGraphValues()
        }
    }

    const updateGraphValues = () => {
        const latestValue = bufferedFrameDistributions.current[bufferedFrameDistributions.current.length - 1]
        if (latestValue !== undefined) {
            const cutOffTimestamp = latestValue.receivedTimestamp - GRAPH_WIDTH_MS
            setFrameDistributionsToPlot(
                frameDistributionsToPlot.concat(bufferedFrameDistributions.current).filter((it) => {
                    return it.receivedTimestamp > cutOffTimestamp
                })
            )
            bufferedFrameDistributions.current = []
        }
    }

    useInterval(updateChart, 1000 / frameRate)

    const onDataCallback = (data: Array<CountByFrameId>, receivedTimestampMs: number) => {
        const newFrameDist = {
            frameIdFrequency: data.map(
                (it) => ({ frameId: it.getFrameid(), frequency: `${it.getCount()}` } as FrameIdFrequency)
            ),
            receivedTimestamp: receivedTimestampMs,
        } as FrameDistribution
        bufferedFrameDistributions.current.push(newFrameDist)
    }

    const onEndCallback = () => {
        console.error(`Something went wrong with the frame frequency distribution subscription`)
    }

    const setFilterOnChange = (event: any) => {
        const searchKey = event.target.value.toLowerCase()
        if (searchKey.length >= 1) {
            setFilter(searchKey)
        }
        if (searchKey.length === 0) {
            setFilter(undefined)
        }
    }

    const frameIdFrequencyToLabel = (frameIdFrequency: FrameIdFrequency) => {
        const frameName = frameIdToNameMapping.current?.get(frameIdFrequency.frameId)
        return frameName !== undefined ? `${frameName} - ${frameIdFrequency.frameId}` : `${frameIdFrequency.frameId}`
    }

    const hitRatioVisualization = () => {
        const latestFrameDistribution = frameDistributionsToPlot[frameDistributionsToPlot.length - 1]

        if (!isLatestBinDistributionEnabled || !latestFrameDistribution) {
            return <></>
        }

        const hits = latestFrameDistribution.frameIdFrequency.filter(
            (it) => frameIdFrequencyToLabel(it) !== `${it.frameId}`
        )
        const hitRatePercent = (hits.length / latestFrameDistribution.frameIdFrequency.length) * 100
        const roundedHitRatePercent = Math.round(hitRatePercent * 100) / 100 || 0
        const hitRateColor =
            roundedHitRatePercent < 10
                ? 'text-danger'
                : roundedHitRatePercent < 33
                ? 'text-warning'
                : roundedHitRatePercent < 75
                ? 'remotive-primary-70-color'
                : 'remotive-success-70-color'
        return (
            <div className="d-flex flex-column">
                <p className="remotive-font-sm p-0 m-0 remotive-primary-70-color text-start">
                    Signal database hit rate: <span className={`${hitRateColor}`}>{`${roundedHitRatePercent}%`}</span>
                </p>
                <p className="remotive-font-xs p-0 m-0 text-secondary">
                    The amount of received frames present in the signal database file
                </p>
            </div>
        )
    }

    const frequencyDistributionList = () => {
        const latestFrameDistribution = frameDistributionsToPlot[frameDistributionsToPlot.length - 1]

        if (!isLatestBinDistributionEnabled) {
            return (
                <>
                    <p className="h-100 remotive-font-sm d-flex justify-content-center align-items-center">{`This visualization is disabled when using ${BIN_DISTRIBUTION_BLACKLIST.map(
                        (it) => ` ${it}`
                    )}.`}</p>
                </>
            )
        }

        let filteredLatestFrameDistribution = latestFrameDistribution
        if (filter !== undefined) {
            filteredLatestFrameDistribution = {
                receivedTimestamp: latestFrameDistribution.receivedTimestamp,
                frameIdFrequency: latestFrameDistribution.frameIdFrequency.filter((it) =>
                    frameIdFrequencyToLabel(it).toLowerCase().includes(filter.toLowerCase())
                ),
            }
        }

        if (filteredLatestFrameDistribution?.frameIdFrequency?.length > 0) {
            const total = latestFrameDistribution.frameIdFrequency
                .map((it) => Number(it.frequency))
                .reduce((accumulator, currentValue) => accumulator + currentValue)

            return (
                <div className="d-flex flex-wrap">
                    {filteredLatestFrameDistribution.frameIdFrequency
                        .sort((a, b) => Number(b.frequency) - Number(a.frequency) || a.frameId - b.frameId)
                        .slice(0, FRAME_DISTRIBUTION_DETAIL_LIST_SIZE)
                        .map((it, index) => {
                            return (
                                <div key={it.frameId} className="col-6">
                                    <div
                                        style={{ marginLeft: 2, marginRight: 2 }}
                                        className="rounded mb-1 remotive-primary-10-background p-1 d-flex flex-column"
                                    >
                                        <div className="d-flex flex-row m-0 align-items-center justify-content-between">
                                            <p className="remotive-primary-70-color remotive-font-sm ps-1 m-0 text-truncate">
                                                {frameIdFrequencyToLabel(it)}
                                            </p>
                                            <p className="remotive-primary-70-color remotive-font-sm ps-1 pe-1 m-0">{`${it.frequency}`}</p>
                                        </div>
                                        <div className="w-100">
                                            <div
                                                style={{
                                                    height: FRAME_DISTRIBUTION_DETAIL_BAR_HEIGHT,
                                                    marginBottom: `-${FRAME_DISTRIBUTION_DETAIL_BAR_HEIGHT}px`,
                                                }}
                                                className="rounded remotive-primary-40-background ms-1 mt-0"
                                            />
                                            <div
                                                style={{
                                                    width: `${(Number(it.frequency) / total) * 100}%`,
                                                    height: FRAME_DISTRIBUTION_DETAIL_BAR_HEIGHT,
                                                    marginBottom: `-${FRAME_DISTRIBUTION_DETAIL_BAR_HEIGHT}px`,
                                                }}
                                                className="rounded remotive-primary-60-background ms-1 mt-0"
                                            />
                                            <p className="remotive-font-xxs text-light ps-1 pe-1 m-0">{`${it.frequency}/${total}`}</p>
                                        </div>
                                    </div>
                                </div>
                            )
                        })}
                </div>
            )
        }

        return (
            <div className="w-100 h-100 d-flex align-items-center justify-content-center">
                <p className="m-0 remotive-font-sm ps-1">No frames received</p>
            </div>
        )
    }

    const filteringSearchbar = () => {
        return (
            <>
                <InputGroup>
                    <Form.Control size="sm" placeholder="Filter frames..." onChange={setFilterOnChange} />
                </InputGroup>
            </>
        )
    }

    const namespaceDropdown = () => {
        return (
            <Dropdown className="remotive-font-md text-end">
                <Dropdown.Toggle
                    style={{ width: 110 }}
                    className="remotive-font-sm bg-transparent text-dark border-0 text-truncate px-2 text-start"
                >
                    {selectedNamespace || 'Loading...'}
                </Dropdown.Toggle>
                <Dropdown.Menu className="remotive-font-sm pb-1 pt-1 remotive-dropdown-dark">
                    {namespaceNames.map((name) => {
                        return (
                            <Dropdown.Item key={name} onClick={() => setSelectedNamespace(name)}>
                                {name}
                            </Dropdown.Item>
                        )
                    })}
                </Dropdown.Menu>
            </Dropdown>
        )
    }

    const streamingFrameDistributionGraph = () => {
        return (
            <div style={{ height: HISTOGRAM_GRAPH_HEIGHT }} className="w-100">
                <FrameDistributionChart
                    GRAPH_WIDTH_MS={GRAPH_WIDTH_MS}
                    shouldPauseStreaming={shouldPauseStreaming}
                    data={frameDistributionsToPlot}
                    onRefreshFunction={() => {}}
                    chartKey={props.panelKey}
                />
            </div>
        )
    }

    return (
        <>
            <Card className="shadow rounded p-2 m-1 mx-0 border-0" style={{ height: 400 }}>
                <div className="d-flex flex-column justify-content-center align-items-center">
                    {props.isCloudBroker}
                </div>
                <div className="d-flex flex-column">
                    <div className="col-12">
                        <div className="d-flex justify-content-between">
                            <div className="col-7 text-truncate">
                                <p className="w-100 text-start m-0 remotive-font-md ms-1 text-truncate">
                                    Frame histogram
                                </p>
                                <p className="w-100 text-start m-0 remotive-font-xs ms-1 text-secondary text-truncate">
                                    {`Each bar is the total number of frames per ${MESSAGE_INTERVAL}s bin`}
                                </p>
                            </div>
                            <div className="col-3">{namespaceDropdown()}</div>
                            <div className="col-2 text-end">
                                <button
                                    onClick={() => props.removePanelFunction()}
                                    className="btn remotive-btn-no-bg px-1"
                                >
                                    <div className="d-flex align-items-center">
                                        <Close sx={{ fontSize: 24 }} />
                                    </div>
                                </button>
                            </div>
                        </div>
                        {streamingFrameDistributionGraph()}
                    </div>

                    <div className="col-12 d-flex flex-column mt-1">
                        <div className="d-flex flex-row mb-1 w-100">
                            <div className="d-none d-md-block col-6 text-start">
                                <p className="w-100 text-start m-0 remotive-font-md ms-1">Latest bin distribution</p>
                                <p className="w-100 text-start m-0 remotive-font-xs ms-1 text-secondary">
                                    Most frequent frames, ordered by count
                                </p>
                            </div>
                            <div className="col-12 col-lg-6 col-md-6">{filteringSearchbar()}</div>
                        </div>

                        <div style={{ height: BIN_DISTRIBUTION_HEIGHT }}>{frequencyDistributionList()}</div>
                        <div className="d-flex">{hitRatioVisualization()}</div>
                    </div>
                </div>
            </Card>
        </>
    )
}
