import { useEffect, useState } from 'react'
import { Modal, Spinner } from 'react-bootstrap'

interface OverlayModalProps {
    show: boolean
    text?: string
}

export default function OverlayModal(props: OverlayModalProps) {
    useEffect(() => {
        console.debug('Mounted overlay modal!')

        return () => {
            console.debug('Unmounted overlay modal')
        }
    }, [])

    return (
        <>
            <Modal
                className="d-flex justify-content-center align-items-center"
                contentClassName="bg-transparent border-0"
                animation={false}
                backdropClassName="opacity-75 remotive-primary-90-background"
                show={props.show}
                backdrop="static"
            >
                <div className="text-center lexend-regular">
                    <p className="fs-5 m-3 text-light">{props.text === undefined ? 'Loading...' : props.text}</p>
                    <Spinner variant="white" />
                </div>
            </Modal>
        </>
    )
}
