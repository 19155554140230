import { License, LicenseInfo } from 'remotivelabs-grpc-web-stubs'
import base64js from 'base64-js'
import { Buffer } from 'buffer'
import { compareVersions } from 'compare-versions'
import { getConfiguration } from '../BrokerService'

/**
 * API using our LicenseServer backend
 */

// I think that our remotivelabs.com/license is not CORS enabled so keeping this for now
export const TERMSURL_REMOTIVE = 'https://www.beamylabs.com/license/'

const licenseServerBaseUrl = process.env.REACT_APP_LICENSE_SERVER_URL || 'https://license.cloud.remotivelabs.com'

export type FetchLicenseResponse = {
    message: string
    license_data: string
}

export function extractMachineIdAsJson(license: LicenseInfo) {
    return JSON.parse(new TextDecoder().decode(Buffer.from(license.getRequestmachineid())))
}

export async function hasInternet() {
    const controller = new AbortController()
    const timeoutId = setTimeout(() => controller.abort(), 5000)

    try {
        await fetch(TERMSURL_REMOTIVE, {
            signal: controller.signal,
            // The url is cached so always fetch fresh otherwise not possible to discover network issues
            cache: 'no-store',
        })
        clearTimeout(timeoutId)
        return true
    } catch (err) {
        clearTimeout(timeoutId)
        return false
    }
}

export async function requestLicense(email: string, invalidLicenseInfo: LicenseInfo): Promise<Response> {
    const newLicense = {
        id: email.trim(),
        machine_id: extractMachineIdAsJson(invalidLicenseInfo),
    }

    const licensejsonb64 = base64js.fromByteArray(new TextEncoder().encode(JSON.stringify(newLicense)))

    const response = await fetch(`${licenseServerBaseUrl}/requestlicense/`, {
        method: 'POST',
        headers: { 'content-type': 'application/json' },
        body: JSON.stringify({ licensejsonb64 }),
    })

    const json = await response.json()
    if (json.error) {
        console.log(json.error.message)
        throw new Error(json.error.message)
    }
    return response
}

export async function fetchLicense(email: string, hash: string): Promise<License> {
    console.log(`${email} ${hash}`)

    const response = await fetch(`${licenseServerBaseUrl}/fetchlicense/`, {
        method: 'POST',
        headers: { 'content-type': 'application/json' },
        body: JSON.stringify({ id: email, hash }),
    })

    const json = await response.json()

    if (json.error) {
        throw new Error(json.error.message)
    }

    const result: FetchLicenseResponse = json as FetchLicenseResponse
    // TODO - Investigate possible outcomes here
    if (result.message === 'OK') {
        const licenseData = new TextEncoder().encode(result.license_data)
        const license = new License()
        license.setData(licenseData)
        license.setTermsagreement(true)
        return license
    } else {
        console.log(result.message)
        throw new Error(JSON.stringify(result))
    }
}

export async function getServerVersions(): Promise<Array<string>> {
    return getVersions('server')
}

export async function getClientVersions(): Promise<Array<string>> {
    return getVersions('client')
}

export async function getVersions(type: 'server' | 'client'): Promise<Array<string>> {
    var list = []

    const resp = await fetch(`${licenseServerBaseUrl}/versions`, {
        method: 'POST',
        headers: { 'content-type': 'application/json' },
        body: `{"component":"${type}"}`,
    })
    if (!resp.ok) {
        const text = await resp.text()
        throw new Error(text)
        //return { error: `http ${resp.status}: ${text}` }
    }
    const data = await resp.json()

    list = data.versions.sort(compareVersions)

    if (list.length < 1) {
        console.log('No versions found')
    }
    return list
}

/**
 * Returns a list of the brokers public IPs, which is also the IPs of the web-client
 * If this is an empty list its not connected to any router and has no internet access.
 */
export async function getBrokerPublicIpList(): Promise<Array<string>> {
    const config = await getConfiguration()
    const brokerIpAddresses = JSON.parse(config.getPublicaddress())
    let urls = []
    for (const [iface, addrs] of Object.entries(brokerIpAddresses)) {
        // TODO? expecting to not have to do filtering here
        if (iface.match(/^(docker[0-9]+|br-|bridge|wlan[0-9]?|wlp[0-9])/)) {
            continue
        }
        //@ts-ignore
        for (const addr of addrs) {
            if (addr.match(/^(127\.|169\.254)/)) {
                continue
            }
            const port = window.location.port ? `:${window.location.port}` : ``
            urls.push(`${window.location.protocol}//${addr}${port}`)
        }
    }
    return urls
}

export type ConnectionState = {
    clientIsConnectedToBroker: boolean
    clientHasInternet: boolean
    brokerHasEthernet: boolean
    brokerPublicUrls: Array<string> | undefined
}

export type CloudContext = {
    project: string
    organisation?: string
    recordingSession: string
    brokerConfigName: string | undefined
    permissions: Array<string>
}
