import BrokerApi from '../../api/BrokerApi'
import { CreateRecordingSession, RecordingState } from '../../api/BrokerApi/types'

export class RecordingDelegate {
    private _brokerGrpcClient: BrokerApi

    constructor(brokerGrpcClient: BrokerApi) {
        this._brokerGrpcClient = brokerGrpcClient
    }

    subscribeToRecordingState = async (callback: (playing: RecordingState) => void) => {
        console.log('subscribe to recording mode')
        this._brokerGrpcClient.listenOnRecordings((playing: RecordingState) => {
            callback(playing)
        })
    }

    recordSession = (recordingSession: CreateRecordingSession) => {
        console.log(`Recording ${recordingSession.recordings.map((recording) => recording.filename)}!`)
        return this._brokerGrpcClient.record(recordingSession)
    }
}
