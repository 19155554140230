import { useState, useEffect } from 'react'
import { Modal, InputGroup, Form } from 'react-bootstrap'
import { toast } from 'react-toastify'
import { Signal } from '../../../types/Filters'
import { LatLonType } from '../../../types/LatLonType'
import { isIframe } from '../../../utils/CloudDetails'

interface SetLatLonModalProps {
    show: boolean
    type: LatLonType
    allSignals: Array<Signal>
    handleCloseFunction: () => void
    setLatLonSignalFunction: (type: LatLonType, signal: Signal) => void
}

export default function SetLatLonModal(props: SetLatLonModalProps) {
    const [selectedSignal, setSelectedSignal] = useState<Signal>()
    const [filteredSignals, setFilteredSignals] = useState<Array<Signal>>(props.allSignals)

    useEffect(() => {
        console.log('Mounting set lat lon modal!')
    }, [])

    const searchOnChange = (event: any) => {
        const searchKey = new String(event.target.value).toLowerCase()
        if (searchKey.length >= 3) {
            console.log(searchKey)
            //console.log(signal.name.toLowerCase())
            const filteredSignals: Array<Signal> = props.allSignals
                .map((signal) => {
                    if (signal.name.toLowerCase().includes(searchKey)) {
                        return signal
                    }
                    // No match
                    return undefined
                })
                .filter((signal) => signal !== undefined) as Array<Signal>
            setFilteredSignals(filteredSignals)
        }
        if (searchKey.length === 0) {
            setFilteredSignals(props.allSignals)
        }
    }

    const selectSignalOnChange = (event: any) => {
        const targetValue = event.target.value
        const tagetedSignal: Signal = JSON.parse(targetValue)
        if (event.target.checked) {
            setSelectedSignal(tagetedSignal)
        } else {
            setSelectedSignal(undefined)
        }
    }

    const createFilteredSignal = (signal: Signal) => {
        const isChecked = selectedSignal?.name === signal.name && selectedSignal.namespace === signal.namespace

        return (
            <div className="row text-break align-middle p-1 m-0">
                <div className="col-6">
                    <Form.Check
                        type={'checkbox'}
                        label={
                            <>
                                <b>{signal.name}</b>
                            </>
                        }
                        value={JSON.stringify(signal)}
                        checked={isChecked}
                        onChange={selectSignalOnChange}
                    />
                </div>
                <div className="col-6 remotive-small">
                    <p className="remotive-primary-40-color ps-1 m-0">{`${signal.namespace}`}</p>
                </div>
            </div>
        )
    }

    const filteredSignalList = () => {
        return filteredSignals.map((signal) => {
            return (
                <div
                    key={`${signal.name}::${signal.namespace}`}
                    className="rounded ms-3 mt-1 remotive-primary-10-background"
                >
                    {createFilteredSignal(signal)}
                </div>
            )
        })
    }

    const setSignalAndClose = () => {
        if (selectedSignal !== undefined) {
            props.setLatLonSignalFunction(props.type, selectedSignal)
        } else {
            // Shouldn't happen as the button will be disabled
            toast.warn(`No signal was selected as source for ${props.type}`)
        }
        closeModal()
    }

    const closeModal = () => {
        props.handleCloseFunction()
        setSelectedSignal(undefined)
        setFilteredSignals(props.allSignals)
    }

    return (
        <>
            <Modal size="lg" className="col-12" show={props.show} onHide={closeModal}>
                <Modal.Header closeVariant="white" className="remotive-primary-70-background" closeButton>
                    <Modal.Title className="lexend-regular text-light">Set {props.type} signal</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <InputGroup size="sm" className="mb-2">
                        <Form.Control
                            placeholder="Signal/frame name..."
                            aria-label="Small"
                            aria-describedby="inputGroup-sizing-sm"
                            onChange={searchOnChange}
                        />
                    </InputGroup>
                    <Form>
                        <div>
                            <div className="row text-break text-start ms-2">
                                <div className="col-6 px-0 lexend-bold d-none d-md-block">Signal</div>
                                <div className="col-6 px-0 lexend-bold d-none d-md-block">Namespace</div>
                                <div className="col-6 px-0 lexend-bold remotive-small d-block d-md-none">Signal</div>
                                <div className="col-6 px-0 lexend-bold remotive-small d-block d-md-none">Namespace</div>
                            </div>
                            <div style={{ overflowY: 'scroll', height: isIframe() ? '40vh' : '60vh', maxHeight: 400 }}>
                                {filteredSignalList()}
                            </div>
                        </div>
                    </Form>
                </Modal.Body>
                <Modal.Footer className="border-0">
                    <button className="btn remotive-btn remotive-btn-primary" onClick={closeModal}>
                        Cancel
                    </button>
                    <button
                        disabled={selectedSignal === undefined}
                        className="btn remotive-btn remotive-btn-success"
                        onClick={setSignalAndClose}
                    >
                        Set {props.type}
                    </button>
                </Modal.Footer>
            </Modal>
        </>
    )
}
