import { Form, Modal, Spinner } from 'react-bootstrap'
import { ConnectionState } from '../../../services/LicenseService'
import { useState } from 'react'
import Stepper from '../../Stepper'
import { CheckBoxRounded, CheckCircle, CheckCircleRounded, CheckRounded } from '@mui/icons-material'
import { toast } from 'react-toastify'
import { formattedToastMessage } from '../../../utils/toast'

interface DisconnectedBrokerFlowProps {
    connectionState: ConnectionState
    handleCloseFunction: () => void
    goToApplyLicenseFunction: (specifiedEmail: string) => void
    specifiedEmail: string
    sendLicenseEmailAndUpdateComponentState: (
        setCurrentStepFunction: () => void,
        setIsSendingEmailFunction: () => void
    ) => void
    setSpecifiedEmail: (e: any) => void
}

export default function DisconnectedBrokerFlow(props: DisconnectedBrokerFlowProps) {
    const [currentStep, setCurrentStep] = useState<number>(1)
    const [isSendingEmail, setIsSendingEmail] = useState<boolean>(false)

    const stepOneContent = () => {
        const emailValid = props.specifiedEmail.includes('@') && props.specifiedEmail.includes('.')
        return (
            <>
                <div className="d-flex align-items-center flex-column">
                    <Stepper currentStep={1} maxSteps={5} />
                    <div className="mt-5 mb-5 px-5">
                        <div className="mb-3 text-start">
                            <p className="m-0 remotive-font">Input your email address to receive a license</p>
                            <p className="m-0 remotive-font-sm text-secondary">
                                If you already have a license for this hardware, we will re-send your previous license.
                            </p>
                        </div>
                        <Form.Group>
                            <Form.Label className={'remotive-font-xs m-0'}>Email</Form.Label>
                            <Form.Control
                                size={'sm'}
                                type="email"
                                id="inputEmail"
                                placeholder="E.g johndoe@example.com"
                                value={props.specifiedEmail}
                                isInvalid={props.specifiedEmail !== '' && !emailValid}
                                onChange={(event: any) => props.setSpecifiedEmail(event.target.value)}
                            />
                            <Form.Control.Feedback type="invalid" className="remotive-font-sm">
                                You need to enter a valid email address
                            </Form.Control.Feedback>
                        </Form.Group>
                    </div>
                </div>
                <div className="mt-5 mb-3 d-flex justify-content-evenly flex-row">
                    <button
                        className="btn remotive-btn remotive-btn-primary"
                        onClick={() => props.handleCloseFunction()}
                    >
                        Close
                    </button>
                    <button
                        className="btn remotive-btn remotive-btn-success"
                        disabled={!emailValid}
                        onClick={() => setCurrentStep(2)}
                    >
                        Next
                    </button>
                </div>
            </>
        )
    }

    const stepTwoContent = () => {
        return (
            <>
                <div className="d-flex align-items-center flex-column">
                    <Stepper currentStep={2} maxSteps={5} />
                    <div className="mt-5 mb-5 px-5">
                        <div className="mb-5 text-start">
                            <p className="m-0 remotive-font lexend-bold">Change to a network with internet access</p>
                            <p className="m-0 remotive-font-sm text-secondary">
                                This application needs an{' '}
                                <span className="remotive-success-70-color lexend-bold">internet connection</span> to be
                                able to issue a license, please change network.
                            </p>
                        </div>
                        {!props.connectionState.clientHasInternet && (
                            <div className="mt-5 d-flex justify-content-center flex-column align-items-center">
                                <Spinner />
                                <p className="mt-4 remotive-font-md mb-0">
                                    Waiting for{' '}
                                    <span className="remotive-success-70-color lexend-bold">internet connection</span>
                                    ...
                                </p>
                                <p className="remotive-font-xs">
                                    it can take up to <span className="lexend-bold">10 seconds</span> to detect an{' '}
                                    <span className="remotive-success-70-color lexend-bold">internet connection</span>.
                                </p>
                            </div>
                        )}
                        {props.connectionState.clientHasInternet && (
                            <div className="mt-5 d-flex justify-content-center flex-column align-items-center">
                                <CheckCircleRounded sx={{ fontSize: 45 }} className="remotive-success-70-color" />
                                <p className="mt-4 remotive-font-md mb-0">
                                    Successfully connected to the{' '}
                                    <span className="remotive-success-70-color lexend-bold">internet</span>!
                                </p>
                                <p className="remotive-font-xs text-secondary">
                                    Disconnecting from your{' '}
                                    <span className="remotive-success-70-color lexend-bold">internet connection</span>{' '}
                                    will interrupt the licensing process.
                                </p>
                            </div>
                        )}
                    </div>
                </div>
                <div className="my-3 d-flex justify-content-evenly flex-row">
                    <button className="btn remotive-btn remotive-btn-primary" onClick={() => setCurrentStep(1)}>
                        Back
                    </button>
                    <button
                        className="btn remotive-btn remotive-btn-success"
                        disabled={isSendingEmail || !props.connectionState.clientHasInternet}
                        onClick={() => {
                            setIsSendingEmail(true)
                            props.sendLicenseEmailAndUpdateComponentState(
                                () => setCurrentStep(3),
                                () => setIsSendingEmail(false)
                            )
                        }}
                    >
                        {isSendingEmail ? <Spinner size="sm" /> : 'Next'}
                    </button>
                </div>
            </>
        )
    }

    const stepThreeContent = () => {
        return (
            <>
                <div className="d-flex align-items-center flex-column">
                    <Stepper currentStep={3} maxSteps={5} />
                    <div className="mt-5 mb-5 px-5">
                        <div className="mb-5 text-start">
                            <p className="m-0 remotive-font lexend-bold">
                                Check your inbox for an email from support@remotivelabs.com
                            </p>
                            <p className="m-0 remotive-font-md text-secondary">
                                The email contains both a license phrase and a license file.
                            </p>
                        </div>
                    </div>
                </div>
                <div className="mt-5 mb-3 d-flex justify-content-evenly flex-row">
                    <button className="btn remotive-btn remotive-btn-primary" onClick={() => setCurrentStep(2)}>
                        Back
                    </button>
                    <button
                        className="btn remotive-btn remotive-btn-success"
                        disabled={!props.connectionState.clientHasInternet}
                        onClick={() => setCurrentStep(4)}
                    >
                        I received it
                    </button>
                </div>
            </>
        )
    }

    const stepFourContent = () => {
        return (
            <>
                <div className="d-flex align-items-center flex-column">
                    <Stepper currentStep={4} maxSteps={5} />
                    <div className="mt-5 mb-5 px-5">
                        <div className="mb-5 text-start">
                            <p className="m-0 remotive-font lexend-bold">
                                Download the license file that is attached in the license email.
                            </p>
                            <p className="m-0 remotive-font-md text-secondary">
                                You <b>must</b> use the license file if this{' '}
                                <span className="remotive-primary-50-color lexend-bold">RemotiveBroker</span> will be
                                used without an{' '}
                                <span className="remotive-success-70-color lexend-bold">internet connection</span>.
                            </p>
                        </div>
                    </div>
                </div>
                <div className="mt-5 mb-3 d-flex justify-content-evenly flex-row">
                    <button className="btn remotive-btn remotive-btn-primary" onClick={() => setCurrentStep(3)}>
                        Back
                    </button>
                    <button
                        className="btn remotive-btn remotive-btn-success"
                        disabled={!props.connectionState.clientHasInternet}
                        onClick={() => setCurrentStep(5)}
                    >
                        I downloaded it
                    </button>
                </div>
            </>
        )
    }

    const stepFiveContent = () => {
        return (
            <>
                <div className="d-flex align-items-center flex-column">
                    <Stepper currentStep={5} maxSteps={5} />
                    <div className="mt-5 mb-5 px-5">
                        <div className="mb-5 text-start">
                            <p className="m-0 remotive-font lexend-bold">
                                Change network to the{' '}
                                <span className="remotive-primary-50-color lexend-bold">RemotiveBroker</span> access
                                point
                            </p>
                            <p className="m-0 remotive-font-sm text-secondary">
                                This application needs access to the{' '}
                                <span className="remotive-primary-50-color lexend-bold">RemotiveBroker</span> to be able
                                to apply the license, please change network to its' access point.
                            </p>
                        </div>
                        {!props.connectionState.clientIsConnectedToBroker && (
                            <div className="mt-5 d-flex justify-content-center flex-column align-items-center">
                                <Spinner />
                                <p className="mt-4 remotive-font-md mb-0">
                                    Waiting for{' '}
                                    <span className="remotive-primary-50-color lexend-bold">RemotiveBroker</span>{' '}
                                    connection...
                                </p>
                                <p className="remotive-font-xs">
                                    it can take up to <span className="lexend-bold">10 seconds</span> to detect a{' '}
                                    <span className="remotive-primary-50-color lexend-bold">RemotiveBroker</span>{' '}
                                    connection.
                                </p>
                            </div>
                        )}
                        {props.connectionState.clientIsConnectedToBroker && (
                            <div className="mt-5 d-flex justify-content-center flex-column align-items-center">
                                <CheckCircleRounded sx={{ fontSize: 45 }} className="remotive-primary-50-color" />
                                <p className="mt-4 remotive-font-md mb-0">
                                    Successfully connected to the{' '}
                                    <span className="remotive-primary-50-color lexend-bold">RemotiveBroker</span>!
                                </p>
                                <p className="remotive-font-xs text-secondary">
                                    Disconnecting from your{' '}
                                    <span className="remotive-primary-50-color lexend-bold">RemotiveBroker</span> will
                                    interrupt the licensing process.
                                </p>
                            </div>
                        )}
                    </div>
                </div>
                <div className="my-3 d-flex justify-content-evenly flex-row">
                    <button className="btn remotive-btn remotive-btn-primary" onClick={() => setCurrentStep(4)}>
                        Back
                    </button>
                    <button
                        className="btn remotive-btn remotive-btn-success"
                        disabled={!props.connectionState.clientIsConnectedToBroker}
                        onClick={() => {
                            props.goToApplyLicenseFunction(props.specifiedEmail)
                            props.handleCloseFunction()
                        }}
                    >
                        Next
                    </button>
                </div>
            </>
        )
    }

    const getCurrentStepBody = () => {
        switch (currentStep) {
            default:
            case 1:
                return stepOneContent()

            case 2:
                return stepTwoContent()

            case 3:
                return stepThreeContent()

            case 4:
                return stepFourContent()

            case 5:
                return stepFiveContent()
        }
    }

    const getCurrentStep = () => {
        return (
            <>
                <Modal.Body>{getCurrentStepBody()}</Modal.Body>
            </>
        )
    }

    return <>{getCurrentStep()}</>
}
