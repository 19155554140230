import { SignalValue } from '../api/BrokerApi/types'
import { ParsingType } from '../types/ParsingType'
import { SignalWithParsingType } from '../types/SignalWithParsingType'

const formatHex = (value: number | string) => {
    const hexValue = value.toString(16)
    return hexValue.length <= 1 ? `0${hexValue}` : hexValue
}

const formatAscii = (value: number | string) => {
    if (typeof value === 'number') {
        return String.fromCharCode(value)
    }
    const parsedCharCode = parseInt(value)
    return String.fromCharCode(parsedCharCode)
}

const formatNumber = (value: number | string): number => {
    const signalAsNumber = Number(value)
    return isNaN(signalAsNumber) ? 0 : signalAsNumber
}

const formatBit = (value: number | string): string => {
    const nummericDecimalValue = formatNumber(value)

    // Fill this with leading 0's to get a full byte
    const bits = nummericDecimalValue.toString(2)
    return `${new Array(9 - bits.length).join("0")}${bits}`
}

class SignalValueParser {
    parse(signalWithParsingType: SignalWithParsingType) {
        const { signalValue, parsingType } = signalWithParsingType
        switch (parsingType) {
            case ParsingType.DECIMAL_NUMBER:
                return this.parseToNumber(signalValue)

            case ParsingType.ARBITRATION:
                return this.parseToArbitration(signalValue)

            case ParsingType.HEX:
                return this.parseToHex(signalValue)

            case ParsingType.ASCII:
                return this.parseToAscii(signalValue)

            case ParsingType.BITS:
                return this.parseToBits(signalValue)

            case ParsingType.RAW:
                return this.parseToRaw(signalValue)
        }
    }

    parseToRaw(signalValue: SignalValue) {
        if (Array.isArray(signalValue.value)) {
            return `[${signalValue.value.toString()}]`
        }
        return `${signalValue.value}`
    }

    parseToAscii(signalValue: SignalValue) {
        if (Array.isArray(signalValue.value)) {
            return (signalValue.value as number[] | string[])
                .map((rawValue) => {
                    return `${formatAscii(rawValue)} `
                })
                .toString()
                .replaceAll(',', '')
        }
        return `${formatAscii(signalValue.value)}`
    }

    parseToHex(signalValue: SignalValue) {
        if (Array.isArray(signalValue.value)) {
            return (signalValue.value as number[] | string[])
                .map((rawValue) => {
                    return `${formatHex(rawValue)} `
                })
                .toString()
                .replaceAll(',', '')
        }
        return `${formatHex(signalValue.value)}`
    }

    parseToNumber(signalValue: SignalValue): number | number[] {
        if (Array.isArray(signalValue.value)) {
            // What makes sense here? A sum of all the values?
            return signalValue.value
                .map((value) => formatNumber(Number(value)))
                .reduce((accumulator, currentValue) => accumulator + currentValue)
        }
        return formatNumber(signalValue.value)
    }

    parseToBits(signalValue: SignalValue) {
        if (Array.isArray(signalValue.value)) {
            return signalValue.value
                .map((value) => formatBit(formatNumber(Number(value))))
                .join()
                .replaceAll(',', ' ')
        }
        return formatBit(formatNumber(Number(signalValue.value)))
    }

    parseToArbitration(signalValue: SignalValue) {
        return `${signalValue.value}`
    }
}

// Sneaky little singleton
export default new SignalValueParser()
