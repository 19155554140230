import { Container } from 'react-bootstrap'

import { CloudContext, ConnectionState } from '../services/LicenseService'
import ConfigurationContainer from '../components/ConfigurationContainer'
import LicenseFlowContainer from '../components/LicenseFlowContainer'
import PageCard from '../components/cards/PageCard'
import { LicenseInfo } from 'remotivelabs-grpc-web-stubs'
import BrokerInformationFooter from '../components/BrokerInformationFooter'

interface ConfigureProps {
    isCloudBroker: boolean
    connectionState: ConnectionState
    license: LicenseInfo | undefined
    setLicenseFunction: (license: LicenseInfo) => void
    isLicensed: boolean
    isAppInitialized: boolean
    cloudContext: CloudContext | undefined
    isFluidDesign: boolean
}

export default function Configure(props: ConfigureProps) {
    const cardContent = () => {
        if (!props.isLicensed) {
            return (
                <LicenseFlowContainer
                    setLicenseFunction={props.setLicenseFunction}
                    license={props.license}
                    isLicensed={props.isLicensed}
                    connectionState={props.connectionState}
                />
            )
        }
        return <ConfigurationContainer isLicensed={props.isLicensed} connectionState={props.connectionState} />
    }

    const containerContent = () => {
        return (
            <>
                <Container fluid={props.isFluidDesign} className={`${props.isFluidDesign ? 'px-4' : ''}`}>
                    <PageCard body={cardContent()} />
                </Container>

                {/* Sticky broker info */}
                <BrokerInformationFooter
                    isLicensed={props.isLicensed}
                    licenseInfo={props.license}
                    isAppInitialized={props.isAppInitialized}
                    isCloudBroker={props.isCloudBroker}
                    connectionState={props.connectionState}
                    cloudContext={props.cloudContext}
                />
            </>
        )
    }

    return <>{props.isAppInitialized && containerContent()}</>
}
