class AppStorage {
    
    public CLOUD_CONTEXT_STORAGE_KEY = 'CLOUD_CONTEXT_STORAGE_KEY'

    getItem = (key: string) => {
        return localStorage.getItem(key)
    }

    setItem = (key: string, itemToStore: string) => {
        localStorage.setItem(key, itemToStore)
    }
}

// Sneaky little singleton
export default new AppStorage()
