export const pythonSubscribeCommand = (
    brokerUrl: string,
    signalsToUseInExamples: Array<{ namespace: string; signals: Array<string> }>,
    brokerApiKey: string | undefined
) => {
    const cmd = `python3 subscribe.py --url ${brokerUrl} --x_api_key ${brokerApiKey} `
    //const namespaceArgs =
    //     '--namespace ' + signalsToUseInExamples.map((namespaceAndSignals) => namespaceAndSignals.namespace).join(' ')

    const signalArgs =
        '--signals ' +
        signalsToUseInExamples
            .map((namespaceAndSignals) =>
                namespaceAndSignals.signals.map((signal) => `${namespaceAndSignals.namespace}:${signal}`).join(' ')
            )
            .join(' ')

    return cmd + signalArgs
}

export const pythonSubscribeDemoCommand = (brokerUrl: string, brokerApiKey: string | undefined) => {
    return `python3 subscribe_demo.py --url ${brokerUrl} --x_api_key ${brokerApiKey} --script_path=scripts/Vehicle.Powertrain.TractionBattery.StateOfCharge.Displayed.lua`
}
