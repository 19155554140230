import { useEffect, useState } from 'react'
import { Modal } from 'react-bootstrap'

import { SignalWithParsingType } from '../../types/SignalWithParsingType'
import SignalValueParser from '../../utils/SignalValueParser'
import { ParsingType } from '../../types/ParsingType'
import { isIframe } from '../../utils/CloudDetails'

interface SignalValueHistoryModalProps {
    show: boolean
    handleCloseFunction: () => void
    signalHistoryList: Array<SignalWithParsingType>
}

const BACKGROUND_COLORS = ['remotive-primary-10-background', 'remotive-primary-20-background']

function SignalValueHistoryModal(props: SignalValueHistoryModalProps) {
    const [signalsToShow, setSignalsToShow] = useState<Array<SignalWithParsingType>>([])

    useEffect(() => {
        console.log('Mounting signal history modal!')
    }, [])

    useEffect(() => {
        if (props.show) {
            setSignalsToShow(props.signalHistoryList.slice(0).reverse())
        }
    }, [props.signalHistoryList, props.show])

    const createSignalValueList = () => {
        let lastTimestamp: number
        let backgroundColor = BACKGROUND_COLORS[0]
        if (signalsToShow.length > 0) {
            return signalsToShow.map((signalWithParsingType) => {
                const shouldSetNewBackground = signalWithParsingType.signalValue.timestampeMs !== lastTimestamp
                const delta = lastTimestamp
                    ? signalWithParsingType.signalValue.timestampeMs * 1000 - lastTimestamp * 1000
                    : 0
                if (shouldSetNewBackground) {
                    lastTimestamp = signalWithParsingType.signalValue.timestampeMs
                    backgroundColor =
                        BACKGROUND_COLORS.find((color) => color !== backgroundColor) || BACKGROUND_COLORS[0]
                }
                return (
                    <div
                        key={`${signalWithParsingType.signalValue.name}::${signalWithParsingType.signalValue.timestampeMs}`}
                        className={`m-1 p-1 row rounded ${backgroundColor}`}
                    >
                        <div className="col-4">
                            <p className="m-0 text-wrap text-truncate">{signalWithParsingType.signalValue.name}</p>
                            <p className="m-0 remotive-neutral-70-color text-truncate">
                                {signalWithParsingType.signalValue.nameSpace}
                            </p>
                        </div>
                        <div className="col-4">
                            <p
                                className={`m-0 text-wrap text-break ${
                                    signalWithParsingType.parsingType === ParsingType.BITS ||
                                    signalWithParsingType.parsingType === ParsingType.HEX
                                        ? 'font-monospace'
                                        : ''
                                }`}
                            >
                                {SignalValueParser.parse(signalWithParsingType)}
                            </p>
                        </div>
                        <div className="col-4 d-flex flex-column justify-content-between ">
                            <p className="m-0 text-wrap text-break">
                                {signalWithParsingType.signalValue.timestampeMs * 1000}
                            </p>
                            {delta !== 0 && (
                                <p className="d-none d-lg-block m-0 remotive-font-xs text-secondary">{`∆ ${delta}`}</p>
                            )}
                        </div>
                    </div>
                )
            })
        }
        return <p className="d-flex justify-content-center">No signals received yet...</p>
    }

    return (
        <>
            <Modal
                style={{ zIndex: '9999' }}
                size="lg"
                className="col-12"
                show={props.show}
                onHide={props.handleCloseFunction}
            >
                <Modal.Header closeVariant="white" className="remotive-primary-70-background" closeButton>
                    <Modal.Title className="lexend-regular text-light">Signal history</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="row text-start lexend-regular m-1 fs-6">
                        <div className="col-4">
                            <p className="m-0">Name</p>
                            <p className="m-0 remotive-font-xs text-secondary">Signal & namespace</p>
                        </div>
                        <div className="col-4">
                            <p className="m-0">Value</p>
                            <p className="m-0 remotive-font-xs text-secondary">Parsed signal value</p>
                        </div>
                        <div className="col-4">
                            <p className="m-0">Timestamp (μs)</p>
                            <p className="m-0 remotive-font-xs text-secondary">With delta to previous frame</p>
                        </div>
                    </div>
                    <div style={{ overflowY: 'scroll', height: isIframe() ? '45vh' : '60vh', maxHeight: 400 }}>
                        <div className="remotive-small">{createSignalValueList()}</div>
                    </div>
                </Modal.Body>
                <Modal.Footer className="border-0 pt-2">
                    <button className="btn remotive-btn remotive-btn-primary" onClick={props.handleCloseFunction}>
                        Close
                    </button>
                </Modal.Footer>
            </Modal>
        </>
    )
}

export default SignalValueHistoryModal
