import { FrameFilter, SignalFilter } from '../types/Filters'

export function isSignalIdentical(signalFilter: SignalFilter, expectedSignalFilter: SignalFilter) {
    return (
        signalFilter.signalName === expectedSignalFilter.signalName &&
        signalFilter.parentFrame.namespace === expectedSignalFilter.parentFrame.namespace
    )
}

export function isFrameIdentical(frameFilter: FrameFilter, expectedFrameFilter: FrameFilter) {
    return (
        frameFilter.frameName === expectedFrameFilter.frameName &&
        frameFilter.namespace === expectedFrameFilter.namespace
    )
}

export function isSignalChildOfFrame(frame: FrameFilter, signal: SignalFilter) {
    return signal.parentFrame.frameName === frame.frameName && signal.parentFrame.namespace === frame.namespace
}
