import { Form } from 'react-bootstrap'

interface TermsAndConditionsProps {
    termsAccepted: boolean
    setTermsAccepted: React.Dispatch<React.SetStateAction<boolean>>
}

const TERMSURL_REMOTIVE = 'https://www.remotivelabs.com/license'

export default function TermsAndConditions(props: TermsAndConditionsProps) {
    const termsAndConditions = () => {
        return (
            <Form.Group className="my-4" key={'terms-checkbox'}>
                <Form.Check
                    id="terms-checkbox"
                    className="d-flex align-items-center justify-content-center mb-3"
                    onChange={(event) => props.setTermsAccepted(event.target.checked)}
                    checked={props.termsAccepted}
                    label={
                        <p className={'ms-2 remotive-font mb-0 mt-1'}>
                            I agree to the{' '}
                            <a href={TERMSURL_REMOTIVE} target={'_blank'}>
                                terms and conditions
                            </a>
                        </p>
                    }
                />
            </Form.Group>
        )
    }

    return termsAndConditions()
}
