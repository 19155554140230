import { useEffect, useState } from 'react'
import { ConnectionState, extractMachineIdAsJson, fetchLicense } from '../../services/LicenseService'
import RequestALicenseModal from './RequestALicenseModal'
import ApplyLicenseOptionPhrase from './ApplyLicenseOptionPhrase'
import ApplyLicenseOptionFile from './ApplyLicenseOptionFile'
import TermsAndConditions from './TermsAndConditions'
import LicenseFlowOptionExistingLicense from './LicenseFlowOptionExistingLicense'
import LicenseFlowOptionNoLicense from './LicenseFlowOptionNoLicense'
import { License, LicenseInfo, LicenseStatus } from 'remotivelabs-grpc-web-stubs'
import { toast } from 'react-toastify'
import { formattedToastMessage } from '../../utils/toast'
import { applyNewLicense, getLicenseInfo } from '../../services/BrokerService'
import { Container, Spinner } from 'react-bootstrap'
import dayjs from 'dayjs'
import relativeTime from 'dayjs/plugin/relativeTime'
import base64js from 'base64-js'
import AccessPointAnnouncement from './AccessPointAnnouncement'

dayjs.extend(relativeTime)

interface LicenseFlowContainerProps {
    connectionState: ConnectionState
    isLicensed: boolean
    license: LicenseInfo | undefined
    setLicenseFunction: (license: LicenseInfo) => void
}

enum LicenseFlowState {
    CURRENT_LICENSE_INFO,
    CHOOSE_LICENSE_OPTION,
    APPLY_LICENSE,
}

const LICENSE_OPTION_CARD_MAX_WIDTH = 520
const LICENSE_OPTION_CARD_MIN_HEIGHT = 310

export default function LicenseFlowContainer(props: LicenseFlowContainerProps) {
    const [licenseFileToUpload, setLicenseFileToUpload] = useState<File>()
    const [licenseRequestEmail, setLicenseRequestEmail] = useState<string>('')
    const [licensePhrase, setLicensePhrase] = useState('')
    const [termsAccepted, setTermsAccepted] = useState(false)
    const [commandApplyLicense, setCommandApplyLicense] = useState(false)
    const [isEmailSetFromRequestALicenseFlow, setIsEmailSetFromRequestALicenseFlow] = useState<boolean>(false)
    const [showRequestALicenseModal, setShowRequestALicenseModal] = useState<boolean>(false)
    const [licenseFlowState, setLicenseFlowState] = useState<LicenseFlowState>(
        props.isLicensed ? LicenseFlowState.CURRENT_LICENSE_INFO : LicenseFlowState.CHOOSE_LICENSE_OPTION
    )

    /* Read license from file and then apply to broker */
    useEffect(() => {
        if (commandApplyLicense && licenseFileToUpload) {
            if (licenseFileToUpload && props.connectionState.clientIsConnectedToBroker) {
                readLicenseFile(licenseFileToUpload)
                    .then((license) => fetchAndApplyNewLicense(license))
                    .catch((err: any) => {
                        console.error(err)
                        setCommandApplyLicense(false)
                        toast.error(formattedToastMessage('License error', `${err.message}.`), { autoClose: 25_000 })
                    })
            }
        }
    }, [commandApplyLicense, props.connectionState])

    /* Fetch license over internet and then apply to broker */
    useEffect(() => {
        if (commandApplyLicense && !licenseFileToUpload) {
            if (props.connectionState.clientHasInternet) {
                const trimmedLicensePhrase = licensePhrase.trim().replaceAll('-', '')
                fetchLicense(licenseRequestEmail as string, trimmedLicensePhrase)
                    .then((license) => fetchAndApplyNewLicense(license))
                    .catch((err: any) => {
                        setCommandApplyLicense(false)
                        toast.error(formattedToastMessage('License error', err.message), { autoClose: 25_000 })
                    })
            }
        }
    }, [commandApplyLicense, props.connectionState])

    const resetApplyLicenseStates = () => {
        setCommandApplyLicense(false)
        setIsEmailSetFromRequestALicenseFlow(false)
        setShowRequestALicenseModal(false)
        setLicenseRequestEmail('')
        setLicensePhrase('')
        setTermsAccepted(false)
    }

    const convertLicenseStatusToInformationalMessage = (statusCode: LicenseStatus) => {
        switch (statusCode) {
            case LicenseStatus.UNSET:
                return 'No license set'

            case LicenseStatus.VALID:
                return 'The license is valid'

            case LicenseStatus.EXPIRED:
                return 'The license has expired'

            case LicenseStatus.BADDATE:
                return 'The license expiration date is malformed'

            case LicenseStatus.WRONGMACHINE:
                return 'This license was issued for another hardware ID'

            case LicenseStatus.INCOMPLETEJSON:
                return 'The provided license data is malformed'

            case LicenseStatus.INVALIDJSON:
                return 'The provided license data is malformed'

            case LicenseStatus.BADSIGNATURE:
                return 'The provided license data is malformed'

            case LicenseStatus.MALFORMED:
                return 'The provided license data is malformed'

            case LicenseStatus.SERVERERROR:
                return 'Unknown RemotiveBroker error'

            case LicenseStatus.NOTERMSAGREEMENT:
                return 'The terms and agreements must be accepted to acquire a license'

            default:
                return 'Unknown error'
        }
    }

    async function fetchAndApplyNewLicense(licenseToApply: License): Promise<void> {
        try {
            const license = await applyNewLicense(licenseToApply)
            if (license.getStatus() !== LicenseStatus.VALID) {
                setCommandApplyLicense(false)
                throw new Error(convertLicenseStatusToInformationalMessage(license.getStatus()))
            }
            // No error, get license to view
            const newLicense = await getLicenseInfo()
            setLicenseFlowState(LicenseFlowState.CURRENT_LICENSE_INFO)
            props.setLicenseFunction(newLicense)
            resetApplyLicenseStates()
            toast.success(
                formattedToastMessage('License is valid!', 'You have successsfully licensed this RemotiveBroker.')
            )
        } catch (e: any) {
            throw e
        }
    }

    async function readLicenseFile(licenseFileToUpload: File): Promise<License> {
        return new Promise((resolve) => {
            const reader = new FileReader()
            reader.readAsArrayBuffer(licenseFileToUpload)

            reader.onerror = (e) => {
                toast.error(
                    formattedToastMessage(
                        'License error',
                        `Failed to read license file "${licenseFileToUpload.name}":\n\n${reader.error}`
                    )
                )
            }
            reader.onload = (e: any) => {
                const licenseToApply = new License()
                licenseToApply.setData(new Uint8Array(e.target.result).slice(0)).setTermsagreement(true)
                resolve(licenseToApply)
            }
        })
    }

    const licenseFlowOptions = () => {
        return props.connectionState.clientIsConnectedToBroker ? (
            <>
                <div className="d-flex flex-row align-items-center justify-content-center flex-wrap flex-lg-nowrap">
                    <div>
                        <LicenseFlowOptionNoLicense
                            LICENSE_OPTION_CARD_MAX_WIDTH={LICENSE_OPTION_CARD_MAX_WIDTH}
                            LICENSE_OPTION_CARD_MIN_HEIGHT={LICENSE_OPTION_CARD_MIN_HEIGHT}
                            showRequestLicenseModal={() => setShowRequestALicenseModal(true)}
                            connectedToBroker={props.connectionState.clientIsConnectedToBroker}
                        />
                    </div>
                    <p className="fs-3 mx-5 lexend-bold col-12 col-lg-1">Or</p>
                    <div>
                        <LicenseFlowOptionExistingLicense
                            LICENSE_OPTION_CARD_MAX_WIDTH={LICENSE_OPTION_CARD_MAX_WIDTH}
                            LICENSE_OPTION_CARD_MIN_HEIGHT={LICENSE_OPTION_CARD_MIN_HEIGHT}
                            goToApplyLicenseState={() => setLicenseFlowState(LicenseFlowState.APPLY_LICENSE)}
                            connectedToBroker={props.connectionState.clientIsConnectedToBroker}
                        />
                    </div>
                </div>
            </>
        ) : (
            <></>
        )
    }

    const brokerIsDisconnectedTitle = () => {
        return (
            <>
                <p className="m-0 fs-4">
                    This <span className="remotive-primary-50-color lexend-bold">RemotiveBroker</span> seems to be
                    disconnected
                </p>
                {!props.connectionState.clientIsConnectedToBroker && (
                    <div className="d-flex align-items-center justify-content-center mt-3 mb-2">
                        <Spinner style={{ height: 10, width: 10 }} size="sm" className="me-2" />
                        <p className="m-0 remotive-font-sm text-secondary">
                            Connect to the <span className="remotive-primary-50-color lexend-bold">RemotiveBroker</span>{' '}
                            to proceed...
                        </p>
                    </div>
                )}
            </>
        )
    }

    const licenseExistTitle = () => {
        return (
            <>
                <p className="m-0 fs-4">
                    Set up new <span className="remotive-primary-50-color lexend-bold">RemotiveBroker</span> license
                </p>
                <p className="m-0 remotive-font-sm mb-3">
                    Proceed to set up a new <b>license</b>.
                </p>
            </>
        )
    }

    const unlicensedText = () => {
        return (
            <>
                <p className="m-0 fs-4">
                    This <span className="remotive-primary-50-color lexend-bold">RemotiveBroker</span> appears to be
                    unlicensed
                </p>
                <p className="m-0 remotive-font-sm mb-3">
                    Please set up a <b>license</b> for your broker to proceed.
                </p>
            </>
        )
    }

    function pickLicenseFile(event: any) {
        const files: Array<File> = Array.from(event.target.files)
        if (files.length > 0) {
            // It should not be possible to select more than one or a directory
            setLicenseFileToUpload(files[0])
        } else {
            setLicenseFileToUpload(undefined)
        }
    }

    const emailValid = () => {
        return licenseRequestEmail?.includes('@') && licenseRequestEmail.includes('.')
    }

    const licenseValid = () => {
        return licensePhrase !== '' && new RegExp(/^([0-9a-f]{16})?$/).test(licensePhrase.trim().replaceAll('-', ''))
    }

    const applyLicenseOptions = () => {
        return (
            <>
                <div className="d-flex flex-column justify-content-center">
                    <div className="d-flex flex-row align-items-center justify-content-center flex-wrap flex-lg-nowrap">
                        <div className="text-start col-12 col-lg-5">
                            <ApplyLicenseOptionPhrase
                                isLicenseRequestEmailValid={emailValid()}
                                isLicensePhraseValid={licenseValid()}
                                connectionState={props.connectionState}
                                licenseRequestEmail={licenseRequestEmail}
                                licenseFileToUpload={licenseFileToUpload}
                                setLicenseRequestEmail={setLicenseRequestEmail}
                                termsAccepted={termsAccepted}
                                isEmailSetFromRequestALicenseFlow={isEmailSetFromRequestALicenseFlow}
                                setLicesePhrase={setLicensePhrase}
                                licensePhrase={licensePhrase}
                            />
                        </div>
                        <p className="fs-3 mx-5 lexend-bold col-12 col-lg-1">Or</p>
                        <div className="text-start col-12 col-lg-5">
                            <ApplyLicenseOptionFile
                                connectionState={props.connectionState}
                                licenseFileToUpload={licenseFileToUpload}
                                isLicensePhraseInputValid={emailValid() && licenseValid()}
                                pickLicenseFile={pickLicenseFile}
                            />
                        </div>
                    </div>
                    <div className="mt-5">
                        <TermsAndConditions termsAccepted={termsAccepted} setTermsAccepted={setTermsAccepted} />
                        <button
                            disabled={
                                commandApplyLicense ||
                                !(termsAccepted && ((licenseRequestEmail && licensePhrase) || licenseFileToUpload))
                            }
                            className="btn remotive-btn remotive-btn-success"
                            onClick={() => setCommandApplyLicense(true)}
                        >
                            {commandApplyLicense ? <Spinner size="sm" /> : 'Apply license'}
                        </button>
                    </div>
                </div>
            </>
        )
    }

    const renderMachineId = (licenseInfo: LicenseInfo) => {
        const machineIdStr = extractMachineIdAsJson(licenseInfo)
            .map((ifadr: any) => {
                return `${ifadr.if} ${ifadr.adr}`
            })
            .join(',')
        return base64js.fromByteArray(new TextEncoder().encode(machineIdStr))
    }

    const renderValidLicense = () => {
        if (props.license === undefined) {
            return <></>
        }

        return (
            <>
                <p className="m-0">This RemotiveBroker installation is licensed for use until</p>
                <p>
                    {props.license.getExpires()} ({dayjs(props.license.getExpires()).fromNow()}).
                </p>
                <p className="m-0">Unique machine ID:</p>
                <p className="small">{renderMachineId(props.license)}</p>
            </>
        )
    }

    const renderInvalidLicense = () => {
        return (
            <>
                <b>This RemotiveBroker installation is unlicensed.</b>
                <br />
                License data is malformed or empty.
                <br />
                <br />
                Unique machine ID:
                <p className="small">{props.license && renderMachineId(props.license)}</p>
            </>
        )
    }

    const getContent = () => {
        switch (licenseFlowState) {
            default:
            case LicenseFlowState.CURRENT_LICENSE_INFO:
                return (
                    <Container className="text-center my-5">
                        <p className="fs-3">License</p>

                        <div>
                            {props.license?.getStatus() === LicenseStatus.VALID
                                ? renderValidLicense()
                                : props.license
                                ? renderInvalidLicense()
                                : 'Not Connected to broker'}
                            {props.license && (
                                <>
                                    <button
                                        disabled={!props.connectionState.clientIsConnectedToBroker}
                                        className="btn remotive-btn remotive-btn-primary"
                                        onClick={() => setLicenseFlowState(LicenseFlowState.CHOOSE_LICENSE_OPTION)}
                                    >
                                        Apply new license to broker
                                    </button>
                                </>
                            )}
                        </div>
                    </Container>
                )

            case LicenseFlowState.CHOOSE_LICENSE_OPTION:
                return (
                    <>
                        {props.isLicensed && (
                            <div className="d-flex justify-content-start">
                                <button
                                    className="btn remotive-btn-sm remotive-btn-secondary"
                                    onClick={() => {
                                        setIsEmailSetFromRequestALicenseFlow(false)
                                        setLicenseFlowState(LicenseFlowState.CURRENT_LICENSE_INFO)
                                    }}
                                >
                                    Cancel
                                </button>
                            </div>
                        )}
                        <div className="d-flex flex-column align-items-center my-4">
                            <div className="mt-3 mb-3">
                                <div>
                                    {!props.connectionState.clientIsConnectedToBroker
                                        ? brokerIsDisconnectedTitle()
                                        : props.isLicensed
                                        ? licenseExistTitle()
                                        : unlicensedText()}
                                </div>
                            </div>
                            <AccessPointAnnouncement connectionState={props.connectionState} />
                            <div>{licenseFlowOptions()}</div>
                        </div>
                    </>
                )

            case LicenseFlowState.APPLY_LICENSE:
                return (
                    <>
                        <div className="d-flex justify-content-start">
                            <button
                                className="btn remotive-btn-sm remotive-btn-secondary"
                                onClick={() => {
                                    setLicensePhrase("")
                                    setLicenseFileToUpload(undefined)
                                    setIsEmailSetFromRequestALicenseFlow(false)
                                    setLicenseFlowState(LicenseFlowState.CHOOSE_LICENSE_OPTION)
                                }}
                            >
                                Back
                            </button>
                        </div>
                        <div className="mt-3 mb-3">
                            <p className="fs-3 lexend-bold pt-2 pb-3">Apply your license</p>
                            <div className="my-4">{applyLicenseOptions()}</div>
                        </div>
                    </>
                )
        }
    }

    return (
        <>
            <div className="d-flex flex-column">{getContent()}</div>
            <RequestALicenseModal
                show={showRequestALicenseModal}
                goToApplyLicenseFunction={(specifiedEmail: string) => {
                    setIsEmailSetFromRequestALicenseFlow(true)
                    setLicenseRequestEmail(specifiedEmail)
                    setLicenseFlowState(LicenseFlowState.APPLY_LICENSE)
                }}
                handleCloseFunction={() => setShowRequestALicenseModal(false)}
                connectionState={props.connectionState}
                license={props.license}
            />
        </>
    )
}
