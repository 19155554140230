import { License, LicenseInfo } from 'remotivelabs-grpc-web-stubs'
import BrokerApi from '../../api/BrokerApi'

export class LicenseDelegate {
    private _brokerGrpcClient: BrokerApi

    constructor(brokerGrpcClient: BrokerApi) {
        this._brokerGrpcClient = brokerGrpcClient
    }

    getLicenseInfo = (): Promise<LicenseInfo> => {
        return this._brokerGrpcClient.getLicence()
    }

    applyNewLicense = (license: License): Promise<LicenseInfo> => {
        return this._brokerGrpcClient.applyLicense(license)
    }
}
