import UpgradeContainer from './UpdateContainer'
import { useEffect } from 'react'
import { Container } from 'react-bootstrap'
import { LicenseInfo } from 'remotivelabs-grpc-web-stubs'
import { ConnectionState } from '../../services/LicenseService'

interface AboutCardProps {
    isCloudBroker: boolean
    connectionState: ConnectionState
    setLicenseFunction: (license: LicenseInfo) => void
    isLicensed: boolean
}

export default function AboutContainer(props: AboutCardProps) {
    useEffect(() => {}, [props.connectionState])

    return (
        <>
            <UpgradeContainer
                isCloudBroker={props.isCloudBroker}
                connectionState={props.connectionState}
                isLicensed={props.isLicensed}
            />

            {
                <>
                    <hr />
                    <Container className="text-center">
                        <h3>RemotiveBrokerApp</h3>
                        {props.connectionState.brokerHasEthernet && (
                            <p>
                                This client is also accessible on <br />
                                {props.connectionState.brokerPublicUrls!.map((url) => (
                                    <>
                                        <a href={url}>{url}</a>
                                        <br />
                                    </>
                                ))}
                            </p>
                        )}
                        {!props.connectionState.brokerHasEthernet && <p>Broker is not connected to ethernet</p>}
                    </Container>
                </>
            }
        </>
    )
}
