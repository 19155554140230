import { Alert, Form } from 'react-bootstrap'
import { ConnectionState } from '../../services/LicenseService'
import { WarningRounded } from '@mui/icons-material'

interface LicenseFileOptionProps {
    connectionState: ConnectionState
    isLicensePhraseInputValid: boolean
    pickLicenseFile: (event: any) => void
    licenseFileToUpload: File | undefined
}

export default function LicenseFileOption(props: LicenseFileOptionProps) {
    const licenseFileOption = () => {
        const isAllInputValid = props.licenseFileToUpload !== undefined
        const clientHasBrokerConnection = props.connectionState.clientIsConnectedToBroker
        return (
            <>
                <div
                    className={`rounded-4 ${
                        !clientHasBrokerConnection || props.isLicensePhraseInputValid
                            ? 'remotive-neutral-40-background'
                            : isAllInputValid
                            ? 'remotive-success-20-background'
                            : 'remotive-primary-10-background'
                    } p-4`}
                    style={{ minHeight: 300 }}
                >
                    <p className="m-0 fs-5">
                        I have a license <span className="lexend-bold">file</span>
                    </p>
                    <p className="mb-4 remotive-font-sm text-secondary">
                        The license file is a downloadable file that is found in the license email. Below you can upload
                        the license file and apply it to the broker.
                    </p>
                    {!clientHasBrokerConnection && (
                        <div className="d-flex justify-content-center">
                            <Alert
                                variant="warning"
                                className="p-2 remotive-warning-10-background"
                                style={{ maxWidth: 420 }}
                            >
                                <div className="d-flex align-items-center">
                                    <WarningRounded className="me-3 text-warning" />
                                    <p className="m-0 remotive-font-md">
                                        You can not use the license <b>file</b> option without a connection to the{' '}
                                        <span className="remotive-primary-50-color lexend-bold">RemotiveBroker</span>.
                                    </p>
                                </div>
                            </Alert>
                        </div>
                    )}
                    <fieldset disabled={!clientHasBrokerConnection || props.isLicensePhraseInputValid}>
                        <Form.Label className={'remotive-font-xs m-0'}>License file</Form.Label>
                        <Form validated={isAllInputValid}>
                            <Form.Control
                                id="fileupload"
                                onChange={props.pickLicenseFile}
                                type="file"
                                size="sm"
                                {...{
                                    directory: '',
                                    multiple: false,
                                }}
                            />
                        </Form>
                    </fieldset>
                </div>
            </>
        )
    }

    return licenseFileOption()
}
