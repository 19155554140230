import { BadgeRounded, CancelRounded } from '@mui/icons-material'
import { Spinner } from 'react-bootstrap'

interface LicenseFlowOptionNoLicenseProps {
    LICENSE_OPTION_CARD_MIN_HEIGHT: number
    LICENSE_OPTION_CARD_MAX_WIDTH: number
    connectedToBroker: boolean
    showRequestLicenseModal: () => void
}

export default function LicenseFlowOptionNoLicense(props: LicenseFlowOptionNoLicenseProps) {
    const noLicenseIcon = () => {
        return (
            <div>
                <BadgeRounded className="remotive-primary-20-color" sx={{ fontSize: 90 }} style={{ marginTop: 0 }} />
                <CancelRounded
                    className="position-absolute remotive-primary-70-color"
                    sx={{ marginTop: 7, marginLeft: -4, fontSize: 40, zIndex: 1 }}
                />
            </div>
        )
    }

    const noLicenseOption = () => {
        return (
            <div
                className="rounded-4 remotive-primary-10-background p-4"
                style={{ minHeight: props.LICENSE_OPTION_CARD_MIN_HEIGHT }}
            >
                <div
                    style={{ maxWidth: props.LICENSE_OPTION_CARD_MAX_WIDTH }}
                    className="d-flex flex-column align-items-center"
                >
                    <div className="mb-4">{noLicenseIcon()}</div>
                    <p className="fs-5 m-0">I need a license</p>
                    <p className="remotive-font-sm m-0 text-secondary">
                        If you want to request a new license or if you want an existing license re-sent, you will be
                        asked to supply an email address. We will send the license to your email inbox.
                    </p>
                    <div className="mt-3">
                        <div>
                            {!props.connectedToBroker && (
                                <div className="d-flex align-items-center justify-content-center mt-4 mb-2">
                                    <Spinner style={{ height: 10, width: 10 }} size="sm" className="me-2" />
                                    <p className="m-0 remotive-font-xs text-secondary">
                                        Connect to the{' '}
                                        <span className="remotive-primary-50-color lexend-bold">RemotiveBroker</span> to
                                        proceed...
                                    </p>
                                </div>
                            )}
                        </div>
                        <button
                            className="btn remotive-btn remotive-btn-primary"
                            disabled={!props.connectedToBroker}
                            onClick={() => props.showRequestLicenseModal()}
                        >
                            Request license
                        </button>
                    </div>
                </div>
            </div>
        )
    }

    return noLicenseOption()
}
