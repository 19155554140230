// Scripts

export const vssIsLeftIndicatorOn = (): string => {
    return `local local_signals = {{
        name = "ID3F5VCFRONT_lighting.VCFRONT_indicatorLeftRequest",
        namespace = "VehicleBus"
    }, {
        name = "ID3F5VCFRONT_lighting.VCFRONT_indicatorRightRequest",
        namespace = "VehicleBus"
    }}
    local local_frequecy_hz = 0
    
    -- Required, declare which input is needed to operate this program.
    function input_signals()
        return local_signals
    end
    
    -- Provided parameters are used for populating metadata when listing signals.
    function output_signals()
        return "Vehicle.Body.Lights.IsLeftIndicatorOn"
    end
    
    -- Required, declare what frequence you like to get "timer" invoked. 0 means no calls to "timer".
    function timer_frequency_hz()
        return local_frequecy_hz
    end
    
    -- Invoked with the frequecy returned by "timer_frequency_hz".
    -- @param system_timestamp_us: system time stamp 
    function timer(system_timestamp_us)
        return return_value_or_bytes("your value")
    end
    
    -- Invoked when ANY signal declared in "local_signals" arrive
    -- @param signals_timestamp_us: signal time stamp
    -- @param system_timestamp_us
    -- @param signals: array of signals containing all or a subset of signals declared in "local_signals". Make sure to nil check before use.
    function signals(signals, namespace, signals_timestamp_us, system_timestamp_us)
        if signals["VCFRONT_indicatorLeftRequest"] == 0 then
            return return_value_or_bytes(0)
        else
            return return_value_or_bytes(1)
        end 
    end
    
    -- helper return function, make sure to use return_value_or_bytes or return_nothing.
    function return_value_or_bytes(value_or_bytes)
        return value_or_bytes
    end
    
    -- helper return function, make sure to use return_value_or_bytes or return_nothing.
    function return_nothing()
        return
    end    
    `
}

export const vssIsRightIndicatorOn = (): string => {
    return `local local_signals = {{
        name = "ID3F5VCFRONT_lighting.VCFRONT_indicatorLeftRequest",
        namespace = "VehicleBus"
    }, {
        name = "ID3F5VCFRONT_lighting.VCFRONT_indicatorRightRequest",
        namespace = "VehicleBus"
    }}
    local local_frequecy_hz = 0
    
    -- Required, declare which input is needed to operate this program.
    function input_signals()
        return local_signals
    end
    
    -- Provided parameters are used for populating metadata when listing signals.
    function output_signals()
        return "Vehicle.Body.Lights.IsRightIndicatorOn"
    end
    
    -- Required, declare what frequence you like to get "timer" invoked. 0 means no calls to "timer".
    function timer_frequency_hz()
        return local_frequecy_hz
    end
    
    -- Invoked with the frequecy returned by "timer_frequency_hz".
    -- @param system_timestamp_us: system time stamp 
    function timer(system_timestamp_us)
        return return_value_or_bytes("your value")
    end
    
    -- Invoked when ANY signal declared in "local_signals" arrive
    -- @param signals_timestamp_us: signal time stamp
    -- @param system_timestamp_us
    -- @param signals: array of signals containing all or a subset of signals declared in "local_signals". Make sure to nil check before use.
    function signals(signals, namespace, signals_timestamp_us, system_timestamp_us)
        if signals["VCFRONT_indicatorRightRequest"] == 0 then
            return return_value_or_bytes(0)
        else
            return return_value_or_bytes(1)
        end 
    end
    
    -- helper return function, make sure to use return_value_or_bytes or return_nothing.
    function return_value_or_bytes(value_or_bytes)
        return value_or_bytes
    end
    
    -- helper return function, make sure to use return_value_or_bytes or return_nothing.
    function return_nothing()
        return
    end    
    `
}

export const vssHeading = (): string => {
    return `local local_signals = {{
        name = "ID2D3UI_solarData.UI_solarAzimuthAngleCarRef",
        namespace = "ChassisBus"
    }, {
        name = "ID2D3UI_solarData.UI_solarAzimuthAngle",
        namespace = "ChassisBus"
    }}
    local local_frequecy_hz = 0
    
    -- Required, declare which input is needed to operate this program.
    function input_signals()
        return local_signals
    end
    
    -- Provided parameters are used for populating metadata when listing signals.
    function output_signals()
        return "Vehicle.CurrentLocation.Heading"
    end
    
    -- Required, declare what frequence you like to get "timer" invoked. 0 means no calls to "timer".
    function timer_frequency_hz()
        return local_frequecy_hz
    end
    
    -- Invoked with the frequecy returned by "timer_frequency_hz".
    -- @param system_timestamp_us: system time stamp 
    function timer(system_timestamp_us)
        return return_value_or_bytes("your value")
    end
    
    -- Invoked when ANY signal declared in "local_signals" arrive
    -- @param signals_timestamp_us: signal time stamp
    -- @param system_timestamp_us
    -- @param signals: array of signals containing all or a subset of signals declared in "local_signals". Make sure to nil check before use.
    function signals(signals, namespace, signals_timestamp_us, system_timestamp_us)
        return return_value_or_bytes(math.fmod((360 + signals["ID2D3UI_solarData.UI_solarAzimuthAngle"] - signals["ID2D3UI_solarData.UI_solarAzimuthAngleCarRef"]), 360))
    end
    
    -- helper return function, make sure to use return_value_or_bytes or return_nothing.
    function return_value_or_bytes(value_or_bytes)
        return value_or_bytes
    end
    
    -- helper return function, make sure to use return_value_or_bytes or return_nothing.
    function return_nothing()
        return
    end
    `
}

export const vssStateOfChargeDisplayed10Hz = (): string => {
    return `local local_signals = {{
        name = "ID352BMS_energyStatus.BMS_nominalFullPackEnergy",
        namespace = "VehicleBus"
    }, {
        name = "ID352BMS_energyStatus.BMS_nominalEnergyRemaining",
        namespace = "VehicleBus"
    }}
    local local_frequecy_hz = 10
    local state_of_charge = 0
    
    -- Required, declare which input is needed to operate this program.
    function input_signals()
        return local_signals
    end
    
    -- Provided parameters are used for populating metadata when listing signals.
    function output_signals()
        return "Vehicle.Powertrain.TractionBattery.StateOfCharge.Displayed_10Hz"
    end
    
    -- Required, declare what frequence you like to get "timer" invoked. 0 means no calls to "timer".
    function timer_frequency_hz()
        return local_frequecy_hz
    end
    
    -- Invoked with the frequecy returned by "timer_frequency_hz".
    -- @param system_timestamp_us: system time stamp 
    function timer(system_timestamp_us)
        return return_value_or_bytes(state_of_charge)
    end
    
    -- Invoked when ANY signal declared in "local_signals" arrive
    -- @param signals_timestamp_us: signal time stamp
    -- @param system_timestamp_us
    -- @param signals: array of signals containing all or a subset of signals declared in "local_signals". Make sure to nil check before use.
    function signals(signals, namespace, signals_timestamp_us, system_timestamp_us)
        state_of_charge = signals["ID352BMS_energyStatus.BMS_nominalEnergyRemaining"] / signals["ID352BMS_energyStatus.BMS_nominalFullPackEnergy"]
        return return_nothing()
    end
    
    -- helper return function, make sure to use return_value_or_bytes or return_nothing.
    function return_value_or_bytes(value_or_bytes)
        return value_or_bytes
    end
    
    -- helper return function, make sure to use return_value_or_bytes or return_nothing.
    function return_nothing()
        return
    end
    `
}

export const vssStateOfChargeDisplayed = (): string => {
    return `local local_signals = {{
        name = "ID352BMS_energyStatus.BMS_nominalFullPackEnergy",
        namespace = "VehicleBus"
    }, {
        name = "ID352BMS_energyStatus.BMS_nominalEnergyRemaining",
        namespace = "VehicleBus"
    }}
    local local_frequecy_hz = 0
    
    -- Required, declare which input is needed to operate this program.
    function input_signals()
        return local_signals
    end
    
    -- Provided parameters are used for populating metadata when listing signals.
    function output_signals()
        return "Vehicle.Powertrain.TractionBattery.StateOfCharge.Displayed"
    end
    
    -- Required, declare what frequence you like to get "timer" invoked. 0 means no calls to "timer".
    function timer_frequency_hz()
        return local_frequecy_hz
    end
    
    -- Invoked with the frequecy returned by "timer_frequency_hz".
    -- @param system_timestamp_us: system time stamp 
    function timer(system_timestamp_us)
        return return_value_or_bytes("your value")
    end
    
    -- Invoked when ANY signal declared in "local_signals" arrive
    -- @param signals_timestamp_us: signal time stamp
    -- @param system_timestamp_us
    -- @param signals: array of signals containing all or a subset of signals declared in "local_signals". Make sure to nil check before use.
    function signals(signals, namespace, signals_timestamp_us, system_timestamp_us)
        return return_value_or_bytes(signals["ID352BMS_energyStatus.BMS_nominalEnergyRemaining"] / signals["ID352BMS_energyStatus.BMS_nominalFullPackEnergy"])
    end
    
    -- helper return function, make sure to use return_value_or_bytes or return_nothing.
    function return_value_or_bytes(value_or_bytes)
        return value_or_bytes
    end
    
    -- helper return function, make sure to use return_value_or_bytes or return_nothing.
    function return_nothing()
        return
    end
    `
}

// Simple mappings

export const vssSpeed = (): string => {
    return `"Vehicle.Speed": {
        "simple_mapping": {
            "namespace": "VehicleBus",
            "name": "ID257DIspeed.DI_vehicleSpeed"
        }
    }`
}

export const vssPedalPosition = (): string => {
    return `"Vehicle.Chassis.Accelerator.PedalPosition": {
        "simple_mapping": {
           "namespace": "VehicleBus",
           "name": "ID118DriveSystemStatus.DI_accelPedalPos"
        }
     }`
}

export const vssCurrentGear = (): string => {
    return `"Vehicle.Powertrain.Transmission.CurrentGear": {
        "simple_mapping": {
           "namespace": "VehicleBus",
           "name": "ID118DriveSystemStatus.DI_gear"
        }
     }`
}

export const vssSteeringAngle = (): string => {
    return `"Vehicle.Chassis.SteeringWheel.Angle": {
        "simple_mapping": {
           "namespace": "VehicleBus",
           "name": "ID129SteeringAngle.SteeringAngle129"
        }
     }`
}

export const vssLongitude = (): string => {
    return `"Vehicle.CurrentLocation.Longitude": {
        "simple_mapping": {
           "namespace": "ChassisBus",
           "name": "ID04FGPSLatLong.GPSLongitude04F"
        }
     }`
}

export const vssLatitude = (): string => {
    return `"Vehicle.CurrentLocation.Latitude": {
        "simple_mapping": {
           "namespace": "ChassisBus",
           "name": "ID04FGPSLatLong.GPSLatitude04F"
        }
     }`
}

export const vssHorizontalAccuracy = (): string => {
    return `"Vehicle.CurrentLocation.HorizontalAccuracy": {
        "simple_mapping": {
           "namespace": "ChassisBus",
           "name": "ID04FGPSLatLong.GPSAccuracy04F"
        }
     }`
}
