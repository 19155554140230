export type TabKeyState = {
    tabKey: string
    setTabKey: (newTabKey: string) => any
}

export enum TabKey {
    PLAYBACK_TAB_KEY = 'playback',
    RECORD_TAB_KEY = 'record',
    ABOUT_TAB_KEY = 'about',
    CONFIG_TAB_KEY = 'config',
}
