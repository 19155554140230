// Scripts

export const aaosCurrentGear = (): string => {
    return `local local_signals = {{
        name = "ID118DriveSystemStatus.DI_gear",
        namespace = "VehicleBus"
    }}
    local local_frequecy_hz = 0
    
    -- Required, declare which input is needed to operate this program.
    function input_signals()
        return local_signals
    end
    
    -- Provided parameters are used for populating metadata when listing signals.
    function output_signals()
        return "CURRENT_GEAR"
    end
    
    -- Required, declare what frequence you like to get "timer" invoked. 0 means no calls to "timer".
    function timer_frequency_hz()
        return local_frequecy_hz
    end
    
    -- Invoked with the frequecy returned by "timer_frequency_hz".
    -- @param system_timestamp_us: system time stamp 
    function timer(system_timestamp_us)
        return return_value_or_bytes("your value")
    end
    
    -- Andorid docs
    -- VehicleGear#GEAR_REVERSE 2 VehicleGear#GEAR_PARK 4 VehicleGear#GEAR_DRIVE 8 VehicleGear#GEAR_NEUTRAL 1
    
    -- Source docs 
    -- VAL_ 280 DI_gear 4 "DI_GEAR_D" 0 "DI_GEAR_INVALID" 3 "DI_GEAR_N" 1 "DI_GEAR_P" 2 "DI_GEAR_R" 7 "DI_GEAR_SNA" ;
    
    local to_android = {
        [0] = 0xFF,
        [1] = 4,
        [2] = 2,
        [3] = 1,
        [4] = 8,
        [7] = 0xFF
    }
    
    -- Invoked when ANY signal declared in "local_signals" arrive
    -- @param signals_timestamp_us: signal time stamp
    -- @param system_timestamp_us
    -- @param signals: array of signals containing all or a subset of signals declared in "local_signals". Make sure to nil check before use.
    function signals(signals, namespace, signals_timestamp_us, system_timestamp_us)
        current_gear = to_android[signals["ID118DriveSystemStatus.DI_gear"]]
        if current_gear ~= nil then
            return return_value_or_bytes(current_gear)
        else
            return return_value_or_bytes("not mapped")
        end
    end
    
    -- helper return function, make sure to use return_value_or_bytes or return_nothing.
    function return_value_or_bytes(value_or_bytes)
        return value_or_bytes
    end
    
    -- helper return function, make sure to use return_value_or_bytes or return_nothing.
    function return_nothing()
        return
    end
    `
}

export const aaosEvBatteryLevel = (): string => {
    return `local local_signals = {{
        name = "ID352BMS_energyStatus.BMS_nominalFullPackEnergy",
        namespace = "VehicleBus"
    }, {
        name = "ID352BMS_energyStatus.BMS_nominalEnergyRemaining",
        namespace = "VehicleBus"
    }}
    local local_frequecy_hz = 0
    
    -- Required, declare which input is needed to operate this program.
    function input_signals()
        return local_signals
    end
    
    -- Provided parameters are used for populating metadata when listing signals.
    function output_signals()
        return "EV_BATTERY_LEVEL"
    end
    
    -- Required, declare what frequence you like to get "timer" invoked. 0 means no calls to "timer".
    function timer_frequency_hz()
        return local_frequecy_hz
    end
    
    -- Invoked with the frequecy returned by "timer_frequency_hz".
    -- @param system_timestamp_us: system time stamp 
    function timer(system_timestamp_us)
        return return_value_or_bytes("your value")
    end
    
    -- Invoked when ANY signal declared in "local_signals" arrive
    -- @param signals_timestamp_us: signal time stamp
    -- @param system_timestamp_us
    -- @param signals: array of signals containing all or a subset of signals declared in "local_signals". Make sure to nil check before use.
    function signals(signals, namespace, signals_timestamp_us, system_timestamp_us)
        return return_value_or_bytes(signals["ID352BMS_energyStatus.BMS_nominalEnergyRemaining"])
    end
    
    -- helper return function, make sure to use return_value_or_bytes or return_nothing.
    function return_value_or_bytes(value_or_bytes)
        return value_or_bytes
    end
    
    -- helper return function, make sure to use return_value_or_bytes or return_nothing.
    function return_nothing()
        return
    end
    `
}

export const aaosHeading = (): string => {
    return `local local_signals = {{
        name = "ID2D3UI_solarData.UI_solarAzimuthAngleCarRef",
        namespace = "ChassisBus"
    }, {
        name = "ID2D3UI_solarData.UI_solarAzimuthAngle",
        namespace = "ChassisBus"
    }}
    local local_frequecy_hz = 0
    
    -- Required, declare which input is needed to operate this program.
    function input_signals()
        return local_signals
    end
    
    -- Provided parameters are used for populating metadata when listing signals.
    function output_signals()
        return "HEADING"
    end
    
    -- Required, declare what frequence you like to get "timer" invoked. 0 means no calls to "timer".
    function timer_frequency_hz()
        return local_frequecy_hz
    end
    
    -- Invoked with the frequecy returned by "timer_frequency_hz".
    -- @param system_timestamp_us: system time stamp 
    function timer(system_timestamp_us)
        return return_value_or_bytes("your value")
    end
    
    -- Invoked when ANY signal declared in "local_signals" arrive
    -- @param signals_timestamp_us: signal time stamp
    -- @param system_timestamp_us
    -- @param signals: array of signals containing all or a subset of signals declared in "local_signals". Make sure to nil check before use.
    function signals(signals, namespace, signals_timestamp_us, system_timestamp_us)
        return return_value_or_bytes(math.fmod((360 + signals["ID2D3UI_solarData.UI_solarAzimuthAngle"] - signals["ID2D3UI_solarData.UI_solarAzimuthAngleCarRef"]), 360))
    end
    
    -- helper return function, make sure to use return_value_or_bytes or return_nothing.
    function return_value_or_bytes(value_or_bytes)
        return value_or_bytes
    end
    
    -- helper return function, make sure to use return_value_or_bytes or return_nothing.
    function return_nothing()
        return
    end
    `
}

export const aaosInfoEvBatteryCapacity = (): string => {
    return `local local_signals = {{
        name = "ID352BMS_energyStatus.BMS_nominalFullPackEnergy",
        namespace = "VehicleBus"
    }, {
        name = "ID352BMS_energyStatus.BMS_nominalEnergyRemaining",
        namespace = "VehicleBus"
    }}
    local local_frequecy_hz = 0
    
    -- Required, declare which input is needed to operate this program.
    function input_signals()
        return local_signals
    end
    
    -- Provided parameters are used for populating metadata when listing signals.
    function output_signals()
        return "INFO_EV_BATTERY_CAPACITY"
    end
    
    -- Required, declare what frequence you like to get "timer" invoked. 0 means no calls to "timer".
    function timer_frequency_hz()
        return local_frequecy_hz
    end
    
    -- Invoked with the frequecy returned by "timer_frequency_hz".
    -- @param system_timestamp_us: system time stamp 
    function timer(system_timestamp_us)
        return return_value_or_bytes("your value")
    end
    
    -- Invoked when ANY signal declared in "local_signals" arrive
    -- @param signals_timestamp_us: signal time stamp
    -- @param system_timestamp_us
    -- @param signals: array of signals containing all or a subset of signals declared in "local_signals". Make sure to nil check before use.
    function signals(signals, namespace, signals_timestamp_us, system_timestamp_us)
        return return_value_or_bytes(signals["ID352BMS_energyStatus.BMS_nominalFullPackEnergy"])
    end
    
    -- helper return function, make sure to use return_value_or_bytes or return_nothing.
    function return_value_or_bytes(value_or_bytes)
        return value_or_bytes
    end
    
    -- helper return function, make sure to use return_value_or_bytes or return_nothing.
    function return_nothing()
        return
    end
    `
}

export const aaosPerfVehicleSpeed = (): string => {
    return `local local_signals = {{
        name = "ID257DIspeed.DI_vehicleSpeed",
        namespace = "VehicleBus"
    }}
    local local_frequecy_hz = 0
    
    -- Required, declare which input is needed to operate this program.
    function input_signals()
        return local_signals
    end
    
    -- Provided parameters are used for populating metadata when listing signals.
    function output_signals()
        return "PERF_VEHICLE_SPEED"
    end
    
    -- Required, declare what frequence you like to get "timer" invoked. 0 means no calls to "timer".
    function timer_frequency_hz()
        return local_frequecy_hz
    end
    
    -- Invoked with the frequecy returned by "timer_frequency_hz".
    -- @param system_timestamp_us: system time stamp 
    function timer(system_timestamp_us)
        return return_value_or_bytes("your value")
    end
    
    -- Invoked when ANY signal declared in "local_signals" arrive
    -- @param signals_timestamp_us: signal time stamp
    -- @param system_timestamp_us
    -- @param signals: array of signals containing all or a subset of signals declared in "local_signals". Make sure to nil check before use.
    function signals(signals, namespace, signals_timestamp_us, system_timestamp_us)
        return return_value_or_bytes(signals["ID257DIspeed.DI_vehicleSpeed"]/3.6)
    end
    
    -- helper return function, make sure to use return_value_or_bytes or return_nothing.
    function return_value_or_bytes(value_or_bytes)
        return value_or_bytes
    end
    
    -- helper return function, make sure to use return_value_or_bytes or return_nothing.
    function return_nothing()
        return
    end
    `
}


// Simple mappings

export const aaosPerfSteeringAngle = (): string => {
    return `"PERF_STEERING_ANGLE": {
        "simple_mapping": {
           "namespace": "VehicleBus",
           "name": "ID129SteeringAngle.SteeringAngle129"
        }
     }
`
}

export const aaosLongitude = (): string => {
    return `"LONGITUDE": {
        "simple_mapping": {
           "namespace": "ChassisBus",
           "name": "ID04FGPSLatLong.GPSLongitude04F"
        }
     }
`
}

export const aaosLatitude = (): string => {
    return `"LATITUDE": {
        "simple_mapping": {
           "namespace": "ChassisBus",
           "name": "ID04FGPSLatLong.GPSLatitude04F"
        }
     }
`
}

export const aaosHorizontalAccuracy = (): string => {
    return `"HORIZONTAL_ACCURACY": {
        "simple_mapping": {
           "namespace": "ChassisBus",
           "name": "ID04FGPSLatLong.GPSAccuracy04F"
        }
     }
`
}
