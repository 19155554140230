export default function DisconnectedBrokerText() {
    const text = () => {
        return (
            <>
                <div className="d-flex flex-column my-5">
                    <p className="m-0 fs-4">
                        <span className="remotive-primary-50-color lexend-bold">RemotiveBroker</span> appears to be
                        disconnected
                    </p>
                    <p className="m-0 remotive-small">
                        Reconnect your <span className="remotive-primary-50-color lexend-bold">RemotiveBroker</span> to
                        resume, it can take up to <span className="lexend-bold">10 seconds</span> to detect a{' '}
                        <span className="remotive-primary-50-color lexend-bold">RemotiveBroker</span> connection.
                    </p>
                </div>
            </>
        )
    }

    return text()
}
