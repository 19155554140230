import { ContentCopyRounded } from '@mui/icons-material'

export type CliHint = {
    title: string
    subtitle?: string
    command: string
}

interface CliHintContainerProperties {
    hints: Array<CliHint>
}

export default function CliHintContainer(props: CliHintContainerProperties) {
    const hint = (hint: CliHint) => {
        return (
            <div className={'text-start mt-2 mb-0 pb-1'} key={hint.command}>
                <p className={'remotive-font-md p-0 m-0'}>{hint.title}</p>
                {hint.subtitle && <p className={'remotive-font-sm text-secondary p-0 m-0'}>{hint.subtitle}</p>}
                <pre className={'m-1 ms-0 p-1 text-wrap remotive-font-sm'}>
                    {hint.command}{' '}
                    <button
                        className="remotive-btn-sm remotive-btn-no-bg border-0"
                        onClick={() => navigator.clipboard.writeText(hint.command)}
                    >
                        <ContentCopyRounded sx={{ fontSize: '1.1em' }} />
                    </button>
                </pre>
            </div>
        )
    }

    const getBody = () => {
        return (
            <div className={'flex-box justify-content-end w-100'}>
                <div className={'flex-box align-content-start p-1 m-1'}>
                    <>{props.hints.map(hint)}</>
                </div>
            </div>
        )
    }

    return <div className="mt-2">{getBody()}</div>
}
