import BrokerApi from '../../api/BrokerApi'
import { CreateRecordingSession, RecordingState } from '../../api/BrokerApi/types'

export class FramesDistributionDelegate {
    private _brokerGrpcClient: BrokerApi

    constructor(brokerGrpcClient: BrokerApi) {
        this._brokerGrpcClient = brokerGrpcClient
    }

    subscribeToFrameDistribution = async (
        namespace: string,
        onDataCallback: (data: any, receivedTimestampMs: number) => void,
        onEndCallback: () => void
    ) => {
        console.log(`Subscribe to frame frequency distribution on namespace: ${namespace}`)
        this._brokerGrpcClient.subscribeToFrameDistribution(namespace, onDataCallback, onEndCallback)
    }
}
