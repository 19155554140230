import { AuthenticatedUser } from '../api/CloudApi/types'
import { AnalyticsBrowser } from '@segment/analytics-next'
import { isDemo } from './CloudDetails'
import { CloudContext } from '../services/LicenseService'

export interface ProductAnalyticsProps {
    user: AuthenticatedUser | undefined
    cloudContext: CloudContext | undefined
}

const analytics = AnalyticsBrowser.load(
    {
        writeKey: 'Hbf4q21GqpXq74emHsbkz4j4jFW5zrtP',
        cdnURL: 'https://pa-cdn.remotivelabs.com',
    },
    {
        integrations: {
            'Segment.io': {
                apiHost: 'pa.remotivelabs.com/v1',
                protocol: 'https',
            },
        },
    }
)

export interface ProductAnalyticsTracker {
    track(event: string, properties?: Object, options?: SegmentAnalytics.SegmentOpts, callback?: () => void): void
}

export class ProductAnalytics {
    cloudContext?: CloudContext

    identitySent = false

    constructor(cloudContext?: CloudContext) {
        this.cloudContext = cloudContext
    }

    /**
     * Convenience wrapper around Segment Analytics.js
     *
     * For more information on how to extend it visit:
     * https://segment.com/docs/connections/sources/catalog/libraries/website/javascript/quickstart/
     */

    getEnvironment() {
        if (isDemo()) {
            return 'demo'
        }
        if (window.location.host.endsWith('cloud-dev.remotivelabs.com')) {
            return 'dev'
        }
        if (window.location.host.endsWith('cloud.remotivelabs.com')) {
            return 'prod'
        }
        return undefined
    }

    getProps() {
        return {
            app: 'Remotive Cloud Console',
            environment: this.getEnvironment(),
            organisation_id: this.cloudContext?.organisation,
        }
    }

    public track(event: string, properties?: Object, options?: SegmentAnalytics.SegmentOpts, callback?: () => void) {
        const props = this.getProps()
        const merged = {
            ...props,
            ...(properties ? properties : {}),
        }
        if (this.getEnvironment() !== undefined) {
            analytics.track(event, merged, options, callback)
        }
    }

    public identify(
        user: AuthenticatedUser,
        properties?: Object,
        options?: SegmentAnalytics.SegmentOpts,
        callback?: () => void
    ) {
        // No need to send identify if we are in demo
        if (this.getEnvironment() && !this.identitySent && !isDemo()) {
            analytics.identify(user.uid, properties, options, callback)
        }
        this.identitySent = true
    }

    public page(name?: string, properties?: Object, options?: SegmentAnalytics.SegmentOpts, callback?: () => void) {
        const props = this.getProps()
        const merged = {
            ...props,
            ...(properties ? properties : {}),
        }
        if (this.getEnvironment() !== undefined) {
            // @ts-ignore
            analytics.page(name, merged, options, callback)
        }
    }
}
