import { useEffect, useState } from 'react'
import { Modal } from 'react-bootstrap'
import { ConnectionState, requestLicense } from '../../../services/LicenseService'
import ConnectedBrokerFlow from './ConnectedBrokerFlow'
import DisconnectedBrokerFlow from './DisconnectedBrokerFlow'
import { LicenseInfo } from 'remotivelabs-grpc-web-stubs'
import { toast } from 'react-toastify'
import { formattedToastMessage } from '../../../utils/toast'

interface RequestALicenseModalProps {
    show: boolean
    handleCloseFunction: () => void
    goToApplyLicenseFunction: (specifiedEmail: string) => void
    connectionState: ConnectionState
    license: LicenseInfo | undefined
}

export default function RequestALicenseModal(props: RequestALicenseModalProps) {
    const [specifiedEmail, setSpecifiedEmail] = useState<string>('')

    useEffect(() => {
        console.log('Mounting RequestALicenseModal modal')
    }, [])

    const doRequestLicense = async (license: LicenseInfo): Promise<void> => {
        if (specifiedEmail === '') {
            toast.error(
                formattedToastMessage(
                    'License error',
                    'You must enter en email address to be able to receive a license...'
                ),
                { autoClose: 25_000 }
            )
        } else {
            try {
                await requestLicense(specifiedEmail, license)
                // No error, license exists
            } catch (error: any) {
                throw error
            }
        }
    }

    const close = () => {
        props.handleCloseFunction()
    }

    const isConnectedToBothBrokerAndInternet = () => {
        return props.connectionState.clientHasInternet && props.connectionState.clientIsConnectedToBroker
    }

    const sendLicenseEmailAndUpdateComponentState = async (
        setCurrentStepFunction: () => void,
        setIsSendingEmailFunction: () => void
    ) => {
        try {
            await doRequestLicense(props.license!!)
            setCurrentStepFunction()
        } catch (e: any) {
            if (e.message === 'Not accepted: policy expired') {
                toast.error(
                    formattedToastMessage(
                        'License error',
                        'Failed to issue a new license, previous license has expired.'
                    )
                )
            } else {
                toast.error(formattedToastMessage('License error', 'Failed to issue a license due to an unknown issue'))
            }
        }
        setIsSendingEmailFunction()
    }

    return (
        <>
            <Modal show={props.show} backdrop="static" keyboard={false} onHide={close} size="lg" className="rounded-5">
                <Modal.Header className="remotive-primary-70-background">
                    <Modal.Title className="lexend-regular text-light">Request a license</Modal.Title>
                </Modal.Header>
                {isConnectedToBothBrokerAndInternet() ? (
                    <ConnectedBrokerFlow
                        goToApplyLicenseFunction={props.goToApplyLicenseFunction}
                        handleCloseFunction={props.handleCloseFunction}
                        connectionState={props.connectionState}
                        sendLicenseEmailAndUpdateComponentState={sendLicenseEmailAndUpdateComponentState}
                        specifiedEmail={specifiedEmail}
                        setSpecifiedEmail={setSpecifiedEmail}
                    />
                ) : (
                    <DisconnectedBrokerFlow
                        goToApplyLicenseFunction={props.goToApplyLicenseFunction}
                        handleCloseFunction={props.handleCloseFunction}
                        connectionState={props.connectionState}
                        specifiedEmail={specifiedEmail}
                        setSpecifiedEmail={setSpecifiedEmail}
                        sendLicenseEmailAndUpdateComponentState={sendLicenseEmailAndUpdateComponentState}
                    />
                )}
            </Modal>
        </>
    )
}
