import { NameSpace, SignalId } from 'remotivelabs-grpc-web-stubs'
import BrokerApi from '../../api/BrokerApi'
import { Frame, SignalType, SignalValue } from '../../api/BrokerApi/types'
import { SubscriptionId } from '../../types/SubscriptionId'

export class SignalDelegate {
    private _brokerGrpcClient: BrokerApi

    constructor(brokerGrpcClient: BrokerApi) {
        this._brokerGrpcClient = brokerGrpcClient
    }

    listSignals = async (): Promise<Array<Frame>> => {
        const namespaces = await this._brokerGrpcClient.getNamespaces()
        const asyncFrames: Array<Promise<Frame[]>> = namespaces.map((namespace) => {
            return this._brokerGrpcClient.listSignals(namespace.name)
        })
        const frames = await Promise.all(asyncFrames)
        return frames.flat()
    }

    dropIncomingSignals = (drop: boolean) => {
        this._brokerGrpcClient.dropSignals(drop)
    }

    addPendingSubscription = (
        name: string,
        namespace: string,
        onSignalCallback: (signalValue: SignalValue, signalType: SignalType) => void,
        onEndCallback?: () => void
    ): SubscriptionId => {
        console.log(`Adding pending subscription to ${name} in namespace ${namespace}`)
        const signalId = this._createSignalId(name, namespace)
        const subscription = this._brokerGrpcClient.addPendingSubscription(signalId, onSignalCallback, onEndCallback)
        return { id: subscription } as SubscriptionId
    }

    applyPendingSubscriptions = () => {
        return this._brokerGrpcClient.applyPendingSubscriptions()
    }

    listSubscriptions = () => {
        return this._brokerGrpcClient.listSubscriptions()
    }

    removeSubscription = (subscriptionId: SubscriptionId | undefined) => {
        if (subscriptionId !== undefined) {
            console.debug(`Removing subscriptionId=${subscriptionId.id}`)
            this._brokerGrpcClient.removeSubscription(subscriptionId.id)
        }
    }

    _createSignalId = (name: string, namespace: string): SignalId => {
        const signalId = new SignalId()
        const signalNamespace = new NameSpace()

        signalNamespace.setName(namespace)
        signalId.setName(name)
        signalId.setNamespace(signalNamespace)
        return signalId
    }
}
