import { Card } from 'react-bootstrap'

interface PageCardProps {
    body: React.ReactNode
}

export default function PageCard(props: PageCardProps) {
    return (
        <div className={'row mb-4'}>
            <div className="col-12 p-0">
                <Card
                    className="shadow-sm rounded-4 bg-white border-0"
                    style={{
                        minHeight: '110px',
                        backgroundColor: 'transparent',
                    }}
                >
                    <Card.Body className="pb-0 pt-2">{props.body}</Card.Body>
                </Card>
            </div>
        </div>
    )
}
