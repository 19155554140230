import BrokerApi from '../../api/BrokerApi'

export class ConfigurationDelegate {
    private _brokerGrpcClient: BrokerApi

    constructor(brokerGrpcClient: BrokerApi) {
        this._brokerGrpcClient = brokerGrpcClient
    }

    reloadConfiguration = async () => {
        return await this._brokerGrpcClient.reloadConfiguration()
    }

    getConfiguration = async () => {
        return await this._brokerGrpcClient.getConfiguration()
    }

    getInterfacesJsonAsString = async () => {
        return await this._brokerGrpcClient.getInterfacesJsonAsString()
    }
}
