import BrokerApi from '../../api/BrokerApi'
import { Namespace } from '../../api/BrokerApi/types'

export class NamespaceDelegate {
    private _brokerGrpcClient: BrokerApi

    constructor(brokerGrpcClient: BrokerApi) {
        this._brokerGrpcClient = brokerGrpcClient
    }

    getPlayableNamespaces = async (): Promise<Array<Namespace>> => {
        const config = await this.getNamespaces()
        return config
            .map((namespace) => {
                if (namespace.type === 'mapped' || namespace.type === 'virtual') {
                    return undefined
                }
                return namespace
            })
            .filter((namespace) => namespace !== undefined) as Array<Namespace> // Filter out an undefined objects and cast to namespace to satisfy return type check
    }

    getNamespaces = async (): Promise<Array<Namespace>> => {
        return this._brokerGrpcClient.getNamespaces()
    }
}
