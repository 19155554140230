import { useState, useEffect, useRef } from 'react'
import { Modal, InputGroup, Form } from 'react-bootstrap'
import CodeIcon from '@mui/icons-material/CodeRounded'

import { Frame, Signal } from '../../api/BrokerApi/types'
import { isFrameIdentical, isSignalChildOfFrame, isSignalIdentical } from '../../utils/FilterUtilities'
import { FrameFilter, PanelFilter, SignalFilter } from '../../types/Filters'
import { fetchNamespaces, isMappedConfiguration, viewSignalSource } from '../../services/ConfigurationService'
import { isIframe } from '../../utils/CloudDetails'

interface PanelFilterModalProps {
    show: boolean
    allFrames: Array<Frame>
    selectedPanelFilters: Array<PanelFilter>
    handleCloseFunction: () => void
    setPanelFiltersFunction: (panelFilters: Array<PanelFilter>) => void
}

// Using bootstrap column system, total should be 12
const FRAME_AND_SIGNAL_COL_WIDTH = 8
const NAMESPACE_COL_WIDTH = 4

export default function PanelFilterModal(props: PanelFilterModalProps) {
    const [selectedFrameFilters, setSelectedFrameFilters] = useState<Array<FrameFilter>>([])
    const [selectedSignalFilters, setSelectedSignalFilters] = useState<Array<SignalFilter>>([])
    const [filteredFrames, setFilteredFrames] = useState<Array<Frame>>([])
    const [namespaceToHasMappedConfigMap, setNamespaceToHasMappedConfigMap] = useState(new Map())

    useEffect(() => {
        console.log('Mounting select signal modal!')

        const setInitialState = async () => {
            const namespaces = await fetchNamespaces()
            namespaces.map(async (namespace) => {
                const isMapped = await isMappedConfiguration(namespace.name)
                setNamespaceToHasMappedConfigMap(namespaceToHasMappedConfigMap.set(namespace.name, isMapped))
            })
        }

        setInitialState().catch((e: any) => {
            console.warn(`Failed to fetch namespaces for PanelFilterModal. Error: \n${JSON.stringify(e)}`)
        })
    }, [])

    useEffect(() => {
        setFilteredFrames(props.allFrames)
    }, [props.allFrames])

    useEffect(() => {
        if (!props.show) {
            const frameFilters: Array<FrameFilter> = []
            const signalFilters: Array<SignalFilter> = []
            props.selectedPanelFilters.forEach((panelFilter) => {
                frameFilters.push(panelFilter.frameFilter)
                signalFilters.push(...panelFilter.signalFilters)
            })
            setSelectedFrameFilters(frameFilters)
            setSelectedSignalFilters(signalFilters)
        }
    }, [props.selectedPanelFilters])

    useEffect(() => {
        const duplicateHelper = new Set()
        const selectedSignalFiltersWithoutParent = selectedSignalFilters
            .filter(
                (selectedSignalFilter) =>
                    selectedFrameFilters.find((frameFilter) =>
                        isFrameIdentical(frameFilter, selectedSignalFilter.parentFrame)
                    ) === undefined
            )
            .filter((selectedSignalFilter) => {
                const key = `${selectedSignalFilter.parentFrame.namespace}::${selectedSignalFilter.parentFrame.frameName}`
                const isDuplicate = !duplicateHelper.has(key)
                duplicateHelper.add(key)
                return isDuplicate
            })
        if (selectedSignalFiltersWithoutParent.length > 0) {
            setSelectedFrameFilters([
                ...selectedFrameFilters,
                ...selectedSignalFiltersWithoutParent.map((selectedSignalFilter) => selectedSignalFilter.parentFrame),
            ])
        }
    }, [selectedSignalFilters, selectedFrameFilters])

    const searchOnChange = (event: any) => {
        const searchKey = new String(event.target.value).toLowerCase()
        if (searchKey.length >= 3) {
            const filteredFrames: Array<Frame> = props.allFrames
                .map((frame) => {
                    if (
                        frame.namespace.toLowerCase().startsWith(searchKey) ||
                        frame.name.toLowerCase().includes(searchKey)
                    ) {
                        // If the frames namespaces starts with search key, return frame + all signals
                        // OR
                        // // If frame name contains search key, return frame + all signals
                        return new Frame(frame.name, frame.namespace, frame.metadata, frame.signals)
                    } else {
                        // If frame doesn't contain search key, filter containing signals on search key and then return frame + signals
                        const signalsAfterNameSearch = frame.signals.filter((signal) =>
                            signal.name.toLowerCase().includes(searchKey)
                        )
                        if (signalsAfterNameSearch.length > 0) {
                            return new Frame(frame.name, frame.namespace, frame.metadata, signalsAfterNameSearch)
                        }

                        // If signal doesn't contain the searh key, check description
                        const signalsAfterDescriptionSearch = frame.signals.filter((signal) => {
                            return signal.metadata?.getDescription().toLowerCase().includes(searchKey)
                        })
                        if (signalsAfterDescriptionSearch.length > 0) {
                            return new Frame(frame.name, frame.namespace, frame.metadata, signalsAfterDescriptionSearch)
                        }
                    }
                    // No match
                    return undefined
                })
                .filter((frame) => frame !== undefined) as Array<Frame>
            setFilteredFrames(filteredFrames)
        }
        if (searchKey.length === 0) {
            setFilteredFrames(props.allFrames)
        }
    }

    const selectSignalOnChange = (event: any) => {
        const targetValue = event.target.value
        const targetedSignalFilter: SignalFilter = JSON.parse(targetValue)
        if (event.target.checked) {
            setSelectedSignalFilters([...selectedSignalFilters, targetedSignalFilter])
        } else {
            setSelectedSignalFilters(
                selectedSignalFilters.filter((signalFilter) => !isSignalIdentical(signalFilter, targetedSignalFilter))
            )
        }
    }

    const selectFrameOnChange = (event: any) => {
        const targetValue = event.target.value
        const targetedFrameFilter: FrameFilter = JSON.parse(targetValue)
        if (event.target.checked) {
            setSelectedFrameFilters([...selectedFrameFilters, targetedFrameFilter])
        } else {
            setSelectedFrameFilters(
                selectedFrameFilters.filter((frameFilter) => !isFrameIdentical(frameFilter, targetedFrameFilter))
            )
        }
    }

    const createFilteredSignal = (frameFilter: FrameFilter, signal: Signal) => {
        const max = signal.metadata?.getMax()
        const min = signal.metadata?.getMin()
        const signalFilter = {
            parentFrame: frameFilter,
            signalName: signal.name,
            maxValue: max,
            minValue: min,
        } as SignalFilter
        const isChecked =
            selectedSignalFilters.filter((selectedSignalFilter) =>
                isSignalIdentical(signalFilter, selectedSignalFilter)
            ).length > 0
        const jsonifiedSignalFilter = JSON.stringify(signalFilter)
        return (
            <div
                key={`${frameFilter.frameName}::${frameFilter.namespace}::${signal.name}`}
                className="rounded ms-3 mt-1 remotive-primary-10-background"
            >
                <div className="row text-break align-middle p-0 m-0">
                    <div className={`col-${FRAME_AND_SIGNAL_COL_WIDTH} d-flex`}>
                        <Form.Check
                            type={'checkbox'}
                            id={jsonifiedSignalFilter}
                            value={jsonifiedSignalFilter}
                            checked={isChecked}
                            onChange={selectSignalOnChange}
                        />
                        <label className="ms-2" htmlFor={jsonifiedSignalFilter}>
                            {signal.name}
                        </label>
                    </div>
                    <div className={`col-${NAMESPACE_COL_WIDTH} remotive-small`}>
                        <p className="remotive-primary-40-color m-0">{`${frameFilter.namespace}`}</p>
                    </div>
                    <div className="col-12">
                        {signal.metadata?.getDescription() !== '' && (
                            <p className="remotive-small remotive-neutral-70-color mt-0 pb-0 mb-0 ms-4">
                                {signal.metadata?.getDescription() || 'Default description'}
                            </p>
                        )}
                    </div>
                </div>
            </div>
        )
    }

    const createFilteredFrame = (frame: Frame) => {
        const frameFilter = {
            frameName: frame.name,
            namespace: frame.namespace,
        } as FrameFilter
        const isChecked =
            selectedFrameFilters.filter((selectedFrameFilter) => isFrameIdentical(selectedFrameFilter, frameFilter))
                .length > 0
        const isDisabled =
            selectedSignalFilters.filter((signalFilter) => isSignalChildOfFrame(frameFilter, signalFilter)).length > 0
        const jsonifiedFrame = JSON.stringify(frameFilter)
        return (
            <div
                key={`${frame.name}::${frame.namespace}::Element`}
                className="rounded mt-1 remotive-primary-10-background"
            >
                <div className="row text-break align-middle p-2 mb-1 m-0">
                    <div className={`col-${FRAME_AND_SIGNAL_COL_WIDTH} lexend-bold d-flex`}>
                        <Form.Check
                            type={'checkbox'}
                            id={JSON.stringify(frameFilter)}
                            value={JSON.stringify(frameFilter)}
                            disabled={isDisabled}
                            checked={isChecked}
                            onChange={selectFrameOnChange}
                        />
                        <label className="ms-2" htmlFor={jsonifiedFrame}>
                            {frame.name}
                        </label>
                    </div>
                    <div className={`col-${NAMESPACE_COL_WIDTH} remotive-small`}>
                        <p className="remotive-primary-40-color m-0">{`${frame.namespace}`}</p>
                    </div>
                    <div className="col-12">
                        {frame.metadata?.getDescription() !== '' && (
                            <p className="remotive-small remotive-neutral-70-color mt-0 pb-0 mb-0 ms-4">
                                {frame.metadata?.getDescription() || 'Default description'}
                            </p>
                        )}
                    </div>
                    <div className="col-12">
                        {frame.signals.map((signal) => createFilteredSignal(frameFilter, signal))}
                    </div>
                </div>
            </div>
        )
    }

    const createFilteredFrameList = () => {
        return filteredFrames.map((frame) => {
            return (
                <div key={`${frame.name}::${frame.namespace}`}>
                    <div className="ms-2">{createFilteredFrame(frame)}</div>
                </div>
            )
        })
    }

    const closeModal = () => {
        props.handleCloseFunction()
        setFilteredFrames(props.allFrames)
        setSelectedFrameFilters([])
        setSelectedSignalFilters([])
    }

    const createPanels = () => {
        const panelFilters = selectedFrameFilters.map((frameFilter) => {
            const signalFilters = selectedSignalFilters.filter((signalFilter) =>
                isSignalChildOfFrame(frameFilter, signalFilter)
            )
            return { frameFilter, signalFilters } as PanelFilter
        })
        console.log(`Creating panels from ${JSON.stringify(panelFilters)}`)
        props.setPanelFiltersFunction(panelFilters)
        closeModal()
    }

    return (
        <>
            <Modal size="lg" className="col-12" show={props.show} onHide={closeModal}>
                {!isIframe() && (
                    <Modal.Header closeVariant="white" className="remotive-primary-70-background" closeButton>
                        <Modal.Title className="lexend-regular text-light">Select signals to visualize</Modal.Title>
                    </Modal.Header>
                )}
                <Modal.Body className="pb-2">
                    <InputGroup size="sm" className="mb-2">
                        <Form.Control
                            autoFocus={props.show}
                            placeholder="Signal/frame name..."
                            aria-label="Small"
                            aria-describedby="inputGroup-sizing-sm"
                            onChange={searchOnChange}
                        />
                    </InputGroup>
                    <Form>
                        <div>
                            <div className="row text-break text-start ms-2">
                                <div className={`col-${FRAME_AND_SIGNAL_COL_WIDTH} px-0 lexend-bold d-none d-md-block`}>
                                    Signal & frame
                                </div>
                                <div className={`col-${NAMESPACE_COL_WIDTH} px-0 lexend-bold d-none d-md-block`}>
                                    Namespace
                                </div>
                                <div
                                    className={`col-${FRAME_AND_SIGNAL_COL_WIDTH} px-0 lexend-bold remotive-small d-block d-md-none`}
                                >
                                    Signal/frame
                                </div>
                                <div
                                    className={`col-${NAMESPACE_COL_WIDTH} px-0 lexend-bold remotive-small d-block d-md-none`}
                                >
                                    Namespace
                                </div>
                            </div>
                            <div style={{ overflowY: 'scroll', height: isIframe() ? '60vh' : '60vh', maxHeight: 400 }}>
                                {createFilteredFrameList()}
                            </div>
                        </div>
                    </Form>
                </Modal.Body>
                <Modal.Footer className="border-0 pt-0 pb-2">
                    <button className="btn remotive-btn-md remotive-btn-primary" onClick={closeModal}>
                        Cancel
                    </button>
                    <button className="btn remotive-btn-md remotive-btn-success" onClick={createPanels}>
                        Apply
                    </button>
                </Modal.Footer>
            </Modal>
        </>
    )
}
