import { Container } from 'react-bootstrap'

import { CloudContext, ConnectionState } from '../services/LicenseService'
import { LicenseInfo } from 'remotivelabs-grpc-web-stubs'
import AboutContainer from './About/AboutContainer'
import LicenseFlowContainer from '../components/LicenseFlowContainer'
import PageCard from '../components/cards/PageCard'
import BrokerInformationFooter from '../components/BrokerInformationFooter'

interface AboutProps {
    isFluidDesign: boolean
    isCloudBroker: boolean
    setLicenseFunction: (license: LicenseInfo) => void
    connectionState: ConnectionState
    isAppInitialized: boolean
    cloudContext: CloudContext | undefined
    license: LicenseInfo | undefined
    isLicensed: boolean
}

export default function About(props: AboutProps) {
    const cardContent = () => {
        if (!props.isLicensed) {
            return (
                <LicenseFlowContainer
                    setLicenseFunction={props.setLicenseFunction}
                    isLicensed={props.isLicensed}
                    license={props.license}
                    connectionState={props.connectionState}
                />
            )
        }
        return (
            <AboutContainer
                isCloudBroker={props.isCloudBroker}
                setLicenseFunction={props.setLicenseFunction}
                connectionState={props.connectionState}
                isLicensed={props.isLicensed}
            />
        )
    }

    const containerContent = () => {
        return (
            <>
                <Container fluid={props.isFluidDesign} className={`${props.isFluidDesign ? 'px-4' : ''}`}>
                    <PageCard body={cardContent()} />
                </Container>

                {/* Sticky broker info */}
                <BrokerInformationFooter
                    isLicensed={props.isLicensed}
                    licenseInfo={props.license}
                    isAppInitialized={props.isAppInitialized}
                    isCloudBroker={props.isCloudBroker}
                    connectionState={props.connectionState}
                    cloudContext={props.cloudContext}
                />
            </>
        )
    }

    return <>{props.isAppInitialized && containerContent()}</>
}
