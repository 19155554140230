import { Alert, Form } from 'react-bootstrap'
import { ConnectionState } from '../../services/LicenseService'
import { WarningRounded } from '@mui/icons-material'

interface LicensePhraseOptionProps {
    connectionState: ConnectionState
    licenseRequestEmail: string
    isLicenseRequestEmailValid: boolean
    setLicenseRequestEmail: (email: string) => void
    licensePhrase: string
    isLicensePhraseValid: boolean
    setLicesePhrase: (phrase: string) => void
    licenseFileToUpload: File | undefined
    termsAccepted: boolean
    isEmailSetFromRequestALicenseFlow: boolean
}

export default function LicensePhraseOption(props: LicensePhraseOptionProps) {
    const licensePhraseOption = () => {
        const isAllInputValid = props.isLicenseRequestEmailValid && props.isLicensePhraseValid
        const clientHasInternetAndBrokerConnection =
            props.connectionState.clientHasInternet && props.connectionState.clientIsConnectedToBroker
        const isPhraseInputInvalid = props.licensePhrase !== '' && !props.isLicensePhraseValid
        const isEmailInputInvalid = props.licenseRequestEmail !== '' && !props.isLicenseRequestEmailValid
        return (
            <>
                <div
                    className={`rounded-4 ${
                        !clientHasInternetAndBrokerConnection || props.licenseFileToUpload !== undefined
                            ? 'remotive-neutral-40-background'
                            : isAllInputValid
                            ? 'remotive-success-20-background'
                            : 'remotive-primary-10-background'
                    } p-4`}
                    style={{ minHeight: 300 }}
                >
                    <p className="m-0 fs-5">
                        I have a license <span className="lexend-bold">phrase</span>
                    </p>
                    <p className="mb-3 remotive-font-sm text-secondary">
                        The license phrase is a short code that is found in the license email. Below you can pair the
                        license phrase together with the email address it was sent to and apply it to the broker.
                    </p>
                    {!clientHasInternetAndBrokerConnection && (
                        <div className="d-flex justify-content-center">
                            <Alert
                                variant="warning"
                                className="p-2 remotive-warning-10-background"
                                style={{ maxWidth: 420 }}
                            >
                                <div className="d-flex align-items-center">
                                    <WarningRounded className="me-3 text-warning" />
                                    <p className="m-0 remotive-font-md">
                                        You can not use the license <b>phrase</b> option without an{' '}
                                        <span className="remotive-success-70-color lexend-bold">
                                            internet connection
                                        </span>
                                        , please use the license <b>file</b> option instead.
                                    </p>
                                </div>
                            </Alert>
                        </div>
                    )}
                    <fieldset
                        disabled={!clientHasInternetAndBrokerConnection || props.licenseFileToUpload !== undefined}
                    >
                        <Form noValidate={true}>
                            <Form.Label className={'remotive-font-xs m-0'}>License phrase</Form.Label>
                            <Form.Control
                                type="text"
                                size={'sm'}
                                id="license-phrase"
                                defaultValue={props.licensePhrase}
                                isValid={props.isLicensePhraseValid}
                                placeholder={'Enter license phrase found in email'}
                                isInvalid={isPhraseInputInvalid}
                                onChange={(event: any) => props.setLicesePhrase(event.target.value)}
                            />
                            {isPhraseInputInvalid && (
                                <Form.Control.Feedback id="license-phrase" type="invalid" className="remotive-font-sm">
                                    You need to enter a license phrase in a valid format.
                                </Form.Control.Feedback>
                            )}
                            <Form.Label className={'remotive-font-xs m-0'}>Email</Form.Label>
                            <Form.Control
                                className={props.isEmailSetFromRequestALicenseFlow ? 'bg-secondary-subtle' : ''}
                                size={'sm'}
                                type="email"
                                id="license-request-email"
                                placeholder="E.g johndoe@example.com"
                                value={props.licenseRequestEmail}
                                isValid={props.isLicenseRequestEmailValid}
                                isInvalid={isEmailInputInvalid}
                                onChange={(event: any) => props.setLicenseRequestEmail(event.target.value)}
                            />
                            {isEmailInputInvalid && (
                                <Form.Control.Feedback
                                    id="license-request-email"
                                    type="invalid"
                                    className="remotive-font-sm"
                                >
                                    You need to enter a valid email address
                                </Form.Control.Feedback>
                            )}
                        </Form>
                    </fieldset>
                </div>
            </>
        )
    }

    return licensePhraseOption()
}
