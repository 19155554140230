import { Container } from 'react-bootstrap'
import { CloudContext, ConnectionState } from '../services/LicenseService'
import { LicenseInfo } from 'remotivelabs-grpc-web-stubs'
import LicenseFlowContainer from '../components/LicenseFlowContainer'
import PageCard from '../components/cards/PageCard'
import BrokerInformationFooter from '../components/BrokerInformationFooter'

interface LicenseProps {
    isCloudBroker: boolean
    isFluidDesign: boolean
    connectionState: ConnectionState
    setLicenseFunction: (license: LicenseInfo) => void
    isAppInitialized: boolean
    cloudContext: CloudContext | undefined
    license: LicenseInfo | undefined
    isLicensed: boolean
}

export default function License(props: LicenseProps) {
    const cardContent = () => {
        return (
            <>
                <LicenseFlowContainer
                    setLicenseFunction={props.setLicenseFunction}
                    isLicensed={props.isLicensed}
                    license={props.license}
                    connectionState={props.connectionState}
                />
                {localStorage.getItem('debug') && (
                    <p className={'small p-3'}>
                        Client connected to broker: {'' + props.connectionState.clientIsConnectedToBroker} <br />
                        Client has internet: {'' + props.connectionState.clientHasInternet} <br />
                        Broker has ethernet: {'' + props.connectionState.brokerHasEthernet} <br />
                        <br />
                        Broker public ip: {'' + props.connectionState.brokerPublicUrls} <br />
                    </p>
                )}
            </>
        )
    }

    const containerContent = () => {
        return (
            <>
                <Container fluid={props.isFluidDesign} className={`${props.isFluidDesign ? 'px-4' : ''}`}>
                    <PageCard body={cardContent()} />
                </Container>
                {/* Sticky broker info should not be available on this screen, it will block neccessary elements when re-applying and old license */}
            </>
        )
    }

    return <>{props.isAppInitialized && containerContent()}</>
}
