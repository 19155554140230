import { Card } from 'react-bootstrap'
import VideoPlayer from './VideoPlayer'
import { useEffect, useState } from 'react'
import format from 'format-duration'
import { PlaybackState } from '../../../api/BrokerApi/types'
import { VideoSyncStatus } from '../../../services/VideoSyncService'
import { VideoPanelSettings } from '../../../types/Panel'
import Close from '@mui/icons-material/CloseRounded'
import { MediaFile } from '../../../api/CloudApi/types'
import { CloudContext } from '../../../services/LicenseService'
import { VolumeOffRounded, VolumeUpRounded } from '@mui/icons-material'

interface VideoPanelCardProps {
    panelTitle: string
    panelKey: string
    playbackState: PlaybackState | undefined
    removePanelFunction: (panelKey: string) => void
    videoPanelSettings: VideoPanelSettings
    saveOffsetFunction: (mediaFile: MediaFile) => void
    cloudContext: CloudContext | undefined
}

function VideoPanelCard(props: VideoPanelCardProps) {
    //const [url, setUrl] = useState<string>()

    const [timeUntilVideoStartsInSeconds, setTimeUntilVideoStartsInSeconds] = useState<number>(0)
    const [videoEnded, setVideoEnded] = useState(false)
    const [videoLengthSeconds, setVideoLengthSeconds] = useState(0)
    //const [currentVideoOffsetSeconds, setCurrentVideoOffsetSeconds] = useState(0)
    //const VIDEO_OFFSET = 6
    const [videoOffsetSeconds, setVideoOffsetSeconds] = useState(props.videoPanelSettings.file.videoOffsetSeconds)

    const [videoSyncStatus, setVideoSyncStatus] = useState<VideoSyncStatus>()
    const [syncModeEnabled, setSyncModeEnabled] = useState(false)
    const [videoCurrentTime, setVideoCurrentTime] = useState(0)
    const [soundMuted, setSoundMuted] = useState(true)

    useEffect(() => {
        if (videoSyncStatus && props.playbackState) {
            setTimeUntilVideoStartsInSeconds(Math.round(videoSyncStatus!.streamAndVideoOffsetSecs))
            setVideoEnded(videoSyncStatus.hasVideoEnded)
            setVideoCurrentTime(videoSyncStatus.videoCurrentTimeSecs)
            setVideoLengthSeconds(videoSyncStatus.videoLengthSeconds)
        }
    }, [videoSyncStatus])

    useEffect(() => {
        console.log(`Should save new Offset: ${videoOffsetSeconds}`)
        if (videoOffsetSeconds != props.videoPanelSettings.file.videoOffsetSeconds) {
            props.videoPanelSettings.file.videoOffsetSeconds = videoOffsetSeconds
            props.saveOffsetFunction(props.videoPanelSettings.file)
        }
    }, [videoOffsetSeconds])

    // @ts-ignore
    // @ts-ignore
    return (
        <>
            <Card className="shadow rounded border-0 m-1 mx-0 pb-2" style={{ height: '400px' }}>
                <div className="d-flex justify-content-between p-0 m-0 flex-nowrap">
                    <div className="d-flex d-inline-block text-truncate flex-nowrap">
                        <p className="small m-1 p-1 text-start remotive-primary-60-color lh-sm text-truncate">
                            {props.videoPanelSettings.fileName}
                        </p>
                    </div>
                    <div className="d-flex d-inline-block flex-nowrap">
                        <div className="d-flex align-items-center remotive-small border-1">
                            {videoEnded && <>Video has ended &nbsp; | &nbsp;</>}
                            {!videoEnded && (
                                <>
                                    Offset {Math.round(videoOffsetSeconds * 100) / 100}s |{' '}
                                    {format(videoCurrentTime * 1000)} / {format(Math.round(videoLengthSeconds * 1000))}
                                </>
                            )}
                            &nbsp;&nbsp;
                        </div>

                        {props.cloudContext &&
                            props.cloudContext.permissions.includes('project/recording/edit') &&
                            !syncModeEnabled && (
                                <button
                                    disabled={!props.playbackState?.isPaused()}
                                    className="className=btn remotive-btn-primary remotive-btn-sm p-1 border-0"
                                    onClick={() => setSyncModeEnabled(!syncModeEnabled)}
                                >
                                    Sync
                                </button>
                            )}
                        <button
                            onClick={() => setSoundMuted(!soundMuted)}
                            className="btn remotive-btn-no-bg remotive-btn-sm"
                        >
                            <div className="d-flex align-items-center">
                                {!soundMuted && <VolumeUpRounded sx={{ fontSize: 24 }} />}
                                {soundMuted && <VolumeOffRounded sx={{ fontSize: 24 }} />}
                            </div>
                        </button>

                        {/*7 seconds into the recording is 11 seconds into the video
                            offset = 7 - 11 = -4

                        */}

                        {props.cloudContext &&
                            props.cloudContext.permissions.includes('project/recording/edit') &&
                            syncModeEnabled && (
                                <button
                                    disabled={!props.playbackState?.isPaused()}
                                    className="className=btn remotive-btn-primary remotive-btn-sm p-1 border-0"
                                    onClick={() => setSyncModeEnabled(!syncModeEnabled)}
                                >
                                    Apply
                                </button>
                            )}
                        <button
                            onClick={() => props.removePanelFunction(props.panelKey)}
                            className="btn remotive-btn-no-bg remotive-btn-sm"
                        >
                            <div className="d-flex align-items-center">
                                <Close sx={{ fontSize: 24 }} />
                            </div>
                        </button>
                    </div>
                </div>
                <div className="h-100">
                    {!props.videoPanelSettings?.file && <p>Loading video...</p>}
                    {props.videoPanelSettings?.file && (
                        <VideoPlayer
                            onVideoLoadedCallback={() => console.log('Video loaded')}
                            videoSettings={props.videoPanelSettings}
                            videoSourceType={props.videoPanelSettings.file.mimeType}
                            playbackState={props.playbackState}
                            videoStatusCallback={setVideoSyncStatus}
                            resyncModeEnabled={syncModeEnabled}
                            videoOffsetSeconds={videoOffsetSeconds}
                            setVideoOffsetSeconds={setVideoOffsetSeconds}
                            soundMuted={soundMuted}
                        />
                    )}
                </div>
                {videoSyncStatus?.problemDescription && (
                    <div className={'remotive-small'}>{videoSyncStatus.problemDescription}</div>
                )}
            </Card>
        </>
    )
}

export default VideoPanelCard
