import { Card, OverlayTrigger, Popover } from 'react-bootstrap'
import Close from '@mui/icons-material/CloseRounded'
import GoogleWorldMap from './GoogleWorldMap'
import { useEffect, useRef, useState } from 'react'
import { CheckRounded, Settings, WarningRounded } from '@mui/icons-material'
import { Signal } from '../../../types/Filters'
import SetLatLonModal from './SetLatLonModal'
import { LatLonType } from '../../../types/LatLonType'
import { MapPanelSettings, Panel } from '../../../types/Panel'
import { addPendingSubscription, applyPendingSubscriptions, removeSubscription } from '../../../services/BrokerService'
import { SubscriptionId } from '../../../types/SubscriptionId'
import { ConnectionState } from '../../../services/LicenseService'

interface MapPanelCardProps {
    allSignals: Array<Signal>
    savePanelFunction: (panel: Panel) => void
    thisPanel: Panel
    isCloudBroker: boolean
    connectionState: ConnectionState
    removePanelFunction: (panelKey: string) => void
}

const DEMO_NAMESPACES = ['ChassisBus', 'vss']
const DEMO_LATITUDE_SIGNALS = [
    'GPSLatitude04F',
    'ID04FGPSLatLong.GPSLatitude04F',
    'Vehicle.CurrentLocation.Latitude',
    'LATITUDE',
]
const DEMO_LONGITUDE_SIGNALS = [
    'GPSLongitude4F',
    'ID04FGPSLatLong.GPSLongitude04F',
    'Vehicle.CurrentLocation.Longitude',
    'LONGITUDE',
]

function MapPanelCard(props: MapPanelCardProps) {
    const [showSetLatLonModal, setShowSetLatLonModal] = useState<boolean>(false)
    const typeToSelect = useRef<LatLonType>(LatLonType.LAT)

    const longitudeSubscriptionId = useRef<SubscriptionId>()
    const latitudeSubscriptionId = useRef<SubscriptionId>()

    useEffect(() => {
        console.debug('Mounted map panel')
        return () => {
            removeSubscription(longitudeSubscriptionId.current)
            removeSubscription(latitudeSubscriptionId.current)
        }
    }, [])

    const getDemoLatitudeSignal = (): Signal | undefined => {
        return props.allSignals.find(
            (signal) => DEMO_LATITUDE_SIGNALS.includes(signal.name) && DEMO_NAMESPACES.includes(signal.namespace)
        )
    }

    const getDemoLongitudeSignal = (): Signal | undefined => {
        return props.allSignals.find(
            (signal) => DEMO_LONGITUDE_SIGNALS.includes(signal.name) && DEMO_NAMESPACES.includes(signal.namespace)
        )
    }

    const [longitudeSignal, setLongitudeSignal] = useState<Signal | undefined>(
        props.thisPanel.mapSettings?.longitudeSignal || getDemoLongitudeSignal()
    )
    const [latitudeSignal, setLatitudeSignal] = useState<Signal | undefined>(
        props.thisPanel.mapSettings?.latitudeSignal || getDemoLatitudeSignal()
    )
    const [longitude, setLongitude] = useState(181) //invalid lon
    const [latitude, setLatitude] = useState(91) //invalid lat
    const [showPopover, setShowPopover] = useState(false)

    useEffect(() => {
        if (latitudeSignal !== undefined && longitudeSignal !== undefined) {
            const mapSettingsToSave = {
                latitudeSignal: { name: latitudeSignal.name, namespace: latitudeSignal.namespace },
                longitudeSignal: { name: longitudeSignal.name, namespace: longitudeSignal.namespace },
            } as MapPanelSettings
            props.savePanelFunction({ ...props.thisPanel, mapSettings: mapSettingsToSave } as Panel)

            removeSubscription(longitudeSubscriptionId.current)
            removeSubscription(latitudeSubscriptionId.current)

            longitudeSubscriptionId.current = addPendingSubscription(
                longitudeSignal.name,
                longitudeSignal.namespace,
                (signalValue) => setLongitude(+signalValue.value)
            )
            latitudeSubscriptionId.current = addPendingSubscription(
                latitudeSignal.name,
                latitudeSignal.namespace,
                (signalValue) => setLatitude(+signalValue.value)
            )
            applyPendingSubscriptions()
        }
    }, [props.thisPanel, longitudeSignal, latitudeSignal])

    const setLatLonSignal = (type: LatLonType, signal: Signal) => {
        switch (type) {
            case LatLonType.LAT:
                return setLatitudeSignal(signal)

            case LatLonType.LON:
                return setLongitudeSignal(signal)
            default:
                console.warn(`Trying to set unsupported latitude/longitude type, type=${type}`)
                break
        }
    }

    const popover = (
        <Popover id="popover-basic" className="border-0 remotive-primary-0-background shadow">
            <Popover.Body className="pb-2">
                <div className="mb-3 text-center">
                    <div className="d-flex justify-content-center remotive-font-md flex-column">
                        <div className="mb-2 text-start">
                            <div className="d-flex flex-row justify-content-between align-items-center">
                                <p className="m-0 me-5">
                                    <b>Latitude</b>
                                </p>
                                <button
                                    className={`btn remotive-btn-sm remotive-btn-primary ps-1 pe-1 border-0 lexend-regular`}
                                    title="Set latitude"
                                    onClick={() => {
                                        setShowPopover(false)
                                        typeToSelect.current = LatLonType.LAT
                                        setShowSetLatLonModal(true)
                                    }}
                                >
                                    Edit
                                </button>
                            </div>
                            <p className="m-0">{latitudeSignal?.name ?? 'Not set'}</p>
                        </div>
                        <div className="mb-0 text-start">
                            <div className="d-flex flex-row justify-content-between align-items-center">
                                <p className="m-0 me-5">
                                    <b>Longitude</b>
                                </p>
                                 <button
                                    className={`btn remotive-btn-sm remotive-btn-primary ps-1 pe-1 border-0 lexend-regular`}
                                    title="Set longitude"
                                    onClick={() => {
                                        setShowPopover(false)
                                        typeToSelect.current = LatLonType.LON
                                        setShowSetLatLonModal(true)
                                    }}
                                >
                                    Edit
                                </button>
                            </div>
                            <p className="m-0">{longitudeSignal?.name ?? 'Not set'}</p>
                        </div>
                    </div>
                </div>
            </Popover.Body>
        </Popover>
    )

    return (
        <>
            <Card className="shadow rounded border-0 m-1 mx-0 pb-2" style={{ height: '400px' }}>
                <div className="d-flex justify-content-between p-0 m-0 flex-nowrap">
                    <div className="invisible">
                        <p>This is hidden</p>
                    </div>
                    <div className="d-flex d-inline-block flex-nowrap">
                        <button className="btn remotive-btn-secondary remotive-btn-sm p-1 border-0">
                            <OverlayTrigger
                                trigger="click"
                                rootClose
                                show={showPopover}
                                onToggle={(newState: boolean) => setShowPopover(newState)}
                                placement="top"
                                overlay={popover}
                            >
                                <div className="d-flex align-items-center">
                                    <Settings sx={{ fontSize: 20 }} />
                                    <p className="m-0 ms-1 d-none d-lg-block">Settings</p>
                                </div>
                            </OverlayTrigger>
                        </button>
                        <button
                            onClick={() => props.removePanelFunction(props.thisPanel.key)}
                            className="btn remotive-btn-no-bg remotive-btn-sm"
                        >
                            <div className="d-flex align-items-center">
                                <Close sx={{ fontSize: 24 }} />
                            </div>
                        </button>
                    </div>
                </div>
                <div className="w-100 h-100">
                    {latitudeSignal !== undefined && longitudeSignal !== undefined ? (
                        <GoogleWorldMap connectionState={props.connectionState} isCloudBroker={props.isCloudBroker} latitude={latitude} longitude={longitude} />
                    ) : (
                        <div className="d-flex flex-column align-items-center justify-content-center h-100">
                            <p className="m-0 remotive-font d-flex align-items-center">
                                <WarningRounded className="me-1 text-warning" /> Set latitude and longitude
                            </p>
                            <p className="m-0 remotive-font-sm text-secondary">
                                Both latitude and longitude must be set to visualize a map
                            </p>
                            <div className="mt-2">
                                {
                                    <button
                                        className={`btn remotive-btn-md remotive-btn-${
                                            longitudeSignal === undefined ? 'primary' : 'success'
                                        } ps-1 pe-1 border-0 me-2`}
                                        title="Set longitude"
                                        onClick={() => {
                                            setShowPopover(false)
                                            typeToSelect.current = LatLonType.LON
                                            setShowSetLatLonModal(true)
                                        }}
                                    >
                                        {longitudeSignal === undefined ? (
                                            <>Set longitude</>
                                        ) : (
                                            <>
                                                <CheckRounded sx={{ fontSize: 17 }} /> Longitude is set
                                            </>
                                        )}
                                    </button>
                                }
                                {latitudeSignal === undefined && (
                                    <button
                                        className={`btn remotive-btn-md remotive-btn-${
                                            latitudeSignal === undefined ? 'primary' : 'success'
                                        } ps-1 pe-1 border-0 me-2`}
                                        title="Set latitude"
                                        onClick={() => {
                                            setShowPopover(false)
                                            typeToSelect.current = LatLonType.LAT
                                            setShowSetLatLonModal(true)
                                        }}
                                    >
                                        {latitudeSignal === undefined ? (
                                            <>Set latitude</>
                                        ) : (
                                            <>
                                                <CheckRounded sx={{ fontSize: 17 }} /> Latitude is set
                                            </>
                                        )}
                                    </button>
                                )}
                            </div>
                        </div>
                    )}
                </div>
            </Card>

            {/* Modals below */}
            <SetLatLonModal
                type={typeToSelect.current}
                show={showSetLatLonModal}
                handleCloseFunction={() => setShowSetLatLonModal(false)}
                allSignals={props.allSignals}
                setLatLonSignalFunction={setLatLonSignal}
            />
        </>
    )
}

export default MapPanelCard
