import { useEffect, useState } from 'react'
import { ProductAnalytics, ProductAnalyticsProps } from './ProductAnalytics'
import { useLocation } from 'react-router-dom'
import { AuthenticatedUser } from '../api/CloudApi/types'

export function useProductAnalyticsPage(props: ProductAnalyticsProps) {
    const location = useLocation()
    const [identitySent, setIdentifySent] = useState(false)
    const [analytics, setAnalytics] = useState(new ProductAnalytics(props.cloudContext))

    useEffect(() => {
        if (props.user) {
            analytics.page(location.pathname)
            sendIdentify()
        }
    }, [location])

    const sendIdentify = () => {
        if (props.user && !identitySent) {
            analytics.identify(props.user)
            setIdentifySent(true)
        }
    }

    useEffect(() => {
        sendIdentify()
    }, [props.user])

    useEffect(() => {
        if (props.cloudContext) {
            analytics.cloudContext = props.cloudContext
        }
    }, [props.cloudContext])
}
