import { Mode, PlaybackMode } from 'remotivelabs-grpc-web-stubs'
import { PlaybackState, PlaybackStateFile, RecordingState } from './types'
import { PlaybackStateFileImpl } from './PlaybackStateFileImpl'

export class PlaybackStateImpl implements PlaybackState, RecordingState {
    playbackMode: PlaybackMode
    private _files: Array<PlaybackStateFile>

    private _sendSinglePlaybackCommand: (
        mode: Mode,
        playbackStateImpl: PlaybackStateImpl,
        seekOffset?: number
    ) => Promise<boolean>

    constructor(
        playbackMode: PlaybackMode,
        files: Array<PlaybackStateFileImpl>,
        sendSinglePlaybackCommand: (
            mode: Mode,
            playbackStateImpl: PlaybackStateImpl,
            seekOffset?: number
        ) => Promise<boolean>
    ) {
        this.playbackMode = playbackMode
        this._files = files
        this._sendSinglePlaybackCommand = sendSinglePlaybackCommand
    }

    debug = () => {
        return `playing? ${this.isPlaying()} ${JSON.stringify(this.files())}`
    }

    //
    isSame(state: PlaybackStateImpl): boolean {
        const paths = state._files.map((f) => f.path())
        const currentPaths = this._files.map((f) => f.path())

        const a = currentPaths
            .map((path) => {
                if (paths.includes(path)) {
                    return true
                }
                return false
            })
            .filter((b) => !b)
        return a.length === 0
    }

    files(): Array<PlaybackStateFile> {
        return this._files as Array<PlaybackStateFile>
    }

    setFiles(files: Array<PlaybackStateFile>) {
        this._files = files
    }

    offsetWallClockVsSampleMs() {
        // Ensures that the getOffsetwallclockvssample function actually exists since it was released on or after 20240314
        if (this.playbackMode.getOffsetwallclockvssample !== undefined) {
            return this.playbackMode.getOffsetwallclockvssample() / 1000
        }
        return 0
    }

    currentOffsetDurationMs() {
        return this.playbackMode.getOffsettime() / 1000
    }

    startTimestampMs() {
        return this.playbackMode.getStarttime() / 1000
    }

    endTimestampMs() {
        return this.playbackMode.getEndtime() / 1000
    }

    totalDurationMs() {
        return this.playbackMode.getEndtime() / 1000 - this.playbackMode.getStarttime() / 1000
    }

    isPlaying() {
        return this.playbackMode.getMode() === Mode.PLAY
    }

    isPaused() {
        return this.playbackMode.getMode() === Mode.PAUSE
    }

    isStopped() {
        return this.playbackMode.getMode() === Mode.STOP
    }

    isRecording() {
        return this.playbackMode.getMode() === Mode.RECORD
    }

    isSeeking(): boolean {
        return this.playbackMode.getMode() === Mode.SEEK
    }

    stop = async () => {
        console.log(`Sending stop... waiting for result`)
        return this._sendSinglePlaybackCommand(Mode.STOP, this)
            .then((res) => {
                console.log(`Stop result: ${res}`)
            })
            .catch((error) => {
                console.error(error)
                throw error
            })
    }

    play = async () => {
        console.log(`Sending play... waiting for result`)
        return this._sendSinglePlaybackCommand(Mode.PLAY, this)
            .then((res) => {
                console.log(`Play result: ${res}`)
            })
            .catch((error) => {
                console.error(error)
                throw error
            })
    }

    pause = async () => {
        console.log(`Sending pause... waiting for result`)
        return this._sendSinglePlaybackCommand(Mode.PAUSE, this)
            .then((res) => {
                console.log(`Pause result: ${res}`)
            })
            .catch((error) => {
                console.error(error)
                throw error
            })
    }

    record = async () => {
        console.log(`Sending record... waiting for result`)
        return this._sendSinglePlaybackCommand(Mode.RECORD, this)
            .then((res) => {
                console.log(`Record result: ${res}`)
            })
            .catch((error) => {
                console.error(error)
                throw error
            })
    }

    seek = async (seekOffsetMs: number, maxAllowedOffset?: number) => {
        const seekOffset =
            maxAllowedOffset !== undefined && seekOffsetMs > maxAllowedOffset ? maxAllowedOffset : seekOffsetMs
        console.log(`Sending seek to ${seekOffset}... waiting for result`)
        return this._sendSinglePlaybackCommand(Mode.SEEK, this, seekOffset)
            .then((res) => {
                console.log(`Seek result: ${res}`)
            })
            .catch((error) => {
                console.error(error)
                throw error
            })
    }
}
