import { useEffect, useState } from 'react'
import { ProductAnalytics, ProductAnalyticsProps, ProductAnalyticsTracker } from './ProductAnalytics'

export function useProductAnalyticsTracker(props: ProductAnalyticsProps): ProductAnalyticsTracker {
    const [analytics, setAnalytics] = useState(new ProductAnalytics())

    useEffect(() => {
        if (props.cloudContext) {
            analytics.cloudContext = props.cloudContext
        }
    }, [props.cloudContext])

    return analytics
}
