import { useEffect } from 'react'
import { Modal } from 'react-bootstrap'
import CloudApi from '../../api/CloudApi'
import BrokerDetailsInfo from '../BrokerDetailsInfo'

interface SignInModalProps {
    show: boolean
    handleCloseFunction: () => void
}

function SignInModal(props: SignInModalProps) {
    useEffect(() => {
        console.log('Mounting sign in modal!')
    }, [])

    return (
        <>
            <Modal keyboard={false} className="col-12" size="lg" show={props.show}>
                <Modal.Header className="remotive-primary-70-background">
                    <Modal.Title className="lexend-regular text-light">Sign in</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="d-flex flex-column justify-content-center">
                        <div className="d-flex justify-content-center mt-4 mb-4">
                            <div className="d-none d-md-block">
                                <img width="300" src="./remotive-labs-logo-pos@3x.png" />
                            </div>
                            <div className="d-none d-sm-block d-md-none">
                                <img width="250" src="./remotive-labs-logo-pos@3x.png" />
                            </div>
                            <div className="d-block d-sm-none">
                                <img width="220" src="./remotive-labs-logo-pos@3x.png" />
                            </div>
                        </div>
                        <div className="d-flex flex-column justify-content-center">
                            <div className="text-center">
                                <p className="m-0 mb-1 fs-5">
                                    <b>Connect to RemotiveCloud</b>
                                </p>
                                <div className="col-12 col-md-10 offset-md-1 col-lg-8 offset-lg-2">
                                    <p className="m-0 remotive-font-sm text-secondary ">
                                        You must be a RemotiveCloud user to use the RemotiveBroker application with a
                                        virtual broker. Please log in to proceed.
                                    </p>
                                </div>
                            </div>
                            <div className="d-flex justify-content-center mt-4 mb-4">
                                <button
                                    className="btn remotive-btn-primary remotive-btn"
                                    onClick={() => CloudApi.setLoginUrl()}
                                >
                                    <img
                                        src="https://img.icons8.com/color/24/000000/google-logo.png"
                                        className="me-2"
                                    />
                                    Sign in with Google
                                </button>
                            </div>
                        </div>
                    </div>

                    {props.show && (
                        <div className="fixed-bottom col-12 col-md-10 col-lg-8 col-xl-6 px-2 fade-in">
                            <BrokerDetailsInfo asModal={false} />
                        </div>
                    )}
                </Modal.Body>
            </Modal>
        </>
    )
}

export default SignInModal
